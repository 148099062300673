import React, {useState, useEffect} from 'react'
import "./style.css";
import Select from 'react-select';
import OtpInput from 'react-otp-input';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

interface InputFieldProps {
  type?: string;
  placeholder?: string;
  label: string;
  autoComplete?: string;
  value?: string;
  className?: string;
  icon?: any,
  name: string,
  autofill?: boolean,
  pattern?: any,
  disabled?: boolean
  error?: string;
  otpChange?: any;
  defaultValue?: string, 
  onChange?: (fields: Partial<any>) => void;
}

const InputField = (prop: InputFieldProps) => {
  const [hasError, setHasError] = useState(false);

  const changeInput = (e: any) => {
    if(prop.onChange) {
      prop.onChange({[e.target.name]: e.target.value});
    }
  }

  useEffect(() => {
    if (prop.error) {
      setHasError(true);
    }
  }, [prop.error]);

  return (
    <div className={`material-field ${prop.className}`}>      
      <label className="material-field-label">{prop.label}</label>
      <input disabled={prop.disabled} autoComplete={prop.autoComplete} pattern={prop.pattern} name={prop.name} id={prop.name} className="material-field-input" type={prop.type} placeholder={prop.placeholder} value={prop.value} onChange={changeInput}/>
      <p className={`error-text ${prop.error ? 'error-animate-in' : hasError ? 'error-animate-out' : ''}`}>
        <i className="bi bi-exclamation-triangle"></i> {prop.error}
      </p>
    </div>
  )
}

export const TextAreaField = (prop: InputFieldProps) => {
  const [hasError, setHasError] = useState(false);

  const changeInput = (e: any) => {
    if(prop.onChange) {
      prop.onChange({[e.target.name]: e.target.value});
    }
  }

  useEffect(() => {
    if (prop.error) {
      setHasError(true);
    }
  }, [prop.error]);

  return (
    <div className="material-field">      
      <label className="material-field-label">{prop.label}</label>
      <textarea name={prop.name} id={prop.name} className="material-field-input" placeholder={prop.placeholder} value={prop.value} onChange={changeInput}></textarea>
      <p className={`error-text ${prop.error ? 'error-animate-in' : hasError ? 'error-animate-out' : ''}`}>
        <i className="bi bi-exclamation-triangle"></i> {prop.error}
      </p>
    </div>
  )
}

interface SelectFieldProps {
  list: any;
  label: any;
  value: any;
  error: any;
  name: any;
  onChange?: any;
}

export const SelectField = (prop: SelectFieldProps) => {
  
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (prop.error) {
      setHasError(true);
    }
  }, [prop.error]);

  return (
    <div className="material-field">      
      <label className="material-field-label">{prop.label}</label>
      <Select name={prop.name} id={prop.name} value={prop.value} onChange={prop.onChange} options={prop.list} className='select-option'/>
      {/* <input pattern={prop.pattern} name={prop.name} id={prop.name} className="material-field-input" type={prop.type} placeholder={prop.placeholder} value={prop.value} onChange={prop.onChange}/> */}
      <p className={`error-text ${prop.error ? 'error-animate-in' : hasError ? 'error-animate-out' : ''}`}>
        <i className="bi bi-exclamation-triangle"></i> {prop.error}
      </p>
    </div>
  )
}

export const OtpField = (prop: InputFieldProps) => {

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (prop.error) {
      setHasError(true);
    }
  }, [prop.error]);

  return (
    <div className="material-field otp-field">      
      <label className="material-field-label">{prop.label}</label>
      <OtpInput value={prop.value} onChange={prop.otpChange} numInputs={6} containerStyle="otp-container-style"
        renderInput={(props) => <input {...props} className="material-field-input single-otp-field" type="number"/>}
      />
      <p className={`error-text ${prop.error ? 'error-animate-in' : hasError ? 'error-animate-out' : ''}`}>
        <i className="bi bi-exclamation-triangle"></i> {prop.error}
      </p>
    </div>
  )
}

export const PhoneInputCustom = (prop: InputFieldProps) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (prop.error) {
      setHasError(true);
    }
  }, [prop.error]);

  const changeInput = (e: any) => {
    if(prop.onChange) {
      prop.onChange({[e.target.name]: e.target.value});
    }
  }



  return (
    <div className="material-field">      
      <label className="material-field-label">{prop.label}</label>
      <input pattern={prop.pattern} name={prop.name} id={prop.name} className="material-field-input" type={prop.type} placeholder={prop.placeholder} value={prop.value} onChange={changeInput}/>
      <p className={`error-text ${prop.error ? 'error-animate-in' : hasError ? 'error-animate-out' : ''}`}>
        <i className="bi bi-exclamation-triangle"></i> {prop.error}
      </p>
    </div>
  )
}

export default InputField
