import React, { useState } from 'react'
import { Status } from '../../../../API'
import { TextAreaField } from '../../../../Components/InputField/InputField'
import "./style.css";

interface IRejectRate {
  onRateAction: (status: Status, comment: string) => void,
  setModalReject: (show: boolean) => void,
}

export const RejectRate = (prop: IRejectRate) => {
  const [error, setError] = useState<string>("");
  const onSubmit = (e: any) => {
    setError("");
    e.preventDefault();
    const data = new FormData(e.target);
    const reason = Object.fromEntries(data.entries()).reason.toString();

    if(reason !== "") {
      prop.onRateAction(Status.REJECTED, reason);
    } else {
      setError("Enter a valid reason for rejection.")
    }
  }
  return (
    <form className='accept-box-container-rate' onSubmit={onSubmit}>
      <h1>Reject Rate</h1>
      <p>After accepting the rate, the carrier will be notified and the rates will added to the accepted column. This further gives you the ability to download them or import them to OTM directly.</p>
      <div className='reason-container'>
        <TextAreaField label='Reason of Rejection' name='reason' error={error}/>
      </div>
      <div>
        <button className='btn btn-danger'>Confirm Reject</button>
        <button className='btn btn-secondary' onClick={() => prop.setModalReject(false)} type='button'>Do it later</button>
      </div>
    </form>
  )
}
