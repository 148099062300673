import React, {useContext, useState} from "react";
import { TbPlus } from "react-icons/tb";
import axios from "axios";
import { useNavigate } from "react-router";
import { AccountContext } from "../../../Context/AuthContext";
import "./style.css";
import InputField from "../../../Components/InputField/InputField";
import Button from "../../../Components/Button/Button";
import { toast } from "react-toastify";



const AddUser = (prop: any) => {

  const navigate = useNavigate()
  const [error, setError] = useState<string>('');
  const {getUser} = useContext(AccountContext);
  const handleSubmit = async (e: any) =>{
    e.preventDefault();
    const data = new FormData(e.target);
    const name = Object.fromEntries(data.entries()).name;
    const email = Object.fromEntries(data.entries()).email;
    if(name !== "" && email !== "") {
      try {
        const user = getUser();
        const userSession = user.signInUserSession;
        const idToken = userSession.idToken.jwtToken;
        const itm = await fetch("https://vsqwc4gr9f.execute-api.us-east-1.amazonaws.com/Test/company/invite-sourcing-user",
          {
            mode: "cors",
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Authorization": idToken,
            },
  
            body: JSON.stringify({
              userId: user.username,
              email: email,
              name: name,
              country: 'Canada'
            }),
        });
        const data = await itm.json();
        if(data.code === 200) {
          toast.success("User Invitation");
          prop.setIsAddingUser(false);
        } else {
          toast.error(data.Message);
          e.target.reset();
        }
      } catch(e: any) {
        console.log(e);
      }   
    }
  }


  return (
    <div className="add-regular-user-container">
      <div className='header-request'>
        <i className="bi bi-boxes"></i>
        <div>
          <h2>Create New User</h2>
          <p>Enhance collaboration with quick carrier invitations</p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="form-box-padding">
        <InputField label='Full Name' name="name" type="text" error={""}/>
        <InputField label='Email Address' name="email" type="email" error={""}/>
        <InputField label='Country' name="departmentName" error={""}/>
        <div className='action-list-button'>
          <div>
            <button type='button' className='button-link' onClick={() => prop.setIsAddingUser(false)}>Cancel</button>
            <Button loading={false} label='Create'/>
          </div>
        </div>
      </form>
    </div>
    //   <div className="col-xl">
    //     <div className="card mb-4">
    //       <div className="card-header d-flex justify-content-between align-items-center">
    //         <h5 className="mb-3">Add User Form</h5>
    //         <small className="text-muted float-end">Fill the form below</small>
    //       </div>
    //       <div className="card-body">
    //         <form onSubmit={handleSubmit}>
    //           <div className="mb-3">
    //             <label className="form-label" htmlFor="basic-default-fullname">
    //               Full Name
    //             </label>
    //             <input
    //               type="text"
    //               name="name"
    //               onChange={handleChange}
    //               value={addUser.name}
    //               className="form-control"
    //               id="basic-default-fullname"
    //               placeholder="John Doe"
    //               required
    //             />
    //           </div>
    //           <div className="mb-3">
    //             <label className="form-label" htmlFor="basic-default-email">
    //               Email
    //             </label>
    //             <div className="input-group input-group-merge">
    //               <input
    //                 type="text"
    //                 name="email"
    //                 onChange={handleChange}
    //                 value={addUser.email}
    //                 id="basic-default-email"
    //                 className="form-control"
    //                 placeholder="john@gmail.com"
    //                 aria-label="john.doe"
    //                 aria-describedby="basic-default-email2"
    //                 required
    //               />
    //             </div>
    //             <div className="form-text">
    //               You can use letters, numbers &amp; periods
    //             </div>
    //           </div>
              
    //           <button
    //             type="submit"
    //             className="btn btn-primary waves-effect waves-light"
    //           >
    //             <TbPlus style={{ marginRight: "10px" }} size={17} />
    //             Add User
    //           </button>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
};

export default AddUser;
