import React from 'react'
import "./style.css";

export const DeleteRate = (prop: any) => {
  return (
    <div className='delete-box-rate-carrier'>
      <div className='danger-icon'>
        <i className="bi bi-exclamation-diamond"></i>
      </div>
      <h2>Are you sure?</h2>
      <p>Deleting this rate will also permanently remove it from the company to which it was sent, affecting all associated records and potentially impacting related transactions."</p>

      <div className='action-buttons'>
        <button className='btn btn-danger'>Delete</button>
        <button className='btn btn-light' onClick={() => prop.setConfirmDelete(false)}>Cancel</button>
      </div>
    </div>
  )
}
