import { CardElement, Elements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import "./style.css";
const stripePromise = loadStripe('your-publishable-key-here');
export const NewPaymentMethod = (prop: any) => {
  //const stripe = useStripe();
  //const elements = useElements();
  const [loading, setLoading] = useState(false);

  // const handleSubmit = async (event: React.FormEvent) => {
  //   event.preventDefault();
  //   if (!stripe || !elements) return;

  //   setLoading(true);
  //   const cardElement = elements.getElement(CardElement);
  //   if (!cardElement) return;

  //   const { token, error } = await stripe.createToken(cardElement);
  //   if (error) {
  //     console.error(error);
  //     setLoading(false);
  //     return;
  //   }

  //   // Call your backend to update payment method
  //   const response = await fetch('/api/update-payment-method', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ token: token.id }),
  //   });

  //   if (response.ok) {
  //     // Handle success (e.g., show confirmation message)
  //   } else {
  //     // Handle error
  //   }
  //   setLoading(false);
  // };

  return (
    <div className='NewPaymentMethod'>
        <form>
          <Elements stripe={stripePromise}>
            <CardElement />
          </Elements>
      <button type="submit" disabled={loading}>
        {loading ? 'Updating...' : 'Update Payment Method'}
      </button>
    </form>
      <button onClick={() => prop.setAddingNewPayment(false)}>Close</button>
    </div>
  )
}
