import React, {useState, useContext} from 'react'
import InputField, { SelectField } from '../../../Components/InputField/InputField'
import "./style.css";
import Button, { CheckBox } from '../../../Components/Button/Button';
import { Link } from 'react-router-dom';
import { AccountContext } from '../../../Context/AuthContext';
import { useNavigate} from 'react-router-dom';
import { countries } from 'countries-list';
import PasswordStrengthBar from 'react-password-strength-bar';
import logo from "../../../assets/img/logo.png";
import { Reveal } from '../../../Components/Revel/Revel';

const INITIAL_ERROR = {
  email: '',
  password: '',
  name: '',
  country: '',
  checkbox: '',
}

interface CountryOption {
  value: string;
  label: string;
}

export const Register = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<any>(INITIAL_ERROR);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedOption, setSelectedOption] = useState<CountryOption | null>(null);
  const [password, setPassword] = useState<string>();
  const [score, setScore] = useState<number>(0);
  const {register} = useContext(AccountContext);

  const countryOptions: CountryOption[] = Object.keys(countries).map((countryCode) => ({
    value: countryCode,
    label: countries[countryCode as keyof typeof countries].name,
  }));

  const changeScore = (score: number) => {
    setScore(score);
  }

  const passwordUpdate = (fields: any) => {
    setPassword(fields.password);
  }

  const onSubmit = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    setError(INITIAL_ERROR);

    const data = new FormData(e.target);
    const email = Object.fromEntries(data.entries()).email;
    const password = Object.fromEntries(data.entries()).password;
    const name = Object.fromEntries(data.entries()).name;
    const remember = Object.fromEntries(data.entries()).agreeTerms;


    if(email !== "" && password !== "" && name !== "" && selectedOption !== null && remember !== undefined) {
      if(score >= 3) {
        try {
          const response = await register({email, password, name, country: selectedOption.label});
          const redirectPassedState = {
            userConfirmed: response.userConfirmed, 
            userSub: response.userSub,
            username: response.user.username,
            from: "SIGNUP"
          }

          if(response !== undefined) {
            navigate("/auth/verify-account", {state: redirectPassedState});
          }          
        } catch(e: any) {

          if(e.code === "UsernameExistsException") {
            setError((prev: any) => {
              return {...prev, email: e.message}
            });
          }
  
          if(e.code === "InvalidPasswordException") {
            setError({for: "password", note: e.message});
          }
        }
      } else {
        setError((prev: any) => {
          return {...prev, password: "Please choose a better password"}
        });
      }     
    } else {
      if(email === "") {
        setError((prev: any) => {
          return {...prev, email: "Enter a valid email"}
        });
      } else if (name === "") {
        setError((prev: any) => {
          return {...prev, name: "Enter a valid full name"}
        });
      } else if (selectedOption === null) {
        setError((prev: any) => {
          return {...prev, country: "Select your country"}
        });
      } else if(password === "") {
        setError((prev: any) => {
          return {...prev, password: "Enter a valid password"}
        });
      } else if(remember === undefined){
        setError((prev: any) => {
          return {...prev, remember: "Make sure to agree with the terms"}
        });
      }
      
      // if() {
      //   setError((prev: any) => {
      //     return {...prev, password: "Please enter your password"}
      //   });
      // }
      
    }

    setFormSubmitted(false);
  }

  return (
    <div className='register-main-page'>
      <div className='register-steps-info-page'>
        <img src={logo} width={160} alt="logo"/>
        <ul>
          <li>
            <aside>
            <i className="bi bi-check2-all"></i>
            </aside>
            <div>
              <h2>Get Started</h2>
              <p>Integrate with developer-friendly APIs or choose low-code or pre-built solutions.</p>
            </div>

            <div className='line-start-left'></div>
          </li>
          <li>
            <aside>
              <i className="bi bi-check2-all"></i>
            </aside>
            <div>
              <h2>Support any business model</h2>
              <p>Integrate with developer-friendly APIs or choose low-code or pre-built solutions.</p>
            </div>
            <div className='line-start-left'></div>
          </li>
          <li>
            <aside>
              <i className="bi bi-check2-all"></i>
            </aside>
            <div>
              <h2>Join millions of businesses</h2>
              <p>Integrate with developer-friendly APIs or choose low-code or pre-built solutions.</p>
            </div>
            
          </li>
        </ul>
      </div>
      <Reveal>
        <div className='register-form-container'>
          <div className='login-header'>
            <h1 className='poppins-regular'>Create your account</h1>
            <p className='poppins-light'>Welcome to RateGenie, please enter your login details below to start using the app</p>
          </div>
          <form onSubmit={onSubmit} className='login-form'>
            <InputField name='email' label='Email' type='email' error={error.email}/>
            <InputField name='name' label='Full Name' type='name' error={error.name}/>
            <SelectField name="country" value={selectedOption} label={"Country"} error={error.country} onChange={setSelectedOption} list={countryOptions}/>
            <InputField name='password' label='Password' type='password' error={error.password} onChange={passwordUpdate}/>
            <PasswordStrengthBar password={password} className="password-bar" onChangeScore={changeScore}/>
            <div className='agreement-section'>
              <CheckBox label='I Agree with all the Terms and Conditions.' loading={false} name='agreeTerms'/>
            </div>
            <Button label='Create an account' type='submit' loading={formSubmitted}/>
          </form>
          <div className='bottom-login-form'> 
            <p className="poppins-light">Already have an account? <Link className="text-link" to="/auth/login">Sign in</Link></p>
          </div>
        </div>
      </Reveal>
    </div>
  )
}
