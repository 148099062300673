import React, { useEffect, useMemo, useState } from 'react'
import { Reveal } from '../../../../Components/Revel/Revel'
import "./style.css";
import Slider from 'rsuite/Slider';
import 'rsuite/Slider/styles/index.css';
import Button from '../../../../Components/Button/Button';
import { CountUp } from 'use-count-up';
import AnimatedNumbers from "react-animated-numbers";

export const Membership = (prop: any) => {
  const [numberOfUser, setNumberOfUsers] = useState(2);
  const [numberOfCarriers, setNumberOfCarriers] = useState(10);
  const [cost, setCost] = useState(0);

  useEffect(() => {
    const costPrice = (numberOfUser + numberOfCarriers) * 75;
    setCost(costPrice);
  }, [numberOfCarriers, numberOfUser]);

  const userChange = (value: number) => {
    if(value >= 2) {
      setNumberOfUsers(value);
    }
  }

  const carrierChange = (value: number) => {
    if(value >= 10) {
      setNumberOfCarriers(value);
    }
  }
    
  return (
    <div>
      <Reveal>
        <div className='header-business-box-step'>
          <h2>Choose your plan</h2>
          <p>This information will help us help you achive your tasks</p>
        </div>
      </Reveal>

      <Reveal delay={.1}>
        <div className='d-flex user-action-slider'>
          <div className='user-selection-input-plan-details'>
            <div className='single-item-user-member'>
              <p>Number of Monthly Active Users</p>
              <span>{numberOfUser}</span>
            </div>
            
            <Slider min={2} max={10} value={numberOfUser} defaultValue={2} onChange={userChange} progress/>
          </div>
          <div className='user-selection-input-plan-details'>
            <div className='single-item-user-member'>
              <p>Number of Monthly Active Carriers</p>
              <span>{numberOfCarriers}</span>
            </div>
            
            <Slider min={10} max={100} value={numberOfCarriers} defaultValue={10} onChange={carrierChange} progress/>
          </div>
        </div>
      </Reveal>

      <Reveal delay={.25}>
        <div className='plan-box-single'>
          <div className='plan-header'>
            <div className='left-side-plan-header'>
              <h1>Business Plan <span className='for-sale-tag'>For Sale</span></h1>
              <p>Contains all the feature and report</p>
            </div>
            <div className='left-side-plan-header-cost'>
              <h2>USD ${cost}</h2>
              <p>/ Monthly</p>
            </div>
          </div>

          <div className='body-membership-info'>
            <div>
              <h1>FEATURES</h1>
              <p>List of features includes...</p>
            </div>

            <ul className='list-of-features'>
              <li><i className="bi bi-check-circle-fill"></i> Unlimited Rate Inquires</li>
              <li><i className="bi bi-check-circle-fill"></i> Dedicated Carrier Portal</li>
              <li><i className="bi bi-check-circle-fill"></i> Dedicated Sourcing Portal</li>
              <li><i className="bi bi-check-circle-fill"></i> SMART CSV to RATE Conversion</li>
              <li><i className="bi bi-check-circle-fill"></i> 24/7 Support</li>
              <li><i className="bi bi-check-circle-fill"></i> Email Notification</li>
              <li><i className="bi bi-check-circle-fill"></i> Deep Rate Lookup</li>
              <li><i className="bi bi-check-circle-fill"></i> Email Notification</li>
            </ul>
          </div>

          <div className='place-order-button-membership'>
            <Button label='Place Order' loading={false} onClick={() => prop.submitUserProfile(numberOfUser, numberOfCarriers)}></Button>
          </div>
        </div>
      </Reveal>
    

    </div>
  )
}
