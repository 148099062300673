/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreatePaymentResultInput = {
  id?: string | null,
  billinghistoryID: string,
  Invoice?: string | null,
  date?: number | null,
  transactionStatus?: string | null,
};

export type ModelPaymentResultConditionInput = {
  billinghistoryID?: ModelIDInput | null,
  Invoice?: ModelStringInput | null,
  date?: ModelIntInput | null,
  transactionStatus?: ModelStringInput | null,
  and?: Array< ModelPaymentResultConditionInput | null > | null,
  or?: Array< ModelPaymentResultConditionInput | null > | null,
  not?: ModelPaymentResultConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type PaymentResult = {
  __typename: "PaymentResult",
  id: string,
  billinghistoryID: string,
  Invoice?: string | null,
  date?: number | null,
  transactionStatus?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdatePaymentResultInput = {
  id: string,
  billinghistoryID?: string | null,
  Invoice?: string | null,
  date?: number | null,
  transactionStatus?: string | null,
};

export type DeletePaymentResultInput = {
  id: string,
};

export type CreateRateBatchInput = {
  id?: string | null,
  companyID: string,
  carrierID: string,
  status?: Status | null,
  tsMode?: string | null,
  scac?: string | null,
  startDate?: string | null,
  endDate?: string | null,
};

export enum Status {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  UPDATE_REQUESTED = "UPDATE_REQUESTED",
  EXPIRED = "EXPIRED",
}


export type ModelRateBatchConditionInput = {
  companyID?: ModelIDInput | null,
  carrierID?: ModelIDInput | null,
  status?: ModelStatusInput | null,
  tsMode?: ModelStringInput | null,
  scac?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  and?: Array< ModelRateBatchConditionInput | null > | null,
  or?: Array< ModelRateBatchConditionInput | null > | null,
  not?: ModelRateBatchConditionInput | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type RateBatch = {
  __typename: "RateBatch",
  id: string,
  Rates?: ModelRatesConnection | null,
  companyID: string,
  carrierID: string,
  status?: Status | null,
  tsMode?: string | null,
  scac?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelRatesConnection = {
  __typename: "ModelRatesConnection",
  items:  Array<Rates | null >,
  nextToken?: string | null,
};

export type Rates = {
  __typename: "Rates",
  id: string,
  tsMode?: RateType | null,
  data?: string | null,
  scac?: string | null,
  serviceName?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  originCity?: string | null,
  originCountry?: string | null,
  originProvince?: string | null,
  destinationCountry?: string | null,
  destinationCity?: string | null,
  destinationProvince?: string | null,
  originRegion?: string | null,
  destinationRegion?: string | null,
  ownerId?: string | null,
  ownerEmail?: string | null,
  ratebatchID: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum RateType {
  AIROCEAN = "AIROCEAN",
  FTL = "FTL",
  LTL = "LTL",
}


export type UpdateRateBatchInput = {
  id: string,
  companyID?: string | null,
  carrierID?: string | null,
  status?: Status | null,
  tsMode?: string | null,
  scac?: string | null,
  startDate?: string | null,
  endDate?: string | null,
};

export type DeleteRateBatchInput = {
  id: string,
};

export type CreateUserInvitationInput = {
  id?: string | null,
  inviteeEmail?: string | null,
  inviteeId?: string | null,
  companyID: string,
};

export type ModelUserInvitationConditionInput = {
  inviteeEmail?: ModelStringInput | null,
  inviteeId?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  and?: Array< ModelUserInvitationConditionInput | null > | null,
  or?: Array< ModelUserInvitationConditionInput | null > | null,
  not?: ModelUserInvitationConditionInput | null,
};

export type UserInvitation = {
  __typename: "UserInvitation",
  id: string,
  inviteeEmail?: string | null,
  inviteeId?: string | null,
  companyID: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateUserInvitationInput = {
  id: string,
  inviteeEmail?: string | null,
  inviteeId?: string | null,
  companyID?: string | null,
};

export type DeleteUserInvitationInput = {
  id: string,
};

export type CreateBillingHistoryInput = {
  id?: string | null,
  amount?: number | null,
  status?: SubscriptionStatus | null,
  billingDate?: string | null,
  companyID: string,
  startPeriod?: number | null,
  endPeriod?: number | null,
};

export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PAST_DUE = "PAST_DUE",
  CANCELED = "CANCELED",
}


export type ModelBillingHistoryConditionInput = {
  amount?: ModelFloatInput | null,
  status?: ModelSubscriptionStatusInput | null,
  billingDate?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  startPeriod?: ModelIntInput | null,
  endPeriod?: ModelIntInput | null,
  and?: Array< ModelBillingHistoryConditionInput | null > | null,
  or?: Array< ModelBillingHistoryConditionInput | null > | null,
  not?: ModelBillingHistoryConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelSubscriptionStatusInput = {
  eq?: SubscriptionStatus | null,
  ne?: SubscriptionStatus | null,
};

export type BillingHistory = {
  __typename: "BillingHistory",
  id: string,
  amount?: number | null,
  status?: SubscriptionStatus | null,
  billingDate?: string | null,
  companyID: string,
  startPeriod?: number | null,
  endPeriod?: number | null,
  PaymentResult?: ModelPaymentResultConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelPaymentResultConnection = {
  __typename: "ModelPaymentResultConnection",
  items:  Array<PaymentResult | null >,
  nextToken?: string | null,
};

export type UpdateBillingHistoryInput = {
  id: string,
  amount?: number | null,
  status?: SubscriptionStatus | null,
  billingDate?: string | null,
  companyID?: string | null,
  startPeriod?: number | null,
  endPeriod?: number | null,
};

export type DeleteBillingHistoryInput = {
  id: string,
};

export type CreateCarrierCompaniesInput = {
  id?: string | null,
  Name?: string | null,
  companyID?: string | null,
  carrierID: string,
};

export type ModelCarrierCompaniesConditionInput = {
  Name?: ModelStringInput | null,
  companyID?: ModelStringInput | null,
  carrierID?: ModelIDInput | null,
  and?: Array< ModelCarrierCompaniesConditionInput | null > | null,
  or?: Array< ModelCarrierCompaniesConditionInput | null > | null,
  not?: ModelCarrierCompaniesConditionInput | null,
};

export type CarrierCompanies = {
  __typename: "CarrierCompanies",
  id: string,
  Name?: string | null,
  companyID?: string | null,
  carrierID: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCarrierCompaniesInput = {
  id: string,
  Name?: string | null,
  companyID?: string | null,
  carrierID?: string | null,
};

export type DeleteCarrierCompaniesInput = {
  id: string,
};

export type CreateRatesInput = {
  id?: string | null,
  tsMode?: RateType | null,
  data?: string | null,
  scac?: string | null,
  serviceName?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  originCity?: string | null,
  originCountry?: string | null,
  originProvince?: string | null,
  destinationCountry?: string | null,
  destinationCity?: string | null,
  destinationProvince?: string | null,
  originRegion?: string | null,
  destinationRegion?: string | null,
  ownerId?: string | null,
  ownerEmail?: string | null,
  ratebatchID: string,
};

export type ModelRatesConditionInput = {
  tsMode?: ModelRateTypeInput | null,
  data?: ModelStringInput | null,
  scac?: ModelStringInput | null,
  serviceName?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  originCity?: ModelStringInput | null,
  originCountry?: ModelStringInput | null,
  originProvince?: ModelStringInput | null,
  destinationCountry?: ModelStringInput | null,
  destinationCity?: ModelStringInput | null,
  destinationProvince?: ModelStringInput | null,
  originRegion?: ModelStringInput | null,
  destinationRegion?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  ownerEmail?: ModelStringInput | null,
  ratebatchID?: ModelIDInput | null,
  and?: Array< ModelRatesConditionInput | null > | null,
  or?: Array< ModelRatesConditionInput | null > | null,
  not?: ModelRatesConditionInput | null,
};

export type ModelRateTypeInput = {
  eq?: RateType | null,
  ne?: RateType | null,
};

export type UpdateRatesInput = {
  id: string,
  tsMode?: RateType | null,
  data?: string | null,
  scac?: string | null,
  serviceName?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  originCity?: string | null,
  originCountry?: string | null,
  originProvince?: string | null,
  destinationCountry?: string | null,
  destinationCity?: string | null,
  destinationProvince?: string | null,
  originRegion?: string | null,
  destinationRegion?: string | null,
  ownerId?: string | null,
  ownerEmail?: string | null,
  ratebatchID?: string | null,
};

export type DeleteRatesInput = {
  id: string,
};

export type CreateDepartmentsInput = {
  id?: string | null,
  DepartmentName: string,
  DepartmentSCAC: string,
  companyID: string,
};

export type ModelDepartmentsConditionInput = {
  DepartmentName?: ModelStringInput | null,
  DepartmentSCAC?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  and?: Array< ModelDepartmentsConditionInput | null > | null,
  or?: Array< ModelDepartmentsConditionInput | null > | null,
  not?: ModelDepartmentsConditionInput | null,
};

export type Departments = {
  __typename: "Departments",
  id: string,
  DepartmentName: string,
  DepartmentSCAC: string,
  companyID: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateDepartmentsInput = {
  id: string,
  DepartmentName?: string | null,
  DepartmentSCAC?: string | null,
  companyID?: string | null,
};

export type DeleteDepartmentsInput = {
  id: string,
};

export type CreateCarrierInput = {
  id?: string | null,
  Email?: string | null,
  Phone?: string | null,
  Address?: AddressInput | null,
  ServiceName?: string | null,
  SCAC?: string | null,
};

export type AddressInput = {
  Address?: string | null,
  City?: string | null,
  Country?: string | null,
  Zipcode?: string | null,
  State?: string | null,
};

export type ModelCarrierConditionInput = {
  Email?: ModelStringInput | null,
  Phone?: ModelStringInput | null,
  ServiceName?: ModelStringInput | null,
  SCAC?: ModelStringInput | null,
  and?: Array< ModelCarrierConditionInput | null > | null,
  or?: Array< ModelCarrierConditionInput | null > | null,
  not?: ModelCarrierConditionInput | null,
};

export type Carrier = {
  __typename: "Carrier",
  id: string,
  Email?: string | null,
  Phone?: string | null,
  Address?: Address | null,
  ServiceName?: string | null,
  SCAC?: string | null,
  CarrierInvitations?: ModelCarrierInvitationConnection | null,
  CarrierCompanies?: ModelCarrierCompaniesConnection | null,
  RateBatches?: ModelRateBatchConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Address = {
  __typename: "Address",
  Address?: string | null,
  City?: string | null,
  Country?: string | null,
  Zipcode?: string | null,
  State?: string | null,
};

export type ModelCarrierInvitationConnection = {
  __typename: "ModelCarrierInvitationConnection",
  items:  Array<CarrierInvitation | null >,
  nextToken?: string | null,
};

export type CarrierInvitation = {
  __typename: "CarrierInvitation",
  id: string,
  InviterId?: string | null,
  inviteeEmail?: string | null,
  status?: Status | null,
  inviteeId?: string | null,
  CompanyName?: string | null,
  companyID: string,
  carrierID: string,
  InviterEmail?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelCarrierCompaniesConnection = {
  __typename: "ModelCarrierCompaniesConnection",
  items:  Array<CarrierCompanies | null >,
  nextToken?: string | null,
};

export type ModelRateBatchConnection = {
  __typename: "ModelRateBatchConnection",
  items:  Array<RateBatch | null >,
  nextToken?: string | null,
};

export type UpdateCarrierInput = {
  id: string,
  Email?: string | null,
  Phone?: string | null,
  Address?: AddressInput | null,
  ServiceName?: string | null,
  SCAC?: string | null,
};

export type DeleteCarrierInput = {
  id: string,
};

export type CreateCarrierInvitationInput = {
  id?: string | null,
  InviterId?: string | null,
  inviteeEmail?: string | null,
  status?: Status | null,
  inviteeId?: string | null,
  CompanyName?: string | null,
  companyID: string,
  carrierID: string,
  InviterEmail?: string | null,
};

export type ModelCarrierInvitationConditionInput = {
  InviterId?: ModelStringInput | null,
  inviteeEmail?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  inviteeId?: ModelStringInput | null,
  CompanyName?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  carrierID?: ModelIDInput | null,
  InviterEmail?: ModelStringInput | null,
  and?: Array< ModelCarrierInvitationConditionInput | null > | null,
  or?: Array< ModelCarrierInvitationConditionInput | null > | null,
  not?: ModelCarrierInvitationConditionInput | null,
};

export type UpdateCarrierInvitationInput = {
  id: string,
  InviterId?: string | null,
  inviteeEmail?: string | null,
  status?: Status | null,
  inviteeId?: string | null,
  CompanyName?: string | null,
  companyID?: string | null,
  carrierID?: string | null,
  InviterEmail?: string | null,
};

export type DeleteCarrierInvitationInput = {
  id: string,
};

export type CreateCompanyInput = {
  id?: string | null,
  Name?: string | null,
  PhoneNumber?: string | null,
  Address?: AddressInput | null,
  Domain?: string | null,
  SubscriptionStatus?: SubscriptionStatus | null,
  SubscriptionEndDate?: number | null,
  MaxSourcingUsers?: number | null,
  MaxCarrierUsers?: number | null,
  CurrentSourcingUsers?: number | null,
  CurrentCarrierUsers?: number | null,
  SubscriptionId?: string | null,
  Email?: string | null,
  SubscriptionNextBillCost?: number | null,
  PaymentNextRetryDate?: number | null,
  CustomerId?: string | null,
};

export type ModelCompanyConditionInput = {
  Name?: ModelStringInput | null,
  PhoneNumber?: ModelStringInput | null,
  Domain?: ModelStringInput | null,
  SubscriptionStatus?: ModelSubscriptionStatusInput | null,
  SubscriptionEndDate?: ModelIntInput | null,
  MaxSourcingUsers?: ModelIntInput | null,
  MaxCarrierUsers?: ModelIntInput | null,
  CurrentSourcingUsers?: ModelIntInput | null,
  CurrentCarrierUsers?: ModelIntInput | null,
  SubscriptionId?: ModelStringInput | null,
  Email?: ModelStringInput | null,
  SubscriptionNextBillCost?: ModelFloatInput | null,
  PaymentNextRetryDate?: ModelIntInput | null,
  CustomerId?: ModelStringInput | null,
  and?: Array< ModelCompanyConditionInput | null > | null,
  or?: Array< ModelCompanyConditionInput | null > | null,
  not?: ModelCompanyConditionInput | null,
};

export type Company = {
  __typename: "Company",
  id: string,
  Name?: string | null,
  PhoneNumber?: string | null,
  Address?: Address | null,
  Domain?: string | null,
  CarrierInvitations?: ModelCarrierInvitationConnection | null,
  Departments?: ModelDepartmentsConnection | null,
  SubscriptionStatus?: SubscriptionStatus | null,
  SubscriptionEndDate?: number | null,
  BillingHistories?: ModelBillingHistoryConnection | null,
  UserInvitations?: ModelUserInvitationConnection | null,
  MaxSourcingUsers?: number | null,
  MaxCarrierUsers?: number | null,
  CurrentSourcingUsers?: number | null,
  CurrentCarrierUsers?: number | null,
  SubscriptionId?: string | null,
  Email?: string | null,
  RateBatches?: ModelRateBatchConnection | null,
  SubscriptionNextBillCost?: number | null,
  PaymentNextRetryDate?: number | null,
  CustomerId?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelDepartmentsConnection = {
  __typename: "ModelDepartmentsConnection",
  items:  Array<Departments | null >,
  nextToken?: string | null,
};

export type ModelBillingHistoryConnection = {
  __typename: "ModelBillingHistoryConnection",
  items:  Array<BillingHistory | null >,
  nextToken?: string | null,
};

export type ModelUserInvitationConnection = {
  __typename: "ModelUserInvitationConnection",
  items:  Array<UserInvitation | null >,
  nextToken?: string | null,
};

export type UpdateCompanyInput = {
  id: string,
  Name?: string | null,
  PhoneNumber?: string | null,
  Address?: AddressInput | null,
  Domain?: string | null,
  SubscriptionStatus?: SubscriptionStatus | null,
  SubscriptionEndDate?: number | null,
  MaxSourcingUsers?: number | null,
  MaxCarrierUsers?: number | null,
  CurrentSourcingUsers?: number | null,
  CurrentCarrierUsers?: number | null,
  SubscriptionId?: string | null,
  Email?: string | null,
  SubscriptionNextBillCost?: number | null,
  PaymentNextRetryDate?: number | null,
  CustomerId?: string | null,
};

export type DeleteCompanyInput = {
  id: string,
};

export type ModelPaymentResultFilterInput = {
  id?: ModelIDInput | null,
  billinghistoryID?: ModelIDInput | null,
  Invoice?: ModelStringInput | null,
  date?: ModelIntInput | null,
  transactionStatus?: ModelStringInput | null,
  and?: Array< ModelPaymentResultFilterInput | null > | null,
  or?: Array< ModelPaymentResultFilterInput | null > | null,
  not?: ModelPaymentResultFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelRateBatchFilterInput = {
  id?: ModelIDInput | null,
  companyID?: ModelIDInput | null,
  carrierID?: ModelIDInput | null,
  status?: ModelStatusInput | null,
  tsMode?: ModelStringInput | null,
  scac?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  and?: Array< ModelRateBatchFilterInput | null > | null,
  or?: Array< ModelRateBatchFilterInput | null > | null,
  not?: ModelRateBatchFilterInput | null,
};

export type ModelUserInvitationFilterInput = {
  id?: ModelIDInput | null,
  inviteeEmail?: ModelStringInput | null,
  inviteeId?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  and?: Array< ModelUserInvitationFilterInput | null > | null,
  or?: Array< ModelUserInvitationFilterInput | null > | null,
  not?: ModelUserInvitationFilterInput | null,
};

export type ModelBillingHistoryFilterInput = {
  id?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  status?: ModelSubscriptionStatusInput | null,
  billingDate?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  startPeriod?: ModelIntInput | null,
  endPeriod?: ModelIntInput | null,
  and?: Array< ModelBillingHistoryFilterInput | null > | null,
  or?: Array< ModelBillingHistoryFilterInput | null > | null,
  not?: ModelBillingHistoryFilterInput | null,
};

export type ModelCarrierCompaniesFilterInput = {
  id?: ModelIDInput | null,
  Name?: ModelStringInput | null,
  companyID?: ModelStringInput | null,
  carrierID?: ModelIDInput | null,
  and?: Array< ModelCarrierCompaniesFilterInput | null > | null,
  or?: Array< ModelCarrierCompaniesFilterInput | null > | null,
  not?: ModelCarrierCompaniesFilterInput | null,
};

export type ModelRatesFilterInput = {
  id?: ModelIDInput | null,
  tsMode?: ModelRateTypeInput | null,
  data?: ModelStringInput | null,
  scac?: ModelStringInput | null,
  serviceName?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  originCity?: ModelStringInput | null,
  originCountry?: ModelStringInput | null,
  originProvince?: ModelStringInput | null,
  destinationCountry?: ModelStringInput | null,
  destinationCity?: ModelStringInput | null,
  destinationProvince?: ModelStringInput | null,
  originRegion?: ModelStringInput | null,
  destinationRegion?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  ownerEmail?: ModelStringInput | null,
  ratebatchID?: ModelIDInput | null,
  and?: Array< ModelRatesFilterInput | null > | null,
  or?: Array< ModelRatesFilterInput | null > | null,
  not?: ModelRatesFilterInput | null,
};

export type ModelDepartmentsFilterInput = {
  id?: ModelIDInput | null,
  DepartmentName?: ModelStringInput | null,
  DepartmentSCAC?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  and?: Array< ModelDepartmentsFilterInput | null > | null,
  or?: Array< ModelDepartmentsFilterInput | null > | null,
  not?: ModelDepartmentsFilterInput | null,
};

export type ModelCarrierFilterInput = {
  id?: ModelIDInput | null,
  Email?: ModelStringInput | null,
  Phone?: ModelStringInput | null,
  ServiceName?: ModelStringInput | null,
  SCAC?: ModelStringInput | null,
  and?: Array< ModelCarrierFilterInput | null > | null,
  or?: Array< ModelCarrierFilterInput | null > | null,
  not?: ModelCarrierFilterInput | null,
};

export type ModelCarrierConnection = {
  __typename: "ModelCarrierConnection",
  items:  Array<Carrier | null >,
  nextToken?: string | null,
};

export type ModelCarrierInvitationFilterInput = {
  id?: ModelIDInput | null,
  InviterId?: ModelStringInput | null,
  inviteeEmail?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  inviteeId?: ModelStringInput | null,
  CompanyName?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  carrierID?: ModelIDInput | null,
  InviterEmail?: ModelStringInput | null,
  and?: Array< ModelCarrierInvitationFilterInput | null > | null,
  or?: Array< ModelCarrierInvitationFilterInput | null > | null,
  not?: ModelCarrierInvitationFilterInput | null,
};

export type ModelCompanyFilterInput = {
  id?: ModelIDInput | null,
  Name?: ModelStringInput | null,
  PhoneNumber?: ModelStringInput | null,
  Domain?: ModelStringInput | null,
  SubscriptionStatus?: ModelSubscriptionStatusInput | null,
  SubscriptionEndDate?: ModelIntInput | null,
  MaxSourcingUsers?: ModelIntInput | null,
  MaxCarrierUsers?: ModelIntInput | null,
  CurrentSourcingUsers?: ModelIntInput | null,
  CurrentCarrierUsers?: ModelIntInput | null,
  SubscriptionId?: ModelStringInput | null,
  Email?: ModelStringInput | null,
  SubscriptionNextBillCost?: ModelFloatInput | null,
  PaymentNextRetryDate?: ModelIntInput | null,
  CustomerId?: ModelStringInput | null,
  and?: Array< ModelCompanyFilterInput | null > | null,
  or?: Array< ModelCompanyFilterInput | null > | null,
  not?: ModelCompanyFilterInput | null,
};

export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection",
  items:  Array<Company | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionPaymentResultFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  billinghistoryID?: ModelSubscriptionIDInput | null,
  Invoice?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionIntInput | null,
  transactionStatus?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPaymentResultFilterInput | null > | null,
  or?: Array< ModelSubscriptionPaymentResultFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionRateBatchFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  companyID?: ModelSubscriptionIDInput | null,
  carrierID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  tsMode?: ModelSubscriptionStringInput | null,
  scac?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRateBatchFilterInput | null > | null,
  or?: Array< ModelSubscriptionRateBatchFilterInput | null > | null,
};

export type ModelSubscriptionUserInvitationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  inviteeEmail?: ModelSubscriptionStringInput | null,
  inviteeId?: ModelSubscriptionStringInput | null,
  companyID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionUserInvitationFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserInvitationFilterInput | null > | null,
};

export type ModelSubscriptionBillingHistoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  status?: ModelSubscriptionStringInput | null,
  billingDate?: ModelSubscriptionStringInput | null,
  companyID?: ModelSubscriptionIDInput | null,
  startPeriod?: ModelSubscriptionIntInput | null,
  endPeriod?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionBillingHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionBillingHistoryFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionCarrierCompaniesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  Name?: ModelSubscriptionStringInput | null,
  companyID?: ModelSubscriptionStringInput | null,
  carrierID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCarrierCompaniesFilterInput | null > | null,
  or?: Array< ModelSubscriptionCarrierCompaniesFilterInput | null > | null,
};

export type ModelSubscriptionRatesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tsMode?: ModelSubscriptionStringInput | null,
  data?: ModelSubscriptionStringInput | null,
  scac?: ModelSubscriptionStringInput | null,
  serviceName?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  originCity?: ModelSubscriptionStringInput | null,
  originCountry?: ModelSubscriptionStringInput | null,
  originProvince?: ModelSubscriptionStringInput | null,
  destinationCountry?: ModelSubscriptionStringInput | null,
  destinationCity?: ModelSubscriptionStringInput | null,
  destinationProvince?: ModelSubscriptionStringInput | null,
  originRegion?: ModelSubscriptionStringInput | null,
  destinationRegion?: ModelSubscriptionStringInput | null,
  ownerId?: ModelSubscriptionIDInput | null,
  ownerEmail?: ModelSubscriptionStringInput | null,
  ratebatchID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionRatesFilterInput | null > | null,
  or?: Array< ModelSubscriptionRatesFilterInput | null > | null,
};

export type ModelSubscriptionDepartmentsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  DepartmentName?: ModelSubscriptionStringInput | null,
  DepartmentSCAC?: ModelSubscriptionStringInput | null,
  companyID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionDepartmentsFilterInput | null > | null,
  or?: Array< ModelSubscriptionDepartmentsFilterInput | null > | null,
};

export type ModelSubscriptionCarrierFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  Email?: ModelSubscriptionStringInput | null,
  Phone?: ModelSubscriptionStringInput | null,
  ServiceName?: ModelSubscriptionStringInput | null,
  SCAC?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCarrierFilterInput | null > | null,
  or?: Array< ModelSubscriptionCarrierFilterInput | null > | null,
};

export type ModelSubscriptionCarrierInvitationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  InviterId?: ModelSubscriptionStringInput | null,
  inviteeEmail?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  inviteeId?: ModelSubscriptionStringInput | null,
  CompanyName?: ModelSubscriptionStringInput | null,
  companyID?: ModelSubscriptionIDInput | null,
  carrierID?: ModelSubscriptionIDInput | null,
  InviterEmail?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCarrierInvitationFilterInput | null > | null,
  or?: Array< ModelSubscriptionCarrierInvitationFilterInput | null > | null,
};

export type ModelSubscriptionCompanyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  Name?: ModelSubscriptionStringInput | null,
  PhoneNumber?: ModelSubscriptionStringInput | null,
  Domain?: ModelSubscriptionStringInput | null,
  SubscriptionStatus?: ModelSubscriptionStringInput | null,
  SubscriptionEndDate?: ModelSubscriptionIntInput | null,
  MaxSourcingUsers?: ModelSubscriptionIntInput | null,
  MaxCarrierUsers?: ModelSubscriptionIntInput | null,
  CurrentSourcingUsers?: ModelSubscriptionIntInput | null,
  CurrentCarrierUsers?: ModelSubscriptionIntInput | null,
  SubscriptionId?: ModelSubscriptionStringInput | null,
  Email?: ModelSubscriptionStringInput | null,
  SubscriptionNextBillCost?: ModelSubscriptionFloatInput | null,
  PaymentNextRetryDate?: ModelSubscriptionIntInput | null,
  CustomerId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCompanyFilterInput | null > | null,
  or?: Array< ModelSubscriptionCompanyFilterInput | null > | null,
};

export type CreatePaymentResultMutationVariables = {
  input: CreatePaymentResultInput,
  condition?: ModelPaymentResultConditionInput | null,
};

export type CreatePaymentResultMutation = {
  createPaymentResult?:  {
    __typename: "PaymentResult",
    id: string,
    billinghistoryID: string,
    Invoice?: string | null,
    date?: number | null,
    transactionStatus?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePaymentResultMutationVariables = {
  input: UpdatePaymentResultInput,
  condition?: ModelPaymentResultConditionInput | null,
};

export type UpdatePaymentResultMutation = {
  updatePaymentResult?:  {
    __typename: "PaymentResult",
    id: string,
    billinghistoryID: string,
    Invoice?: string | null,
    date?: number | null,
    transactionStatus?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePaymentResultMutationVariables = {
  input: DeletePaymentResultInput,
  condition?: ModelPaymentResultConditionInput | null,
};

export type DeletePaymentResultMutation = {
  deletePaymentResult?:  {
    __typename: "PaymentResult",
    id: string,
    billinghistoryID: string,
    Invoice?: string | null,
    date?: number | null,
    transactionStatus?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateRateBatchMutationVariables = {
  input: CreateRateBatchInput,
  condition?: ModelRateBatchConditionInput | null,
};

export type CreateRateBatchMutation = {
  createRateBatch?:  {
    __typename: "RateBatch",
    id: string,
    Rates?:  {
      __typename: "ModelRatesConnection",
      items:  Array< {
        __typename: "Rates",
        id: string,
        tsMode?: RateType | null,
        data?: string | null,
        scac?: string | null,
        serviceName?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        originCity?: string | null,
        originCountry?: string | null,
        originProvince?: string | null,
        destinationCountry?: string | null,
        destinationCity?: string | null,
        destinationProvince?: string | null,
        originRegion?: string | null,
        destinationRegion?: string | null,
        ownerId?: string | null,
        ownerEmail?: string | null,
        ratebatchID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    companyID: string,
    carrierID: string,
    status?: Status | null,
    tsMode?: string | null,
    scac?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateRateBatchMutationVariables = {
  input: UpdateRateBatchInput,
  condition?: ModelRateBatchConditionInput | null,
};

export type UpdateRateBatchMutation = {
  updateRateBatch?:  {
    __typename: "RateBatch",
    id: string,
    Rates?:  {
      __typename: "ModelRatesConnection",
      items:  Array< {
        __typename: "Rates",
        id: string,
        tsMode?: RateType | null,
        data?: string | null,
        scac?: string | null,
        serviceName?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        originCity?: string | null,
        originCountry?: string | null,
        originProvince?: string | null,
        destinationCountry?: string | null,
        destinationCity?: string | null,
        destinationProvince?: string | null,
        originRegion?: string | null,
        destinationRegion?: string | null,
        ownerId?: string | null,
        ownerEmail?: string | null,
        ratebatchID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    companyID: string,
    carrierID: string,
    status?: Status | null,
    tsMode?: string | null,
    scac?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteRateBatchMutationVariables = {
  input: DeleteRateBatchInput,
  condition?: ModelRateBatchConditionInput | null,
};

export type DeleteRateBatchMutation = {
  deleteRateBatch?:  {
    __typename: "RateBatch",
    id: string,
    Rates?:  {
      __typename: "ModelRatesConnection",
      items:  Array< {
        __typename: "Rates",
        id: string,
        tsMode?: RateType | null,
        data?: string | null,
        scac?: string | null,
        serviceName?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        originCity?: string | null,
        originCountry?: string | null,
        originProvince?: string | null,
        destinationCountry?: string | null,
        destinationCity?: string | null,
        destinationProvince?: string | null,
        originRegion?: string | null,
        destinationRegion?: string | null,
        ownerId?: string | null,
        ownerEmail?: string | null,
        ratebatchID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    companyID: string,
    carrierID: string,
    status?: Status | null,
    tsMode?: string | null,
    scac?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUserInvitationMutationVariables = {
  input: CreateUserInvitationInput,
  condition?: ModelUserInvitationConditionInput | null,
};

export type CreateUserInvitationMutation = {
  createUserInvitation?:  {
    __typename: "UserInvitation",
    id: string,
    inviteeEmail?: string | null,
    inviteeId?: string | null,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserInvitationMutationVariables = {
  input: UpdateUserInvitationInput,
  condition?: ModelUserInvitationConditionInput | null,
};

export type UpdateUserInvitationMutation = {
  updateUserInvitation?:  {
    __typename: "UserInvitation",
    id: string,
    inviteeEmail?: string | null,
    inviteeId?: string | null,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserInvitationMutationVariables = {
  input: DeleteUserInvitationInput,
  condition?: ModelUserInvitationConditionInput | null,
};

export type DeleteUserInvitationMutation = {
  deleteUserInvitation?:  {
    __typename: "UserInvitation",
    id: string,
    inviteeEmail?: string | null,
    inviteeId?: string | null,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateBillingHistoryMutationVariables = {
  input: CreateBillingHistoryInput,
  condition?: ModelBillingHistoryConditionInput | null,
};

export type CreateBillingHistoryMutation = {
  createBillingHistory?:  {
    __typename: "BillingHistory",
    id: string,
    amount?: number | null,
    status?: SubscriptionStatus | null,
    billingDate?: string | null,
    companyID: string,
    startPeriod?: number | null,
    endPeriod?: number | null,
    PaymentResult?:  {
      __typename: "ModelPaymentResultConnection",
      items:  Array< {
        __typename: "PaymentResult",
        id: string,
        billinghistoryID: string,
        Invoice?: string | null,
        date?: number | null,
        transactionStatus?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateBillingHistoryMutationVariables = {
  input: UpdateBillingHistoryInput,
  condition?: ModelBillingHistoryConditionInput | null,
};

export type UpdateBillingHistoryMutation = {
  updateBillingHistory?:  {
    __typename: "BillingHistory",
    id: string,
    amount?: number | null,
    status?: SubscriptionStatus | null,
    billingDate?: string | null,
    companyID: string,
    startPeriod?: number | null,
    endPeriod?: number | null,
    PaymentResult?:  {
      __typename: "ModelPaymentResultConnection",
      items:  Array< {
        __typename: "PaymentResult",
        id: string,
        billinghistoryID: string,
        Invoice?: string | null,
        date?: number | null,
        transactionStatus?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteBillingHistoryMutationVariables = {
  input: DeleteBillingHistoryInput,
  condition?: ModelBillingHistoryConditionInput | null,
};

export type DeleteBillingHistoryMutation = {
  deleteBillingHistory?:  {
    __typename: "BillingHistory",
    id: string,
    amount?: number | null,
    status?: SubscriptionStatus | null,
    billingDate?: string | null,
    companyID: string,
    startPeriod?: number | null,
    endPeriod?: number | null,
    PaymentResult?:  {
      __typename: "ModelPaymentResultConnection",
      items:  Array< {
        __typename: "PaymentResult",
        id: string,
        billinghistoryID: string,
        Invoice?: string | null,
        date?: number | null,
        transactionStatus?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCarrierCompaniesMutationVariables = {
  input: CreateCarrierCompaniesInput,
  condition?: ModelCarrierCompaniesConditionInput | null,
};

export type CreateCarrierCompaniesMutation = {
  createCarrierCompanies?:  {
    __typename: "CarrierCompanies",
    id: string,
    Name?: string | null,
    companyID?: string | null,
    carrierID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCarrierCompaniesMutationVariables = {
  input: UpdateCarrierCompaniesInput,
  condition?: ModelCarrierCompaniesConditionInput | null,
};

export type UpdateCarrierCompaniesMutation = {
  updateCarrierCompanies?:  {
    __typename: "CarrierCompanies",
    id: string,
    Name?: string | null,
    companyID?: string | null,
    carrierID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCarrierCompaniesMutationVariables = {
  input: DeleteCarrierCompaniesInput,
  condition?: ModelCarrierCompaniesConditionInput | null,
};

export type DeleteCarrierCompaniesMutation = {
  deleteCarrierCompanies?:  {
    __typename: "CarrierCompanies",
    id: string,
    Name?: string | null,
    companyID?: string | null,
    carrierID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRatesMutationVariables = {
  input: CreateRatesInput,
  condition?: ModelRatesConditionInput | null,
};

export type CreateRatesMutation = {
  createRates?:  {
    __typename: "Rates",
    id: string,
    tsMode?: RateType | null,
    data?: string | null,
    scac?: string | null,
    serviceName?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    originCity?: string | null,
    originCountry?: string | null,
    originProvince?: string | null,
    destinationCountry?: string | null,
    destinationCity?: string | null,
    destinationProvince?: string | null,
    originRegion?: string | null,
    destinationRegion?: string | null,
    ownerId?: string | null,
    ownerEmail?: string | null,
    ratebatchID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateRatesMutationVariables = {
  input: UpdateRatesInput,
  condition?: ModelRatesConditionInput | null,
};

export type UpdateRatesMutation = {
  updateRates?:  {
    __typename: "Rates",
    id: string,
    tsMode?: RateType | null,
    data?: string | null,
    scac?: string | null,
    serviceName?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    originCity?: string | null,
    originCountry?: string | null,
    originProvince?: string | null,
    destinationCountry?: string | null,
    destinationCity?: string | null,
    destinationProvince?: string | null,
    originRegion?: string | null,
    destinationRegion?: string | null,
    ownerId?: string | null,
    ownerEmail?: string | null,
    ratebatchID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteRatesMutationVariables = {
  input: DeleteRatesInput,
  condition?: ModelRatesConditionInput | null,
};

export type DeleteRatesMutation = {
  deleteRates?:  {
    __typename: "Rates",
    id: string,
    tsMode?: RateType | null,
    data?: string | null,
    scac?: string | null,
    serviceName?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    originCity?: string | null,
    originCountry?: string | null,
    originProvince?: string | null,
    destinationCountry?: string | null,
    destinationCity?: string | null,
    destinationProvince?: string | null,
    originRegion?: string | null,
    destinationRegion?: string | null,
    ownerId?: string | null,
    ownerEmail?: string | null,
    ratebatchID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateDepartmentsMutationVariables = {
  input: CreateDepartmentsInput,
  condition?: ModelDepartmentsConditionInput | null,
};

export type CreateDepartmentsMutation = {
  createDepartments?:  {
    __typename: "Departments",
    id: string,
    DepartmentName: string,
    DepartmentSCAC: string,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateDepartmentsMutationVariables = {
  input: UpdateDepartmentsInput,
  condition?: ModelDepartmentsConditionInput | null,
};

export type UpdateDepartmentsMutation = {
  updateDepartments?:  {
    __typename: "Departments",
    id: string,
    DepartmentName: string,
    DepartmentSCAC: string,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteDepartmentsMutationVariables = {
  input: DeleteDepartmentsInput,
  condition?: ModelDepartmentsConditionInput | null,
};

export type DeleteDepartmentsMutation = {
  deleteDepartments?:  {
    __typename: "Departments",
    id: string,
    DepartmentName: string,
    DepartmentSCAC: string,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCarrierMutationVariables = {
  input: CreateCarrierInput,
  condition?: ModelCarrierConditionInput | null,
};

export type CreateCarrierMutation = {
  createCarrier?:  {
    __typename: "Carrier",
    id: string,
    Email?: string | null,
    Phone?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    ServiceName?: string | null,
    SCAC?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    CarrierCompanies?:  {
      __typename: "ModelCarrierCompaniesConnection",
      items:  Array< {
        __typename: "CarrierCompanies",
        id: string,
        Name?: string | null,
        companyID?: string | null,
        carrierID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCarrierMutationVariables = {
  input: UpdateCarrierInput,
  condition?: ModelCarrierConditionInput | null,
};

export type UpdateCarrierMutation = {
  updateCarrier?:  {
    __typename: "Carrier",
    id: string,
    Email?: string | null,
    Phone?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    ServiceName?: string | null,
    SCAC?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    CarrierCompanies?:  {
      __typename: "ModelCarrierCompaniesConnection",
      items:  Array< {
        __typename: "CarrierCompanies",
        id: string,
        Name?: string | null,
        companyID?: string | null,
        carrierID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCarrierMutationVariables = {
  input: DeleteCarrierInput,
  condition?: ModelCarrierConditionInput | null,
};

export type DeleteCarrierMutation = {
  deleteCarrier?:  {
    __typename: "Carrier",
    id: string,
    Email?: string | null,
    Phone?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    ServiceName?: string | null,
    SCAC?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    CarrierCompanies?:  {
      __typename: "ModelCarrierCompaniesConnection",
      items:  Array< {
        __typename: "CarrierCompanies",
        id: string,
        Name?: string | null,
        companyID?: string | null,
        carrierID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCarrierInvitationMutationVariables = {
  input: CreateCarrierInvitationInput,
  condition?: ModelCarrierInvitationConditionInput | null,
};

export type CreateCarrierInvitationMutation = {
  createCarrierInvitation?:  {
    __typename: "CarrierInvitation",
    id: string,
    InviterId?: string | null,
    inviteeEmail?: string | null,
    status?: Status | null,
    inviteeId?: string | null,
    CompanyName?: string | null,
    companyID: string,
    carrierID: string,
    InviterEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCarrierInvitationMutationVariables = {
  input: UpdateCarrierInvitationInput,
  condition?: ModelCarrierInvitationConditionInput | null,
};

export type UpdateCarrierInvitationMutation = {
  updateCarrierInvitation?:  {
    __typename: "CarrierInvitation",
    id: string,
    InviterId?: string | null,
    inviteeEmail?: string | null,
    status?: Status | null,
    inviteeId?: string | null,
    CompanyName?: string | null,
    companyID: string,
    carrierID: string,
    InviterEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCarrierInvitationMutationVariables = {
  input: DeleteCarrierInvitationInput,
  condition?: ModelCarrierInvitationConditionInput | null,
};

export type DeleteCarrierInvitationMutation = {
  deleteCarrierInvitation?:  {
    __typename: "CarrierInvitation",
    id: string,
    InviterId?: string | null,
    inviteeEmail?: string | null,
    status?: Status | null,
    inviteeId?: string | null,
    CompanyName?: string | null,
    companyID: string,
    carrierID: string,
    InviterEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type CreateCompanyMutation = {
  createCompany?:  {
    __typename: "Company",
    id: string,
    Name?: string | null,
    PhoneNumber?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    Domain?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    Departments?:  {
      __typename: "ModelDepartmentsConnection",
      items:  Array< {
        __typename: "Departments",
        id: string,
        DepartmentName: string,
        DepartmentSCAC: string,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionStatus?: SubscriptionStatus | null,
    SubscriptionEndDate?: number | null,
    BillingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      items:  Array< {
        __typename: "BillingHistory",
        id: string,
        amount?: number | null,
        status?: SubscriptionStatus | null,
        billingDate?: string | null,
        companyID: string,
        startPeriod?: number | null,
        endPeriod?: number | null,
        PaymentResult?:  {
          __typename: "ModelPaymentResultConnection",
          items:  Array< {
            __typename: "PaymentResult",
            id: string,
            billinghistoryID: string,
            Invoice?: string | null,
            date?: number | null,
            transactionStatus?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    UserInvitations?:  {
      __typename: "ModelUserInvitationConnection",
      items:  Array< {
        __typename: "UserInvitation",
        id: string,
        inviteeEmail?: string | null,
        inviteeId?: string | null,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MaxSourcingUsers?: number | null,
    MaxCarrierUsers?: number | null,
    CurrentSourcingUsers?: number | null,
    CurrentCarrierUsers?: number | null,
    SubscriptionId?: string | null,
    Email?: string | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionNextBillCost?: number | null,
    PaymentNextRetryDate?: number | null,
    CustomerId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type UpdateCompanyMutation = {
  updateCompany?:  {
    __typename: "Company",
    id: string,
    Name?: string | null,
    PhoneNumber?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    Domain?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    Departments?:  {
      __typename: "ModelDepartmentsConnection",
      items:  Array< {
        __typename: "Departments",
        id: string,
        DepartmentName: string,
        DepartmentSCAC: string,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionStatus?: SubscriptionStatus | null,
    SubscriptionEndDate?: number | null,
    BillingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      items:  Array< {
        __typename: "BillingHistory",
        id: string,
        amount?: number | null,
        status?: SubscriptionStatus | null,
        billingDate?: string | null,
        companyID: string,
        startPeriod?: number | null,
        endPeriod?: number | null,
        PaymentResult?:  {
          __typename: "ModelPaymentResultConnection",
          items:  Array< {
            __typename: "PaymentResult",
            id: string,
            billinghistoryID: string,
            Invoice?: string | null,
            date?: number | null,
            transactionStatus?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    UserInvitations?:  {
      __typename: "ModelUserInvitationConnection",
      items:  Array< {
        __typename: "UserInvitation",
        id: string,
        inviteeEmail?: string | null,
        inviteeId?: string | null,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MaxSourcingUsers?: number | null,
    MaxCarrierUsers?: number | null,
    CurrentSourcingUsers?: number | null,
    CurrentCarrierUsers?: number | null,
    SubscriptionId?: string | null,
    Email?: string | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionNextBillCost?: number | null,
    PaymentNextRetryDate?: number | null,
    CustomerId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type DeleteCompanyMutation = {
  deleteCompany?:  {
    __typename: "Company",
    id: string,
    Name?: string | null,
    PhoneNumber?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    Domain?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    Departments?:  {
      __typename: "ModelDepartmentsConnection",
      items:  Array< {
        __typename: "Departments",
        id: string,
        DepartmentName: string,
        DepartmentSCAC: string,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionStatus?: SubscriptionStatus | null,
    SubscriptionEndDate?: number | null,
    BillingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      items:  Array< {
        __typename: "BillingHistory",
        id: string,
        amount?: number | null,
        status?: SubscriptionStatus | null,
        billingDate?: string | null,
        companyID: string,
        startPeriod?: number | null,
        endPeriod?: number | null,
        PaymentResult?:  {
          __typename: "ModelPaymentResultConnection",
          items:  Array< {
            __typename: "PaymentResult",
            id: string,
            billinghistoryID: string,
            Invoice?: string | null,
            date?: number | null,
            transactionStatus?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    UserInvitations?:  {
      __typename: "ModelUserInvitationConnection",
      items:  Array< {
        __typename: "UserInvitation",
        id: string,
        inviteeEmail?: string | null,
        inviteeId?: string | null,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MaxSourcingUsers?: number | null,
    MaxCarrierUsers?: number | null,
    CurrentSourcingUsers?: number | null,
    CurrentCarrierUsers?: number | null,
    SubscriptionId?: string | null,
    Email?: string | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionNextBillCost?: number | null,
    PaymentNextRetryDate?: number | null,
    CustomerId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type GetPaymentResultQueryVariables = {
  id: string,
};

export type GetPaymentResultQuery = {
  getPaymentResult?:  {
    __typename: "PaymentResult",
    id: string,
    billinghistoryID: string,
    Invoice?: string | null,
    date?: number | null,
    transactionStatus?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPaymentResultsQueryVariables = {
  filter?: ModelPaymentResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentResultsQuery = {
  listPaymentResults?:  {
    __typename: "ModelPaymentResultConnection",
    items:  Array< {
      __typename: "PaymentResult",
      id: string,
      billinghistoryID: string,
      Invoice?: string | null,
      date?: number | null,
      transactionStatus?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentResultsByBillinghistoryIDQueryVariables = {
  billinghistoryID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentResultsByBillinghistoryIDQuery = {
  paymentResultsByBillinghistoryID?:  {
    __typename: "ModelPaymentResultConnection",
    items:  Array< {
      __typename: "PaymentResult",
      id: string,
      billinghistoryID: string,
      Invoice?: string | null,
      date?: number | null,
      transactionStatus?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRateBatchQueryVariables = {
  id: string,
};

export type GetRateBatchQuery = {
  getRateBatch?:  {
    __typename: "RateBatch",
    id: string,
    Rates?:  {
      __typename: "ModelRatesConnection",
      items:  Array< {
        __typename: "Rates",
        id: string,
        tsMode?: RateType | null,
        data?: string | null,
        scac?: string | null,
        serviceName?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        originCity?: string | null,
        originCountry?: string | null,
        originProvince?: string | null,
        destinationCountry?: string | null,
        destinationCity?: string | null,
        destinationProvince?: string | null,
        originRegion?: string | null,
        destinationRegion?: string | null,
        ownerId?: string | null,
        ownerEmail?: string | null,
        ratebatchID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    companyID: string,
    carrierID: string,
    status?: Status | null,
    tsMode?: string | null,
    scac?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListRateBatchesQueryVariables = {
  filter?: ModelRateBatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRateBatchesQuery = {
  listRateBatches?:  {
    __typename: "ModelRateBatchConnection",
    items:  Array< {
      __typename: "RateBatch",
      id: string,
      Rates?:  {
        __typename: "ModelRatesConnection",
        items:  Array< {
          __typename: "Rates",
          id: string,
          tsMode?: RateType | null,
          data?: string | null,
          scac?: string | null,
          serviceName?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          originCity?: string | null,
          originCountry?: string | null,
          originProvince?: string | null,
          destinationCountry?: string | null,
          destinationCity?: string | null,
          destinationProvince?: string | null,
          originRegion?: string | null,
          destinationRegion?: string | null,
          ownerId?: string | null,
          ownerEmail?: string | null,
          ratebatchID: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      companyID: string,
      carrierID: string,
      status?: Status | null,
      tsMode?: string | null,
      scac?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RateBatchesByCompanyIDQueryVariables = {
  companyID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRateBatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RateBatchesByCompanyIDQuery = {
  rateBatchesByCompanyID?:  {
    __typename: "ModelRateBatchConnection",
    items:  Array< {
      __typename: "RateBatch",
      id: string,
      Rates?:  {
        __typename: "ModelRatesConnection",
        items:  Array< {
          __typename: "Rates",
          id: string,
          tsMode?: RateType | null,
          data?: string | null,
          scac?: string | null,
          serviceName?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          originCity?: string | null,
          originCountry?: string | null,
          originProvince?: string | null,
          destinationCountry?: string | null,
          destinationCity?: string | null,
          destinationProvince?: string | null,
          originRegion?: string | null,
          destinationRegion?: string | null,
          ownerId?: string | null,
          ownerEmail?: string | null,
          ratebatchID: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      companyID: string,
      carrierID: string,
      status?: Status | null,
      tsMode?: string | null,
      scac?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RateBatchesByCarrierIDQueryVariables = {
  carrierID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRateBatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RateBatchesByCarrierIDQuery = {
  rateBatchesByCarrierID?:  {
    __typename: "ModelRateBatchConnection",
    items:  Array< {
      __typename: "RateBatch",
      id: string,
      Rates?:  {
        __typename: "ModelRatesConnection",
        items:  Array< {
          __typename: "Rates",
          id: string,
          tsMode?: RateType | null,
          data?: string | null,
          scac?: string | null,
          serviceName?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          originCity?: string | null,
          originCountry?: string | null,
          originProvince?: string | null,
          destinationCountry?: string | null,
          destinationCity?: string | null,
          destinationProvince?: string | null,
          originRegion?: string | null,
          destinationRegion?: string | null,
          ownerId?: string | null,
          ownerEmail?: string | null,
          ratebatchID: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      companyID: string,
      carrierID: string,
      status?: Status | null,
      tsMode?: string | null,
      scac?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserInvitationQueryVariables = {
  id: string,
};

export type GetUserInvitationQuery = {
  getUserInvitation?:  {
    __typename: "UserInvitation",
    id: string,
    inviteeEmail?: string | null,
    inviteeId?: string | null,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserInvitationsQueryVariables = {
  filter?: ModelUserInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserInvitationsQuery = {
  listUserInvitations?:  {
    __typename: "ModelUserInvitationConnection",
    items:  Array< {
      __typename: "UserInvitation",
      id: string,
      inviteeEmail?: string | null,
      inviteeId?: string | null,
      companyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserInvitationsByCompanyIDQueryVariables = {
  companyID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserInvitationsByCompanyIDQuery = {
  userInvitationsByCompanyID?:  {
    __typename: "ModelUserInvitationConnection",
    items:  Array< {
      __typename: "UserInvitation",
      id: string,
      inviteeEmail?: string | null,
      inviteeId?: string | null,
      companyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBillingHistoryQueryVariables = {
  id: string,
};

export type GetBillingHistoryQuery = {
  getBillingHistory?:  {
    __typename: "BillingHistory",
    id: string,
    amount?: number | null,
    status?: SubscriptionStatus | null,
    billingDate?: string | null,
    companyID: string,
    startPeriod?: number | null,
    endPeriod?: number | null,
    PaymentResult?:  {
      __typename: "ModelPaymentResultConnection",
      items:  Array< {
        __typename: "PaymentResult",
        id: string,
        billinghistoryID: string,
        Invoice?: string | null,
        date?: number | null,
        transactionStatus?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListBillingHistoriesQueryVariables = {
  filter?: ModelBillingHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBillingHistoriesQuery = {
  listBillingHistories?:  {
    __typename: "ModelBillingHistoryConnection",
    items:  Array< {
      __typename: "BillingHistory",
      id: string,
      amount?: number | null,
      status?: SubscriptionStatus | null,
      billingDate?: string | null,
      companyID: string,
      startPeriod?: number | null,
      endPeriod?: number | null,
      PaymentResult?:  {
        __typename: "ModelPaymentResultConnection",
        items:  Array< {
          __typename: "PaymentResult",
          id: string,
          billinghistoryID: string,
          Invoice?: string | null,
          date?: number | null,
          transactionStatus?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BillingHistoriesByCompanyIDQueryVariables = {
  companyID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBillingHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BillingHistoriesByCompanyIDQuery = {
  billingHistoriesByCompanyID?:  {
    __typename: "ModelBillingHistoryConnection",
    items:  Array< {
      __typename: "BillingHistory",
      id: string,
      amount?: number | null,
      status?: SubscriptionStatus | null,
      billingDate?: string | null,
      companyID: string,
      startPeriod?: number | null,
      endPeriod?: number | null,
      PaymentResult?:  {
        __typename: "ModelPaymentResultConnection",
        items:  Array< {
          __typename: "PaymentResult",
          id: string,
          billinghistoryID: string,
          Invoice?: string | null,
          date?: number | null,
          transactionStatus?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCarrierCompaniesQueryVariables = {
  id: string,
};

export type GetCarrierCompaniesQuery = {
  getCarrierCompanies?:  {
    __typename: "CarrierCompanies",
    id: string,
    Name?: string | null,
    companyID?: string | null,
    carrierID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCarrierCompaniesQueryVariables = {
  filter?: ModelCarrierCompaniesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCarrierCompaniesQuery = {
  listCarrierCompanies?:  {
    __typename: "ModelCarrierCompaniesConnection",
    items:  Array< {
      __typename: "CarrierCompanies",
      id: string,
      Name?: string | null,
      companyID?: string | null,
      carrierID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CarrierCompaniesByCarrierIDQueryVariables = {
  carrierID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCarrierCompaniesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CarrierCompaniesByCarrierIDQuery = {
  carrierCompaniesByCarrierID?:  {
    __typename: "ModelCarrierCompaniesConnection",
    items:  Array< {
      __typename: "CarrierCompanies",
      id: string,
      Name?: string | null,
      companyID?: string | null,
      carrierID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRatesQueryVariables = {
  id: string,
};

export type GetRatesQuery = {
  getRates?:  {
    __typename: "Rates",
    id: string,
    tsMode?: RateType | null,
    data?: string | null,
    scac?: string | null,
    serviceName?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    originCity?: string | null,
    originCountry?: string | null,
    originProvince?: string | null,
    destinationCountry?: string | null,
    destinationCity?: string | null,
    destinationProvince?: string | null,
    originRegion?: string | null,
    destinationRegion?: string | null,
    ownerId?: string | null,
    ownerEmail?: string | null,
    ratebatchID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListRatesQueryVariables = {
  filter?: ModelRatesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRatesQuery = {
  listRates?:  {
    __typename: "ModelRatesConnection",
    items:  Array< {
      __typename: "Rates",
      id: string,
      tsMode?: RateType | null,
      data?: string | null,
      scac?: string | null,
      serviceName?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      originCity?: string | null,
      originCountry?: string | null,
      originProvince?: string | null,
      destinationCountry?: string | null,
      destinationCity?: string | null,
      destinationProvince?: string | null,
      originRegion?: string | null,
      destinationRegion?: string | null,
      ownerId?: string | null,
      ownerEmail?: string | null,
      ratebatchID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RatesByRatebatchIDQueryVariables = {
  ratebatchID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRatesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RatesByRatebatchIDQuery = {
  ratesByRatebatchID?:  {
    __typename: "ModelRatesConnection",
    items:  Array< {
      __typename: "Rates",
      id: string,
      tsMode?: RateType | null,
      data?: string | null,
      scac?: string | null,
      serviceName?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      originCity?: string | null,
      originCountry?: string | null,
      originProvince?: string | null,
      destinationCountry?: string | null,
      destinationCity?: string | null,
      destinationProvince?: string | null,
      originRegion?: string | null,
      destinationRegion?: string | null,
      ownerId?: string | null,
      ownerEmail?: string | null,
      ratebatchID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDepartmentsQueryVariables = {
  id: string,
};

export type GetDepartmentsQuery = {
  getDepartments?:  {
    __typename: "Departments",
    id: string,
    DepartmentName: string,
    DepartmentSCAC: string,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListDepartmentsQueryVariables = {
  filter?: ModelDepartmentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDepartmentsQuery = {
  listDepartments?:  {
    __typename: "ModelDepartmentsConnection",
    items:  Array< {
      __typename: "Departments",
      id: string,
      DepartmentName: string,
      DepartmentSCAC: string,
      companyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DepartmentsByCompanyIDQueryVariables = {
  companyID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDepartmentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DepartmentsByCompanyIDQuery = {
  departmentsByCompanyID?:  {
    __typename: "ModelDepartmentsConnection",
    items:  Array< {
      __typename: "Departments",
      id: string,
      DepartmentName: string,
      DepartmentSCAC: string,
      companyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCarrierQueryVariables = {
  id: string,
};

export type GetCarrierQuery = {
  getCarrier?:  {
    __typename: "Carrier",
    id: string,
    Email?: string | null,
    Phone?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    ServiceName?: string | null,
    SCAC?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    CarrierCompanies?:  {
      __typename: "ModelCarrierCompaniesConnection",
      items:  Array< {
        __typename: "CarrierCompanies",
        id: string,
        Name?: string | null,
        companyID?: string | null,
        carrierID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCarriersQueryVariables = {
  filter?: ModelCarrierFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCarriersQuery = {
  listCarriers?:  {
    __typename: "ModelCarrierConnection",
    items:  Array< {
      __typename: "Carrier",
      id: string,
      Email?: string | null,
      Phone?: string | null,
      Address?:  {
        __typename: "Address",
        Address?: string | null,
        City?: string | null,
        Country?: string | null,
        Zipcode?: string | null,
        State?: string | null,
      } | null,
      ServiceName?: string | null,
      SCAC?: string | null,
      CarrierInvitations?:  {
        __typename: "ModelCarrierInvitationConnection",
        items:  Array< {
          __typename: "CarrierInvitation",
          id: string,
          InviterId?: string | null,
          inviteeEmail?: string | null,
          status?: Status | null,
          inviteeId?: string | null,
          CompanyName?: string | null,
          companyID: string,
          carrierID: string,
          InviterEmail?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      CarrierCompanies?:  {
        __typename: "ModelCarrierCompaniesConnection",
        items:  Array< {
          __typename: "CarrierCompanies",
          id: string,
          Name?: string | null,
          companyID?: string | null,
          carrierID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      RateBatches?:  {
        __typename: "ModelRateBatchConnection",
        items:  Array< {
          __typename: "RateBatch",
          id: string,
          Rates?:  {
            __typename: "ModelRatesConnection",
            items:  Array< {
              __typename: "Rates",
              id: string,
              tsMode?: RateType | null,
              data?: string | null,
              scac?: string | null,
              serviceName?: string | null,
              startDate?: string | null,
              endDate?: string | null,
              originCity?: string | null,
              originCountry?: string | null,
              originProvince?: string | null,
              destinationCountry?: string | null,
              destinationCity?: string | null,
              destinationProvince?: string | null,
              originRegion?: string | null,
              destinationRegion?: string | null,
              ownerId?: string | null,
              ownerEmail?: string | null,
              ratebatchID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          companyID: string,
          carrierID: string,
          status?: Status | null,
          tsMode?: string | null,
          scac?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCarrierInvitationQueryVariables = {
  id: string,
};

export type GetCarrierInvitationQuery = {
  getCarrierInvitation?:  {
    __typename: "CarrierInvitation",
    id: string,
    InviterId?: string | null,
    inviteeEmail?: string | null,
    status?: Status | null,
    inviteeId?: string | null,
    CompanyName?: string | null,
    companyID: string,
    carrierID: string,
    InviterEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCarrierInvitationsQueryVariables = {
  filter?: ModelCarrierInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCarrierInvitationsQuery = {
  listCarrierInvitations?:  {
    __typename: "ModelCarrierInvitationConnection",
    items:  Array< {
      __typename: "CarrierInvitation",
      id: string,
      InviterId?: string | null,
      inviteeEmail?: string | null,
      status?: Status | null,
      inviteeId?: string | null,
      CompanyName?: string | null,
      companyID: string,
      carrierID: string,
      InviterEmail?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CarrierInvitationsByCompanyIDQueryVariables = {
  companyID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCarrierInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CarrierInvitationsByCompanyIDQuery = {
  carrierInvitationsByCompanyID?:  {
    __typename: "ModelCarrierInvitationConnection",
    items:  Array< {
      __typename: "CarrierInvitation",
      id: string,
      InviterId?: string | null,
      inviteeEmail?: string | null,
      status?: Status | null,
      inviteeId?: string | null,
      CompanyName?: string | null,
      companyID: string,
      carrierID: string,
      InviterEmail?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CarrierInvitationsByCarrierIDQueryVariables = {
  carrierID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCarrierInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CarrierInvitationsByCarrierIDQuery = {
  carrierInvitationsByCarrierID?:  {
    __typename: "ModelCarrierInvitationConnection",
    items:  Array< {
      __typename: "CarrierInvitation",
      id: string,
      InviterId?: string | null,
      inviteeEmail?: string | null,
      status?: Status | null,
      inviteeId?: string | null,
      CompanyName?: string | null,
      companyID: string,
      carrierID: string,
      InviterEmail?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyQueryVariables = {
  id: string,
};

export type GetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    id: string,
    Name?: string | null,
    PhoneNumber?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    Domain?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    Departments?:  {
      __typename: "ModelDepartmentsConnection",
      items:  Array< {
        __typename: "Departments",
        id: string,
        DepartmentName: string,
        DepartmentSCAC: string,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionStatus?: SubscriptionStatus | null,
    SubscriptionEndDate?: number | null,
    BillingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      items:  Array< {
        __typename: "BillingHistory",
        id: string,
        amount?: number | null,
        status?: SubscriptionStatus | null,
        billingDate?: string | null,
        companyID: string,
        startPeriod?: number | null,
        endPeriod?: number | null,
        PaymentResult?:  {
          __typename: "ModelPaymentResultConnection",
          items:  Array< {
            __typename: "PaymentResult",
            id: string,
            billinghistoryID: string,
            Invoice?: string | null,
            date?: number | null,
            transactionStatus?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    UserInvitations?:  {
      __typename: "ModelUserInvitationConnection",
      items:  Array< {
        __typename: "UserInvitation",
        id: string,
        inviteeEmail?: string | null,
        inviteeId?: string | null,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MaxSourcingUsers?: number | null,
    MaxCarrierUsers?: number | null,
    CurrentSourcingUsers?: number | null,
    CurrentCarrierUsers?: number | null,
    SubscriptionId?: string | null,
    Email?: string | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionNextBillCost?: number | null,
    PaymentNextRetryDate?: number | null,
    CustomerId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCompaniesQueryVariables = {
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompaniesQuery = {
  listCompanies?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      Name?: string | null,
      PhoneNumber?: string | null,
      Address?:  {
        __typename: "Address",
        Address?: string | null,
        City?: string | null,
        Country?: string | null,
        Zipcode?: string | null,
        State?: string | null,
      } | null,
      Domain?: string | null,
      CarrierInvitations?:  {
        __typename: "ModelCarrierInvitationConnection",
        items:  Array< {
          __typename: "CarrierInvitation",
          id: string,
          InviterId?: string | null,
          inviteeEmail?: string | null,
          status?: Status | null,
          inviteeId?: string | null,
          CompanyName?: string | null,
          companyID: string,
          carrierID: string,
          InviterEmail?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      Departments?:  {
        __typename: "ModelDepartmentsConnection",
        items:  Array< {
          __typename: "Departments",
          id: string,
          DepartmentName: string,
          DepartmentSCAC: string,
          companyID: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      SubscriptionStatus?: SubscriptionStatus | null,
      SubscriptionEndDate?: number | null,
      BillingHistories?:  {
        __typename: "ModelBillingHistoryConnection",
        items:  Array< {
          __typename: "BillingHistory",
          id: string,
          amount?: number | null,
          status?: SubscriptionStatus | null,
          billingDate?: string | null,
          companyID: string,
          startPeriod?: number | null,
          endPeriod?: number | null,
          PaymentResult?:  {
            __typename: "ModelPaymentResultConnection",
            items:  Array< {
              __typename: "PaymentResult",
              id: string,
              billinghistoryID: string,
              Invoice?: string | null,
              date?: number | null,
              transactionStatus?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      UserInvitations?:  {
        __typename: "ModelUserInvitationConnection",
        items:  Array< {
          __typename: "UserInvitation",
          id: string,
          inviteeEmail?: string | null,
          inviteeId?: string | null,
          companyID: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      MaxSourcingUsers?: number | null,
      MaxCarrierUsers?: number | null,
      CurrentSourcingUsers?: number | null,
      CurrentCarrierUsers?: number | null,
      SubscriptionId?: string | null,
      Email?: string | null,
      RateBatches?:  {
        __typename: "ModelRateBatchConnection",
        items:  Array< {
          __typename: "RateBatch",
          id: string,
          Rates?:  {
            __typename: "ModelRatesConnection",
            items:  Array< {
              __typename: "Rates",
              id: string,
              tsMode?: RateType | null,
              data?: string | null,
              scac?: string | null,
              serviceName?: string | null,
              startDate?: string | null,
              endDate?: string | null,
              originCity?: string | null,
              originCountry?: string | null,
              originProvince?: string | null,
              destinationCountry?: string | null,
              destinationCity?: string | null,
              destinationProvince?: string | null,
              originRegion?: string | null,
              destinationRegion?: string | null,
              ownerId?: string | null,
              ownerEmail?: string | null,
              ratebatchID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          companyID: string,
          carrierID: string,
          status?: Status | null,
          tsMode?: string | null,
          scac?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      SubscriptionNextBillCost?: number | null,
      PaymentNextRetryDate?: number | null,
      CustomerId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreatePaymentResultSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentResultFilterInput | null,
  owner?: string | null,
};

export type OnCreatePaymentResultSubscription = {
  onCreatePaymentResult?:  {
    __typename: "PaymentResult",
    id: string,
    billinghistoryID: string,
    Invoice?: string | null,
    date?: number | null,
    transactionStatus?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePaymentResultSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentResultFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePaymentResultSubscription = {
  onUpdatePaymentResult?:  {
    __typename: "PaymentResult",
    id: string,
    billinghistoryID: string,
    Invoice?: string | null,
    date?: number | null,
    transactionStatus?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePaymentResultSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentResultFilterInput | null,
  owner?: string | null,
};

export type OnDeletePaymentResultSubscription = {
  onDeletePaymentResult?:  {
    __typename: "PaymentResult",
    id: string,
    billinghistoryID: string,
    Invoice?: string | null,
    date?: number | null,
    transactionStatus?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateRateBatchSubscriptionVariables = {
  filter?: ModelSubscriptionRateBatchFilterInput | null,
  owner?: string | null,
};

export type OnCreateRateBatchSubscription = {
  onCreateRateBatch?:  {
    __typename: "RateBatch",
    id: string,
    Rates?:  {
      __typename: "ModelRatesConnection",
      items:  Array< {
        __typename: "Rates",
        id: string,
        tsMode?: RateType | null,
        data?: string | null,
        scac?: string | null,
        serviceName?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        originCity?: string | null,
        originCountry?: string | null,
        originProvince?: string | null,
        destinationCountry?: string | null,
        destinationCity?: string | null,
        destinationProvince?: string | null,
        originRegion?: string | null,
        destinationRegion?: string | null,
        ownerId?: string | null,
        ownerEmail?: string | null,
        ratebatchID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    companyID: string,
    carrierID: string,
    status?: Status | null,
    tsMode?: string | null,
    scac?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateRateBatchSubscriptionVariables = {
  filter?: ModelSubscriptionRateBatchFilterInput | null,
  owner?: string | null,
};

export type OnUpdateRateBatchSubscription = {
  onUpdateRateBatch?:  {
    __typename: "RateBatch",
    id: string,
    Rates?:  {
      __typename: "ModelRatesConnection",
      items:  Array< {
        __typename: "Rates",
        id: string,
        tsMode?: RateType | null,
        data?: string | null,
        scac?: string | null,
        serviceName?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        originCity?: string | null,
        originCountry?: string | null,
        originProvince?: string | null,
        destinationCountry?: string | null,
        destinationCity?: string | null,
        destinationProvince?: string | null,
        originRegion?: string | null,
        destinationRegion?: string | null,
        ownerId?: string | null,
        ownerEmail?: string | null,
        ratebatchID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    companyID: string,
    carrierID: string,
    status?: Status | null,
    tsMode?: string | null,
    scac?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteRateBatchSubscriptionVariables = {
  filter?: ModelSubscriptionRateBatchFilterInput | null,
  owner?: string | null,
};

export type OnDeleteRateBatchSubscription = {
  onDeleteRateBatch?:  {
    __typename: "RateBatch",
    id: string,
    Rates?:  {
      __typename: "ModelRatesConnection",
      items:  Array< {
        __typename: "Rates",
        id: string,
        tsMode?: RateType | null,
        data?: string | null,
        scac?: string | null,
        serviceName?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        originCity?: string | null,
        originCountry?: string | null,
        originProvince?: string | null,
        destinationCountry?: string | null,
        destinationCity?: string | null,
        destinationProvince?: string | null,
        originRegion?: string | null,
        destinationRegion?: string | null,
        ownerId?: string | null,
        ownerEmail?: string | null,
        ratebatchID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    companyID: string,
    carrierID: string,
    status?: Status | null,
    tsMode?: string | null,
    scac?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUserInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionUserInvitationFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserInvitationSubscription = {
  onCreateUserInvitation?:  {
    __typename: "UserInvitation",
    id: string,
    inviteeEmail?: string | null,
    inviteeId?: string | null,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionUserInvitationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserInvitationSubscription = {
  onUpdateUserInvitation?:  {
    __typename: "UserInvitation",
    id: string,
    inviteeEmail?: string | null,
    inviteeId?: string | null,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionUserInvitationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserInvitationSubscription = {
  onDeleteUserInvitation?:  {
    __typename: "UserInvitation",
    id: string,
    inviteeEmail?: string | null,
    inviteeId?: string | null,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateBillingHistorySubscriptionVariables = {
  filter?: ModelSubscriptionBillingHistoryFilterInput | null,
  owner?: string | null,
};

export type OnCreateBillingHistorySubscription = {
  onCreateBillingHistory?:  {
    __typename: "BillingHistory",
    id: string,
    amount?: number | null,
    status?: SubscriptionStatus | null,
    billingDate?: string | null,
    companyID: string,
    startPeriod?: number | null,
    endPeriod?: number | null,
    PaymentResult?:  {
      __typename: "ModelPaymentResultConnection",
      items:  Array< {
        __typename: "PaymentResult",
        id: string,
        billinghistoryID: string,
        Invoice?: string | null,
        date?: number | null,
        transactionStatus?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateBillingHistorySubscriptionVariables = {
  filter?: ModelSubscriptionBillingHistoryFilterInput | null,
  owner?: string | null,
};

export type OnUpdateBillingHistorySubscription = {
  onUpdateBillingHistory?:  {
    __typename: "BillingHistory",
    id: string,
    amount?: number | null,
    status?: SubscriptionStatus | null,
    billingDate?: string | null,
    companyID: string,
    startPeriod?: number | null,
    endPeriod?: number | null,
    PaymentResult?:  {
      __typename: "ModelPaymentResultConnection",
      items:  Array< {
        __typename: "PaymentResult",
        id: string,
        billinghistoryID: string,
        Invoice?: string | null,
        date?: number | null,
        transactionStatus?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteBillingHistorySubscriptionVariables = {
  filter?: ModelSubscriptionBillingHistoryFilterInput | null,
  owner?: string | null,
};

export type OnDeleteBillingHistorySubscription = {
  onDeleteBillingHistory?:  {
    __typename: "BillingHistory",
    id: string,
    amount?: number | null,
    status?: SubscriptionStatus | null,
    billingDate?: string | null,
    companyID: string,
    startPeriod?: number | null,
    endPeriod?: number | null,
    PaymentResult?:  {
      __typename: "ModelPaymentResultConnection",
      items:  Array< {
        __typename: "PaymentResult",
        id: string,
        billinghistoryID: string,
        Invoice?: string | null,
        date?: number | null,
        transactionStatus?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCarrierCompaniesSubscriptionVariables = {
  filter?: ModelSubscriptionCarrierCompaniesFilterInput | null,
};

export type OnCreateCarrierCompaniesSubscription = {
  onCreateCarrierCompanies?:  {
    __typename: "CarrierCompanies",
    id: string,
    Name?: string | null,
    companyID?: string | null,
    carrierID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCarrierCompaniesSubscriptionVariables = {
  filter?: ModelSubscriptionCarrierCompaniesFilterInput | null,
};

export type OnUpdateCarrierCompaniesSubscription = {
  onUpdateCarrierCompanies?:  {
    __typename: "CarrierCompanies",
    id: string,
    Name?: string | null,
    companyID?: string | null,
    carrierID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCarrierCompaniesSubscriptionVariables = {
  filter?: ModelSubscriptionCarrierCompaniesFilterInput | null,
};

export type OnDeleteCarrierCompaniesSubscription = {
  onDeleteCarrierCompanies?:  {
    __typename: "CarrierCompanies",
    id: string,
    Name?: string | null,
    companyID?: string | null,
    carrierID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRatesSubscriptionVariables = {
  filter?: ModelSubscriptionRatesFilterInput | null,
  owner?: string | null,
};

export type OnCreateRatesSubscription = {
  onCreateRates?:  {
    __typename: "Rates",
    id: string,
    tsMode?: RateType | null,
    data?: string | null,
    scac?: string | null,
    serviceName?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    originCity?: string | null,
    originCountry?: string | null,
    originProvince?: string | null,
    destinationCountry?: string | null,
    destinationCity?: string | null,
    destinationProvince?: string | null,
    originRegion?: string | null,
    destinationRegion?: string | null,
    ownerId?: string | null,
    ownerEmail?: string | null,
    ratebatchID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateRatesSubscriptionVariables = {
  filter?: ModelSubscriptionRatesFilterInput | null,
  owner?: string | null,
};

export type OnUpdateRatesSubscription = {
  onUpdateRates?:  {
    __typename: "Rates",
    id: string,
    tsMode?: RateType | null,
    data?: string | null,
    scac?: string | null,
    serviceName?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    originCity?: string | null,
    originCountry?: string | null,
    originProvince?: string | null,
    destinationCountry?: string | null,
    destinationCity?: string | null,
    destinationProvince?: string | null,
    originRegion?: string | null,
    destinationRegion?: string | null,
    ownerId?: string | null,
    ownerEmail?: string | null,
    ratebatchID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteRatesSubscriptionVariables = {
  filter?: ModelSubscriptionRatesFilterInput | null,
  owner?: string | null,
};

export type OnDeleteRatesSubscription = {
  onDeleteRates?:  {
    __typename: "Rates",
    id: string,
    tsMode?: RateType | null,
    data?: string | null,
    scac?: string | null,
    serviceName?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    originCity?: string | null,
    originCountry?: string | null,
    originProvince?: string | null,
    destinationCountry?: string | null,
    destinationCity?: string | null,
    destinationProvince?: string | null,
    originRegion?: string | null,
    destinationRegion?: string | null,
    ownerId?: string | null,
    ownerEmail?: string | null,
    ratebatchID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateDepartmentsSubscriptionVariables = {
  filter?: ModelSubscriptionDepartmentsFilterInput | null,
  owner?: string | null,
};

export type OnCreateDepartmentsSubscription = {
  onCreateDepartments?:  {
    __typename: "Departments",
    id: string,
    DepartmentName: string,
    DepartmentSCAC: string,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateDepartmentsSubscriptionVariables = {
  filter?: ModelSubscriptionDepartmentsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateDepartmentsSubscription = {
  onUpdateDepartments?:  {
    __typename: "Departments",
    id: string,
    DepartmentName: string,
    DepartmentSCAC: string,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteDepartmentsSubscriptionVariables = {
  filter?: ModelSubscriptionDepartmentsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteDepartmentsSubscription = {
  onDeleteDepartments?:  {
    __typename: "Departments",
    id: string,
    DepartmentName: string,
    DepartmentSCAC: string,
    companyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCarrierSubscriptionVariables = {
  filter?: ModelSubscriptionCarrierFilterInput | null,
  owner?: string | null,
};

export type OnCreateCarrierSubscription = {
  onCreateCarrier?:  {
    __typename: "Carrier",
    id: string,
    Email?: string | null,
    Phone?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    ServiceName?: string | null,
    SCAC?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    CarrierCompanies?:  {
      __typename: "ModelCarrierCompaniesConnection",
      items:  Array< {
        __typename: "CarrierCompanies",
        id: string,
        Name?: string | null,
        companyID?: string | null,
        carrierID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCarrierSubscriptionVariables = {
  filter?: ModelSubscriptionCarrierFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCarrierSubscription = {
  onUpdateCarrier?:  {
    __typename: "Carrier",
    id: string,
    Email?: string | null,
    Phone?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    ServiceName?: string | null,
    SCAC?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    CarrierCompanies?:  {
      __typename: "ModelCarrierCompaniesConnection",
      items:  Array< {
        __typename: "CarrierCompanies",
        id: string,
        Name?: string | null,
        companyID?: string | null,
        carrierID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCarrierSubscriptionVariables = {
  filter?: ModelSubscriptionCarrierFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCarrierSubscription = {
  onDeleteCarrier?:  {
    __typename: "Carrier",
    id: string,
    Email?: string | null,
    Phone?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    ServiceName?: string | null,
    SCAC?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    CarrierCompanies?:  {
      __typename: "ModelCarrierCompaniesConnection",
      items:  Array< {
        __typename: "CarrierCompanies",
        id: string,
        Name?: string | null,
        companyID?: string | null,
        carrierID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCarrierInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionCarrierInvitationFilterInput | null,
  owner?: string | null,
};

export type OnCreateCarrierInvitationSubscription = {
  onCreateCarrierInvitation?:  {
    __typename: "CarrierInvitation",
    id: string,
    InviterId?: string | null,
    inviteeEmail?: string | null,
    status?: Status | null,
    inviteeId?: string | null,
    CompanyName?: string | null,
    companyID: string,
    carrierID: string,
    InviterEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCarrierInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionCarrierInvitationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCarrierInvitationSubscription = {
  onUpdateCarrierInvitation?:  {
    __typename: "CarrierInvitation",
    id: string,
    InviterId?: string | null,
    inviteeEmail?: string | null,
    status?: Status | null,
    inviteeId?: string | null,
    CompanyName?: string | null,
    companyID: string,
    carrierID: string,
    InviterEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCarrierInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionCarrierInvitationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCarrierInvitationSubscription = {
  onDeleteCarrierInvitation?:  {
    __typename: "CarrierInvitation",
    id: string,
    InviterId?: string | null,
    inviteeEmail?: string | null,
    status?: Status | null,
    inviteeId?: string | null,
    CompanyName?: string | null,
    companyID: string,
    carrierID: string,
    InviterEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
  owner?: string | null,
};

export type OnCreateCompanySubscription = {
  onCreateCompany?:  {
    __typename: "Company",
    id: string,
    Name?: string | null,
    PhoneNumber?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    Domain?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    Departments?:  {
      __typename: "ModelDepartmentsConnection",
      items:  Array< {
        __typename: "Departments",
        id: string,
        DepartmentName: string,
        DepartmentSCAC: string,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionStatus?: SubscriptionStatus | null,
    SubscriptionEndDate?: number | null,
    BillingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      items:  Array< {
        __typename: "BillingHistory",
        id: string,
        amount?: number | null,
        status?: SubscriptionStatus | null,
        billingDate?: string | null,
        companyID: string,
        startPeriod?: number | null,
        endPeriod?: number | null,
        PaymentResult?:  {
          __typename: "ModelPaymentResultConnection",
          items:  Array< {
            __typename: "PaymentResult",
            id: string,
            billinghistoryID: string,
            Invoice?: string | null,
            date?: number | null,
            transactionStatus?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    UserInvitations?:  {
      __typename: "ModelUserInvitationConnection",
      items:  Array< {
        __typename: "UserInvitation",
        id: string,
        inviteeEmail?: string | null,
        inviteeId?: string | null,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MaxSourcingUsers?: number | null,
    MaxCarrierUsers?: number | null,
    CurrentSourcingUsers?: number | null,
    CurrentCarrierUsers?: number | null,
    SubscriptionId?: string | null,
    Email?: string | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionNextBillCost?: number | null,
    PaymentNextRetryDate?: number | null,
    CustomerId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany?:  {
    __typename: "Company",
    id: string,
    Name?: string | null,
    PhoneNumber?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    Domain?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    Departments?:  {
      __typename: "ModelDepartmentsConnection",
      items:  Array< {
        __typename: "Departments",
        id: string,
        DepartmentName: string,
        DepartmentSCAC: string,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionStatus?: SubscriptionStatus | null,
    SubscriptionEndDate?: number | null,
    BillingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      items:  Array< {
        __typename: "BillingHistory",
        id: string,
        amount?: number | null,
        status?: SubscriptionStatus | null,
        billingDate?: string | null,
        companyID: string,
        startPeriod?: number | null,
        endPeriod?: number | null,
        PaymentResult?:  {
          __typename: "ModelPaymentResultConnection",
          items:  Array< {
            __typename: "PaymentResult",
            id: string,
            billinghistoryID: string,
            Invoice?: string | null,
            date?: number | null,
            transactionStatus?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    UserInvitations?:  {
      __typename: "ModelUserInvitationConnection",
      items:  Array< {
        __typename: "UserInvitation",
        id: string,
        inviteeEmail?: string | null,
        inviteeId?: string | null,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MaxSourcingUsers?: number | null,
    MaxCarrierUsers?: number | null,
    CurrentSourcingUsers?: number | null,
    CurrentCarrierUsers?: number | null,
    SubscriptionId?: string | null,
    Email?: string | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionNextBillCost?: number | null,
    PaymentNextRetryDate?: number | null,
    CustomerId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany?:  {
    __typename: "Company",
    id: string,
    Name?: string | null,
    PhoneNumber?: string | null,
    Address?:  {
      __typename: "Address",
      Address?: string | null,
      City?: string | null,
      Country?: string | null,
      Zipcode?: string | null,
      State?: string | null,
    } | null,
    Domain?: string | null,
    CarrierInvitations?:  {
      __typename: "ModelCarrierInvitationConnection",
      items:  Array< {
        __typename: "CarrierInvitation",
        id: string,
        InviterId?: string | null,
        inviteeEmail?: string | null,
        status?: Status | null,
        inviteeId?: string | null,
        CompanyName?: string | null,
        companyID: string,
        carrierID: string,
        InviterEmail?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    Departments?:  {
      __typename: "ModelDepartmentsConnection",
      items:  Array< {
        __typename: "Departments",
        id: string,
        DepartmentName: string,
        DepartmentSCAC: string,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionStatus?: SubscriptionStatus | null,
    SubscriptionEndDate?: number | null,
    BillingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      items:  Array< {
        __typename: "BillingHistory",
        id: string,
        amount?: number | null,
        status?: SubscriptionStatus | null,
        billingDate?: string | null,
        companyID: string,
        startPeriod?: number | null,
        endPeriod?: number | null,
        PaymentResult?:  {
          __typename: "ModelPaymentResultConnection",
          items:  Array< {
            __typename: "PaymentResult",
            id: string,
            billinghistoryID: string,
            Invoice?: string | null,
            date?: number | null,
            transactionStatus?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    UserInvitations?:  {
      __typename: "ModelUserInvitationConnection",
      items:  Array< {
        __typename: "UserInvitation",
        id: string,
        inviteeEmail?: string | null,
        inviteeId?: string | null,
        companyID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MaxSourcingUsers?: number | null,
    MaxCarrierUsers?: number | null,
    CurrentSourcingUsers?: number | null,
    CurrentCarrierUsers?: number | null,
    SubscriptionId?: string | null,
    Email?: string | null,
    RateBatches?:  {
      __typename: "ModelRateBatchConnection",
      items:  Array< {
        __typename: "RateBatch",
        id: string,
        Rates?:  {
          __typename: "ModelRatesConnection",
          items:  Array< {
            __typename: "Rates",
            id: string,
            tsMode?: RateType | null,
            data?: string | null,
            scac?: string | null,
            serviceName?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            originCity?: string | null,
            originCountry?: string | null,
            originProvince?: string | null,
            destinationCountry?: string | null,
            destinationCity?: string | null,
            destinationProvince?: string | null,
            originRegion?: string | null,
            destinationRegion?: string | null,
            ownerId?: string | null,
            ownerEmail?: string | null,
            ratebatchID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        companyID: string,
        carrierID: string,
        status?: Status | null,
        tsMode?: string | null,
        scac?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SubscriptionNextBillCost?: number | null,
    PaymentNextRetryDate?: number | null,
    CustomerId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};
