import React from 'react'
import "./style.css";
import { LoadingFull } from '../../../Components/PreLoader/Loading';

export const SubmittingLoading = () => {
  return (
    <div className='submitting-loading-box'>
      <LoadingFull text="Creating Profile"/>
    </div>
  )
}
