import React from 'react'
import Button from '../../../../Components/Button/Button'
import { Reveal } from '../../../../Components/Revel/Revel'
import './style.css';

export const WelcomeRG = (prop: any) => {
  return (
    <div className='welcome-box-setup-account'>
      <Reveal>
        <h2>🎉 Welcome to RateGenie! 🎉</h2>
      </Reveal>
      <Reveal delay={.200}>
        <p>With RateGenie, you’re using the world's most innovative and efficient platform for managing rates on OTM. Say goodbye to the hassle and hello to seamless, streamlined operations! 🚀</p>
      </Reveal>
      <Reveal delay={.400}>
        <Button loading={false} label='Next' onClick={() => prop.next()}></Button>
      </Reveal>
    </div>
  )
}

export const WelcomeCarrier = (prop: any) => {
  return (
    <div className='welcome-box-setup-account'>
      <Reveal>
        <h2>🎉 Welcome to RateGenie! 🎉</h2>
      </Reveal>
      <Reveal delay={.200}>
        <p>With RateGenie, you’re using the world's most innovative and efficient platform for managing rates on OTM. Say goodbye to the hassle and hello to seamless, streamlined operations! 🚀</p>
      </Reveal>
      <Reveal delay={.400}>
        <Button loading={false} label='Next' onClick={() => prop.next()}></Button>
      </Reveal>
    </div>
  )
}
