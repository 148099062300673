import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./style.css";
import { AccountContext } from '../../../Context/AuthContext';
import logo from "../../../assets/img/logo.png";

export const Header = () => {
  const {isLoading, isAuthenticated, setIsLoggingOut} = useContext(AccountContext);
  const [authStorage, setAuthStorage] = useState<boolean>(false);

  useEffect(() => {
    const value = localStorage.getItem('userAuth');
    if(value != null) {
      setAuthStorage(true);
    }
  }, []);
  return (
    <header className='header'>
      <div className='content-sizing header-wrapper'>
        <div className='left-side-header'>
          <Link to="/"><img src={logo}  width={190}/></Link>
          <nav className='nav'>
            <ul>
              <li><Link to="/products">Product</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/resources">Resources</Link></li>
              <li><Link to="/pricing">Pricing</Link></li>
            </ul>
          </nav>
        </div>
        <div className='right-side-header'>
          {!authStorage ? <>
          <Link to="/auth/login" className='text-link'>Sign In</Link>
          <Link to="/auth/register" className='primary-button-link'>Get Started <i className="bi bi-chevron-right"></i></Link>
          </> : 
          <>
          <button className='button-link' onClick={() => setIsLoggingOut(true)}>Sign Out</button>
          <Link to="/dashboard" className='primary-button-link'>Go to dashboard<i className="bi bi-chevron-right"></i></Link></>}
        </div>
      </div>
    </header>
  )
}
