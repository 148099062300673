import React, { useContext } from 'react'
import { AccountContext } from '../../../../Context/AuthContext'
// import thumbnail from '../assets/img/1.png'

const Header = () => {
  const {getUser, isLoading, isAuthenticated} = useContext(AccountContext);

  return (
    <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
              <a className="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                <i className="ti ti-menu-2 ti-sm"></i>
              </a>
            </div>

            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
              {/* <!-- Search --> */}
              <div className="navbar-nav align-items-center">
                <div className="nav-item navbar-search-wrapper mb-0">
                  <a className="nav-item nav-link search-toggler d-flex align-items-center px-0" href="javascript:void(0);">
                    <i className="ti ti-search ti-md me-2"></i>
                    <span className="d-none d-md-inline-block text-muted">Carrier Portal</span>
                  </a>
                </div>
              </div>
              {/* <!-- /Search --> */}

              <ul className="navbar-nav flex-row align-items-center ms-auto">
                {/* <!-- Language --> */}
                {/* <!--/ Language --> */}

                {/* <!-- Style Switcher --> */}
                {/* <!-- / Style Switcher--> */}

                {/* <!-- Quick links  --> */}
                {/* <!-- Quick links --> */}

                {/* <!-- Notification --> */}
                {/* <!--/ Notification --> */}

                {/* <!-- User --> */}
                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                  <a className="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
                    <div className='right-side-header-dash'>
                      <div className="avatar avatar-online">
                        <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5nkIBtLrjh2hXCp62Sy9Ay8tzkNGW3TYwRA&s"} alt="" className="h-auto rounded-circle" />
                      </div>
                      <div className='dash-header-user-info'>
                        <h3>{isAuthenticated && getUser().attributes.name === "null" ? getUser().attributes.email : getUser().attributes.name}</h3>
                        <p>Role: CARRIER</p>
                      </div>
                    </div>
                    
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="pages-account-settings-account.html">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar avatar-online">
                              <img src="../../assets/img/avatars/1.png" alt="" className="h-auto rounded-circle" />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <span className="fw-medium d-block">John Doe</span>
                            <small className="text-muted">Admin</small>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <a className="dropdown-item" href="pages-profile-user.html">
                        <i className="ti ti-user-check me-2 ti-sm"></i>
                        <span className="align-middle">My Profile</span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="pages-account-settings-account.html">
                        <i className="ti ti-settings me-2 ti-sm"></i>
                        <span className="align-middle">Settings</span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="pages-account-settings-billing.html">
                        <span className="d-flex align-items-center align-middle">
                          <i className="flex-shrink-0 ti ti-credit-card me-2 ti-sm"></i>
                          <span className="flex-grow-1 align-middle">Billing</span>
                          <span className="flex-shrink-0 badge badge-center rounded-pill bg-label-danger w-px-20 h-px-20">2</span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <a className="dropdown-item" href="pages-faq.html">
                        <i className="ti ti-help me-2 ti-sm"></i>
                        <span className="align-middle">FAQ</span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="pages-pricing.html">
                        <i className="ti ti-currency-dollar me-2 ti-sm"></i>
                        <span className="align-middle">Pricing</span>
                      </a>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <a className="dropdown-item" href="auth-login-cover.html" target="_blank">
                        <i className="ti ti-logout me-2 ti-sm"></i>
                        <span className="align-middle">Log Out</span>
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <!--/ User --> */}
              </ul>
            </div>

            {/* <!-- Search Small Screens --> */}
            <div className="navbar-search-wrapper search-input-wrapper d-none">
              <input type="text" className="form-control search-input container-xxl border-0" placeholder="Search..." aria-label="Search..." />
              <i className="ti ti-x ti-sm search-toggler cursor-pointer"></i>
            </div>
    </nav>
  )
}

export default Header