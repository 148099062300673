import React, { useContext, useEffect, useRef, useState } from 'react'
import InputField from '../../../../../../Components/InputField/InputField'
import "./style.css";
import Button, { CheckBox } from '../../../../../../Components/Button/Button';
import { GLOBAL_RATE_TYPE } from '../../../../../../Interfaces/IRateForm';
import { ITransportationType } from '../../../../../../Interfaces/ITransportationType';
import { API } from 'aws-amplify';
import { createRateBatch, createRates } from '../../../../../../graphql/mutations';
import { Carrier, CreateRateBatchInput, CreateRatesInput, Rates, Status } from '../../../../../../API';
import { toast } from 'react-toastify';
import { AccountContext } from '../../../../../../Context/AuthContext';
import { useOutletContext } from 'react-router-dom';

interface IReviewData { 
  ratesData: GLOBAL_RATE_TYPE[] | null,
  setRatesData: (data: any) => void,
  selectedTransportationType: ITransportationType,
  selectedCompany: any,
  createModule: () => void,
  carrier: Carrier | null;
}


export const ReviewData = (prop: IReviewData) => {
  const {getUser} = useContext(AccountContext);
  const [formData, setFormData] = useState<GLOBAL_RATE_TYPE[]>([]);
  const [errors, setErrors] = useState<{[key: string]: string }>({});
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  
  useEffect(() => {
    if(prop.ratesData) 
      setFormData(prop.ratesData);
  }, [prop.ratesData]);


  const addRow = () => {
    setFormData((prev: any) => {
      return [...prev, prop.selectedTransportationType.object]
    });
  }

  const handleChange = (index: number, name: string, value: string) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = { ...updatedFormData[index], [name]: value };
    setFormData(updatedFormData);

    // Clear the error for the field if it is correctly filled
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`${index}-${name}`];
      return newErrors;
    });
  };

  const deleteRow = (index: number) => {
    if(index > 0 && formData !== null) {
      setFormData((prevFormData: any) => prevFormData.filter((_:any, i:any) => i !== index));
    }
  };

  const validateFormData = () => {
    const newErrors: { [key: string]: string } = {};

    formData.forEach((item: any, index: number) => {
      prop.selectedTransportationType.fields.forEach(field => {

        if(field.required && (item[field.objectKey] === "" ||item[field.objectKey] === undefined)) {
          newErrors[`${index}-${field.objectKey}`] = `${field.label} is required`;
        }
      });
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const scrollToFirstError = () => {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      const [rowIndex] = firstErrorField.split('-');
      if (refs.current[Number(rowIndex)]) {
        refs.current[Number(rowIndex)]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const onUpload = async () => {
    if(!validateFormData()) {
      scrollToFirstError();
      return;
    }

    try {
      const user = getUser();

      const startDateUTC = new Date(formData[0].startDate).toISOString();
      const endDateUTC = new Date(formData[0].endDate).toISOString();

      const newRateBatch: CreateRateBatchInput = {
        companyID: prop.selectedCompany.value,
        carrierID: prop.carrier?.id || "",
        status: Status.PENDING,
        tsMode: prop.selectedTransportationType.type,
        scac: prop.carrier?.SCAC,
        startDate: startDateUTC,
        endDate: endDateUTC,
      };

      const rateBatchResponse:any = await API.graphql({
        query: createRateBatch,
        variables: { input: newRateBatch },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const rateBatchId = rateBatchResponse.data.createRateBatch.id;

      const requests = formData.map(item => {
        const newRate: CreateRatesInput = {
          tsMode: prop.selectedTransportationType.type,
          scac: prop.carrier?.SCAC,
          serviceName: prop.carrier?.ServiceName,
          ownerId: user.username,
          ownerEmail: user.attributes.email,
          data: JSON.stringify(item),
          startDate: startDateUTC,
          endDate: endDateUTC,
          originCity: item.originCity,
          originCountry: item.originCountry,
          originProvince: item.originProvince,
          originRegion: item.originRegion,
          destinationRegion: item.destinationRegion,
          ratebatchID: rateBatchId,
          destinationCity: item.destinationCity,
          destinationCountry: item.destinationCountry,
          destinationProvince: item.destinationProvince,
        };
  
        return API.graphql({
          query: createRates,
          variables: { input: newRate },
          authMode: "AMAZON_COGNITO_USER_POOLS"
        });
      });

      await Promise.all(requests);

      const idToken = user.signInUserSession.idToken.jwtToken;
      const response = await fetch("https://vsqwc4gr9f.execute-api.us-east-1.amazonaws.com/Test/carrier/post-rate-creation", {
        mode: "cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": idToken,
        },
        body: JSON.stringify({
          companyID: prop.selectedCompany.value,
          tsMode: prop.selectedTransportationType.type,
          companyName: prop.selectedCompany.label,
          scac: prop.carrier?.SCAC,
        }),
      });

      const data = await response.json();
      if (data.code === 200) {
        prop.createModule();
        toast.success("Rates Uploaded");
      } else {
        throw new Error("Failed to upload rates");
      }
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("There was an error uploading the rates.");
    }
  }

  return (
    <div className='data-form-uploading-form'>
      <div className='header-review-data-form'>
        <div>
          <h2>Rate Data</h2>
          <p>Please review all the fields before submitting changes.</p>
        </div>
        <Button label='Clear All' loading={false}/>
      </div>
      {formData != null && formData.map((item, rowIndex) => {
        const obj:any = item;
        return (
        <div className='single-rate-item' key={rowIndex} ref={el => refs.current[rowIndex] = el}>
          <span className='overborder-label'>Row {rowIndex + 1}</span>
          {rowIndex > 0 && <button className='overborder-close-button btn btn-danger' onClick={() => deleteRow(rowIndex)}><i className="bi bi-trash"></i></button>}
          <div className='d-flex gap-4 main-top-field field-box'>
            {prop.selectedTransportationType.fields.map((field, index) => (
              
              <InputField
                key={index}
                type={field.type}
                label={field.label}
                name={field.objectKey}
                value={obj[field.objectKey]}
                error={errors[`${rowIndex}-${field.objectKey}`] || ''}
                onChange={(data) => handleChange(rowIndex, field.objectKey, data[field.objectKey])}
              />
            ))}
          </div>
          
        </div>
      )}) }

      <div className='button-add-row-container'>
        <button className='btn btn-light ' onClick={addRow}><i className="bi bi-arrow-bar-down"></i> Add Row</button>
      </div>

      <div className='action-upload-rates-ts-mode'>
        <CheckBox label="I've viewed all the data and all the data looks good" loading={false}/>
        <Button loading={false} label='Upload Rates' onClick={onUpload}></Button>
      </div>
    </div>
  )
}
