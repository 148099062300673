import React, { useEffect, useState } from 'react'
import InputField, { SelectField } from '../../../../Components/InputField/InputField'
import Button from '../../../../Components/Button/Button'
import departmentImage from "../../../../assets/img/department.png";
import { toast } from 'react-toastify';
import "./style.css";
import { Company, CreateDepartmentsInput, Departments } from '../../../../API';
import { API } from 'aws-amplify';
import { createDepartments } from '../../../../graphql/mutations';

interface CompanyOption {
  value: string;
  label: string;
}

interface IAddDepartment {
  setIsAddingDepartment: (value: boolean) => void,
  company: Company | null,
  addDepartment: (department: Departments) => void;
}

const AddDepartment = (prop: IAddDepartment) => {
  const [selectedOption, setSelectedOption] = useState<CompanyOption | null>(null);
  const [company, setCompany] = useState<CompanyOption>({value: "", label: ""});
  
  useEffect(() => {
    setCompany({
      value: prop.company?.Domain || "",
      label: prop.company?.Domain || "",
    })
  }, [prop.company]);

  const createDepartment = async (e: any) => {
    e.preventDefault();
    
    const data = new FormData(e.target);
    const departmentName:string = Object.fromEntries(data.entries()).departmentName.toString();
    const departmentId:string = Object.fromEntries(data.entries()).departmentId.toString(); 

    if(departmentName !== "" && departmentId !== "" && company.value !== "") {
     
      try {
        const newDepartment: CreateDepartmentsInput = {
          companyID: prop.company?.id || "",
          DepartmentName: departmentName,
          DepartmentSCAC: departmentId,
        };

        const response:any = await API.graphql({query: createDepartments, variables: {input: newDepartment}, authMode: "AMAZON_COGNITO_USER_POOLS" });
        if(response != undefined) {
          const department: Departments = response?.data.createDepartments;
          prop.addDepartment(department);
          prop.setIsAddingDepartment(false);
          toast.success(`${departmentName} added as a department successfully`);
          e.target.reset();
        }
      } catch(e) {
        console.log(e);
      }
    } else {
    }
  };

  return (
    <div className='request-form-submit'>
      <div className='header-request'>
        <i className="bi bi-boxes"></i>
        <div>
          <h2>Create new Department</h2>
          <p>Enhance collaboration with quick carrier invitations</p>
        </div>
      </div>

      <div className='info-not-department-adding'>
        <img src={departmentImage} width={200} alt="excel sheet"/>
        <p>The department you are adding will be used to generate a CSV file, which will then be uploaded to the OTM software.</p>
      </div>

      <form onSubmit={createDepartment}>
        <InputField label='Department Name' name="departmentName"/>
        <div className='department-field'>
          <SelectField label="Company / Domain" list={[company]} name="rateType" error={""} onChange={setSelectedOption} value={selectedOption} />
          <i className="bi bi-dash-lg"></i>
          <InputField label='Department / Sub-domain' name="departmentId"/>
        </div>
        <div className='action-list-button'>
          <div>
            <button type='button' className='button-link' onClick={() => prop.setIsAddingDepartment(false)}>Cancel</button>
            <Button loading={false} label='Create'/>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddDepartment