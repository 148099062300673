import React from "react";
import { TbMoneybag , TbUserCog , TbGitPullRequest , TbProgressCheck  } from "react-icons/tb";
import { BsBuildingLock } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";
import { Reveal } from "../../../Components/Revel/Revel";
import { Company } from "../../../API";
import { MdOutlineEventBusy } from "react-icons/md";
import { AiOutlineStop } from "react-icons/ai";


interface prop {
  business: Company | null;
}

const Card = (prop: prop) => {
  return (
    <>
        <div className="col-sm-6 col-lg-3 mb-4 child-div-full-width">
          <Reveal>
            <div className="card card-border-shadow-primary ">
              <div className="card-body">
                <div className="d-flex align-items-center mb-2 pb-1">
                  <div className="avatar me-2">
                    <span className="avatar-initial rounded bg-label-primary">
                    <IoNewspaperOutline size={24}/>

                    </span>
                  </div>
                  <h4 className="ms-1 mb-0">100</h4>
                </div>
                <p className="mb-1">Total Rates Sheets</p>
                <p className="mb-0">
                  <span className="fw-medium me-1">+18.2%</span>
                  <small className="text-muted">than last week</small>
                </p>
              </div>
            </div>
          </Reveal>
        </div>
        <div className="col-sm-6 col-lg-3 mb-4 child-div-full-width">
          <Reveal>
            <div className="card card-border-shadow-info">
              <div className="card-body">
                <div className="d-flex align-items-center mb-2 pb-1">
                  <div className="avatar me-2">
                    <span className="avatar-initial rounded bg-label-info">
                      <MdOutlineEventBusy size={24} />
                    </span>
                  </div>
                  <h4 className="ms-1 mb-0">0</h4>
                </div>
                <p className="mb-1">Expired Rate Sheets</p>
                <p className="mb-0">
                  <span className="fw-medium me-1">+18.2%</span>
                  <small className="text-muted">than last week</small>
                </p>
              </div>
            </div>
          </Reveal>
        </div>
        <div className="col-sm-6 col-lg-3 mb-4 child-div-full-width">
          <Reveal delay={.2}>
            <div className="card card-border-shadow-warning">
              <div className="card-body">
                <div className="d-flex align-items-center mb-2 pb-1">
                  <div className="avatar me-2">
                    <span className="avatar-initial rounded bg-label-warning">
                      <TbUserCog size={24}/>
                    </span>
                  </div>
                  <h4 className="ms-1 mb-0">{prop.business?.CurrentSourcingUsers}</h4>
                </div>
                <p className="mb-1">Active Sourcing Accounts</p>
                <p className="mb-0">
                  <span className="fw-medium me-1">{prop.business?.MaxSourcingUsers}</span>
                  <small className="text-muted">Max Allowed by Plan</small>
                </p>
              </div>
            </div>
            </Reveal>
        </div>
                
                <div className="col-sm-6 col-lg-3 mb-4 child-div-full-width">
                <Reveal delay={.4}>
                  <div className="card card-border-shadow-danger">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-2 pb-1">
                        <div className="avatar me-2">
                          <span className="avatar-initial rounded bg-label-danger">
                            <BsBuildingLock size={22} />
                          </span>
                        </div>
                        <h4 className="ms-1 mb-0">{prop.business?.CurrentCarrierUsers}</h4>
                      </div>
                      <p className="mb-1">Active Carriers Accounts</p>
                      <p className="mb-0">
                        <span className="fw-medium me-1">{prop.business?.MaxCarrierUsers}</span>
                        <small className="text-muted">Max Allowed by Plan</small>
                      </p>
                    </div>
                  </div>
                  </Reveal>
                </div>
    </>
  );
};

export default Card;
