import React, { useState, useCallback } from 'react';
import Button from '../../../../../../Components/Button/Button';
import "./style.css";
import { GLOBAL_RATE_TYPE } from '../../../../../../Interfaces/IRateForm';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import Moment from 'react-moment';
import { LoadingSpinner } from '../../../../../../Components/PreLoader/Loading';
import { CSVProcessor } from '../../../../../../CSVProcessor/CSVProcessor';
import { ITransportationType } from '../../../../../../Interfaces/ITransportationType';

interface IUploadRates {
  setRatesData: any,
  nextPage: any,
  selectedTransportationType: ITransportationType
}

export const UploadRates = (prop: IUploadRates) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileSize, setFileSize] = useState<number | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [fileJSONData, setFileJSONData] = useState<any[]>([]);
  const [error, setError] = useState<string>("");

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setError("");
    const file = acceptedFiles[0];
    setFile(file);
    setFileSize(file.size);
    const reader = new FileReader();

    reader.onprogress = (event) => {
      if (event.loaded && event.total) {
        setProgress(Math.round((event.loaded / event.total) * 100));
      }
    };

    reader.onload = (event) => {
      const data = new Uint8Array(event.target!.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      //console.log(jsonData);
      setFileJSONData(jsonData);
    };

    reader.readAsArrayBuffer(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.ms-excel': []
    },
  });

  const manualData = () => {
    prop.setRatesData([prop.selectedTransportationType.object]);
    prop.nextPage();
  }

  const nextStep = () => {
    setError("");

    if(file === null || fileJSONData.length === 0) {
      setError("Please upload data");
      return;
    }

    if(file !== null && fileJSONData.length > 0) {
      const processor = new CSVProcessor(prop.selectedTransportationType.headers);
      const validation = processor.validateCSV(fileJSONData as any[][]);
      if(validation.isValid && validation.data) {
        const processedData:GLOBAL_RATE_TYPE[] = processor.processData(validation.data, prop.selectedTransportationType.object);
        console.log(processedData);
        prop.setRatesData(processedData);
        prop.nextPage();
      } else {
        setError(validation.error || "");
      }
    }
  }

  return (
    <div className='upload-box-container'>
      <h2>Upload File</h2>
      {error && <p className='btn light-color gap-2 full-width bg-danger'><i className="bi bi-exclamation-triangle"></i> {error}</p>}
      {file ? 
        <div className={`dropzone`}>
          {progress !== 100 && <div className='over-flow-loading-box'>
            <LoadingSpinner />
            <p>Uploading {progress}%</p>
          </div>}
          <div className="file-info">
            <i className="bi bi-file-earmark-spreadsheet"></i>
            <p>{file.name}</p>
            <p><Moment format='ddd MMM DD'>{file.lastModified}</Moment><i className="bi bi-dot"></i>{Math.round(fileSize! / 1024)} KB</p>
            <button className='btn btn-danger' onClick={() => {setFile(null); setError("");}}>Remove</button>
          </div>
        </div>
      : <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        <div>
          <i className="bi bi-file-earmark-bar-graph"></i>
          <p>Drag and Drop file here or <u>Choose file</u></p>
        </div>
      </div>}
      <div className='file-info-upload-file'>
        <p>Supported format: XLS, XLSX</p>
        <p>Maximum Size: 25MB</p>
      </div>
      <div className='manual-data-entry-box-up-data'>
        <div>
          <h3>Manual Entry</h3>
          <p>if you would like to enter data manual you can do so my clicking the button "Manual".</p>  
        </div>
        <button onClick={manualData}>Manual</button>        
      </div>
      
      <div className='center-button-upload-file'>
        <Button label='Next' loading={false} onClick={nextStep}/>
      </div>
    </div>
  )
}
