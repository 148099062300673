import React, { useContext, useState } from 'react'
import InputField, { SelectField, TextAreaField } from '../../../../Components/InputField/InputField'
import "./style.css";
import Button from '../../../../Components/Button/Button';
import { AccountContext } from '../../../../Context/AuthContext';
import { CarrierInvitation, Company } from '../../../../API';
import { toast } from 'react-toastify';

const options = [
  { value: 'AIROCEAN', label: 'Air / Ocean' },
  { value: 'TL', label: 'TL' },
  { value: 'FTLLTL', label: 'FTL / LTL' }
]


interface IRequestPricing {
  setIsRequestingPricing: (value: boolean) => void,
  company: Company | null,
  addInvitation: (invitation: CarrierInvitation) => void;
}

export const RequestPricing = (prop: IRequestPricing) => {
  const [transportationMode, setTransportationMode] = useState<any>(null);
  const {getUser} = useContext(AccountContext);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const onSubmit = async (e: any) => {
    setIsSubmitted(true);
    e.preventDefault();
    const data = new FormData(e.target);
    const email = Object.fromEntries(data.entries()).email;
    const comment = Object.fromEntries(data.entries()).comment;
    if(email !== "" && transportationMode != null) {
      try {
        const user = getUser();
        const userSession = user.signInUserSession;
        const idToken = userSession.idToken.jwtToken;
        const userEmail = user.attributes.email;
        const itm = await fetch("https://vsqwc4gr9f.execute-api.us-east-1.amazonaws.com/Test/company/invite_carrier",
          {
            mode: "cors",
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Authorization": idToken,
            },

            body: JSON.stringify({
              email: email,
              ttype: transportationMode.value,
              comment: comment,
              company: prop.company?.Name,
              companyID: prop.company?.id,
              ownerID: user.username,
              companyEmail: userEmail
            }),
        });
        const data = await itm.json();
        if(data.statusCode === 200) {
          const carrierInvitationOBJ:CarrierInvitation = data.data;
          prop.addInvitation(carrierInvitationOBJ);
          prop.setIsRequestingPricing(false);
          toast.success(`Invitation send to ${email}`);
        }

        if(data.statusCode === 404) {
          toast.error(data.error);
        }
      } catch(e) {
        console.log(e);
      }   
    } else {
      //! Do Errors
      console.log(email, comment, transportationMode);
    }
    setIsSubmitted(false);
  }

  return (
    <div className='request-form-submit'>
      <div className='header-request'>
        <i className="bi bi-envelope-paper"></i>
        <div>
          <h2>Invite Carrier</h2>
          <p>Enhance collaboration with quick carrier invitations</p>
        </div>
      </div>

      <form onSubmit={onSubmit}>
        <InputField label='Email Addres' name="email"/>
        <SelectField label="Transportation Mode" list={options} name="rateType" error={""} value={transportationMode} onChange={setTransportationMode}/>
        <TextAreaField label='Comment' name='comment'/>
        <div className='action-list-button'>
          <div>
            <button type='button' className='button-link' onClick={() => prop.setIsRequestingPricing(false)}>Cancel</button>
            <Button loading={isSubmitted} label='Invite'/>
          </div>
        </div>
      </form>
    </div>
  )
}
