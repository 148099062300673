import React, {useState, useContext, useEffect} from 'react'
import { OtpField } from '../../../Components/InputField/InputField'
import "./style.css";
import Button from '../../../Components/Button/Button';
import { AccountContext } from '../../../Context/AuthContext';
import { useNavigate, useLocation} from 'react-router-dom';
import { Message } from '../../../Components/Message/Message';
import { CountDown } from '../../../Components/CountDown/CountDown';
import logo from "../../../assets/img/logo.png";
import { Reveal } from '../../../Components/Revel/Revel';


const INITIAL_ERROR = {
  otp: '',
}

interface currentUserInfo{
  userConfirmed: boolean, 
  userSub?: string,
  username: string,
  from: string
}


export const VerifyAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [timeRunning, setTimeRunning] = useState<boolean>(true);
  const [defaultTime, setDefaultTime] = useState<number>(30);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [currentUserInfo, setCurrentUserInfo] = useState<currentUserInfo>();
  const {sendMFACode, verifyMFACode, getSession, getUser} = useContext(AccountContext);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState<any>(INITIAL_ERROR);
  const [attemptLimitExceeded, setAttemptLimitExceeded] = useState(false);
  const [message, setMessage] = useState({
    code: '',
    form: '',
    type: '',
    header: '',
  });

  useEffect(() => {
    const onLoad = async () => {
      if(location.state != null) {
        setCurrentUserInfo(location.state);
        if(location.state?.from === "LOGIN") {
          try {
            await sendMFACode(location.state?.username);
            // toast.success(`Email sent successfully to ${location.state?.username}`, {toastId: "EMAIL_SENT"});
          } catch (e: any) {
            if(e.code === "LimitExceededException") {
              setDefaultTime(3000);
              setAttemptLimitExceeded(true);
            }
          }
        } else {
          setMessage((prev) => {
            return {...prev, form: `Verification code was sent successfully to ${location.state?.username}`, type: "SUCCESS", header: "Email Sent"}
          });
        }
  
        
      } else {
        navigate("/");
      }
    }

    onLoad();
  }, [location.state, navigate, sendMFACode]);

  const confirmCode = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    setError(INITIAL_ERROR);
    setMessage({code: '', form: '', type: '', header: ''});
    if(otp !== "") {
      try {
        const response = await verifyMFACode(currentUserInfo?.username, otp);
        if(response === 'SUCCESS') {
         
          //console.log(getUser());
          //console.log("re Direct");
          //
          //! Select Account Types
          //navigate('/dashboard');
          navigate("/auth/login", {state: {response: "SUCCESS_VERIFICATION"}});
        }
      } catch (e: any) {
        if(e.code === "CodeMismatchException") {
          setError((prev: any) => {
            return {...prev, otp: "Invalid code provided, please try again."}
          });
        } else if(e.code === "LimitExceededException") {
          setDefaultTime(3000);
          setAttemptLimitExceeded(true);
        }
        //const errorMessage:any = getErrorMessage(e.code, e.message);
        //setMessage(errorMessage);
        // if(e.code === "CodeMismatchException") {
        //   setError({for: "code", note: e.message});
        //   // setMessage((prev) => {
        //   //   return {...prev, code: }
        //   // });
        // }

        // if(e.code === "ExpiredCodeException") {
        //   setMessage((prev) => {
        //     return {...prev, form: `The code that you entered was expired. Click the resend button to get a new code`, type: "WARNING", header: "Code Expired"}
        //   });
        // }

        // if(e.code === "NotAuthorizedException") {
        //   navigate("/");
        // }
        console.log(e);
      }
    } else {
      
    }

    setFormSubmitted(false);
    //setError(INITIAL_ERROR);
  }

  

  const timeRun = async () => {
    setTimeRunning(true);
    setDefaultTime(prev => prev + 30);
    try {
      await sendMFACode(currentUserInfo?.username);
      // toast.success(`Email sent successfully to ${currentUserInfo?.username}`, {toastId: "EMAIL_SENT"});
    } catch (e: any) {
      if(e.code === 'LimitExceededException') {
        setDefaultTime(3000);
        setAttemptLimitExceeded(true);
      }
      // toast.error(e.message, {toastId: "ERROR_RESEND_EMAIL_TOAST"});
    }
  }

  return (
    <div className='logo-container'>
      <img src={logo} width={130} /> 
      <Reveal>
        <div className='login-form-container'>
          {attemptLimitExceeded && 
          <div className='locked-portal'>
            <i className="bi bi-lock"></i>
            <h2 className='poppins-light'>Locked</h2>
            <p className='poppins-extralight'>Too many attempts have been made. Please wait a while before trying again. Thank you for your patience.</p>
            <CountDown seconds={defaultTime} setTimeRunning={setAttemptLimitExceeded}/>
          </div>}
          <div className='login-header'>
            <h1 className='poppins-regular'>Account Verification</h1>
            <p className='poppins-light'>Please type the 6-digit code that you received on your email {currentUserInfo?.username}.</p>
          </div>
          
          <form onSubmit={confirmCode} className='login-form'>
            <OtpField label='Verification Code *' name="otp" value={otp} otpChange={setOtp} error={error.otp}/>
            {/* <InputField name='email' label='Email' type='email' error={error.email}/> */}
            <Button label='Confirm' type='submit' loading={formSubmitted} />
            {/* <Link className="text-link return-back-link" to="/auth/login">Return to sign in</Link> */}
          </form>
          <div className='bottom-login-form'> 
            <p className="poppins-light">Didn't receive the Code? {!timeRunning ? <button className="text-link text-button poppins-light" onClick={timeRun} type='button'>Resend Code</button> : <CountDown seconds={defaultTime} setTimeRunning={setTimeRunning}/>}</p>
          </div>
        </div>
      </Reveal>
    </div>
  )
}
