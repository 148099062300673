import React, { useEffect, useState } from 'react'
import { Company, Departments, RateBatch, Rates } from '../../../../API';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import "./style.css";
import moment from 'moment';
import { CSVProcessor } from '../../../../CSVProcessor/CSVProcessor';
import { ModualPop } from '../../../../Components/ModulePop/ModulePop';
import { GeneratePop } from '../components/GeneratePop';
import { toast } from 'react-toastify';

interface IApprovedRates {
  approvedRates: (RateBatch | null)[];
  business: Company | null;
}

interface DepartmentOption {
  value: string;
  label: string;
}

export const ApprovedRates = (prop: IApprovedRates) => {
  const [currentSelectedRate, setCurrentSelectedRate] = useState<RateBatch | null>(null);
  const [isGeneratingCSV, setIsGenerating] = useState(false);
  const [departmentsList, setDepartmentsList] = useState<DepartmentOption[]>([]);

  useEffect(() => {
    if(prop.business) {
      const dataDepartment:DepartmentOption[] = [];

      prop.business.Departments?.items.forEach((item: Departments | null) => {
        dataDepartment.push({
          value: item?.DepartmentSCAC || "",
          label: item?.DepartmentSCAC || "",
        });
      });

      setDepartmentsList(dataDepartment);
    }
  }, [prop.business]);


  const generateRates = (data: any, companyData: any) => {
    console.log(data, companyData);
    const CSVProcessors = new CSVProcessor([]);
    CSVProcessors.generateData(data.tsMode, data.Rates.items, companyData);
    // //console.log(data.tsMode);
    //
    //console.log(data);
  };

  const setSingleRate = (item: RateBatch) => {
    const departments = prop.business?.Departments?.items || [];

    if (departments.length === 0) {
      toast.error("Unable to initialize CSV generation. No departments found. Please add a department before continuing.");
      return;
    }

    setCurrentSelectedRate(item);
    setIsGenerating(true);
  }

  return (
    <>
    <ModualPop show={isGeneratingCSV} child={<GeneratePop generateRates={generateRates} departmentsList={departmentsList} setIsGenerating={setIsGenerating} business={prop.business} currentSelectedRate={currentSelectedRate}/>}/> 
    {prop.approvedRates.length === 0 ? <div className='box-security-token no-rates-box mt-4 pt-6'> 
        <div className='no-result-found mt-1'>
          <div>
            <i className="bi bi-paperclip"></i>
            <h2>No Approved Rates Found</h2>
            <p>For any questions or information regarding rates, please contact your carrier directly. They will be able to provide you with the most accurate and up-to-date details.</p>
            <Link to="/">Learn More <i className="bi bi-arrow-right"></i></Link>
          </div>
        </div> 
    </div> :

    <div className='d-flex justify-content-between align-items-center header-section-dash-single-row over-flow-box'>
      <table className='table-of-items activity-table rate-accepted-rates-table'>
        <thead>
          <th>FF <i className="bi bi-arrow-down"></i></th>
          <th>Rate Type</th>
          <th>Rate Count</th>
          <th>Duration</th>
          <th></th>
        </thead>
        <tbody>
          {prop.approvedRates.map((item: any, index) => {
            return (
              <tr key={index}>
                <td>
                  {item?.scac}
                </td>
                <td>{item?.tsMode}</td>
                <td>
                  {item?.Rates?.items.length}
                </td>
                <td>
                  <p>
                    <Moment format="MMM DD, YYYY">{moment.utc(item?.startDate)}</Moment> - <Moment format="MMM DD, YYYY">{moment.utc(item?.endDate)}</Moment>
                  </p>
                </td>
                <td className='last-table-col'>
                  <div className='d-flex gap-2 end-table-col'>
                    
                    <button className='btn btn-dark' onClick={() => setSingleRate(item)}>Generate CSV</button>
                    <button className='btn btn-success'>Upload to OTM</button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>}
    </>
  )
}
