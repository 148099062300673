import React, { useEffect, useState } from 'react'
import noResult from "../../../assets/img/undraw_No_data_re_kwbl.png";
import { Link, useOutletContext } from 'react-router-dom';
import { Company, UserInvitation } from '../../../API';
import Moment from 'react-moment';
import Button from '../../../Components/Button/Button';
import { Slider } from 'rsuite';
import { ModualPop } from '../../../Components/ModulePop/ModulePop';
import AddUser from './AddUser';
import { UserTable } from './UserTable';

interface DashboardContext {
  business: Company | null;
}

export const UserList = () => {
  const [userData, setUserData] = useState<(UserInvitation | null)[]>([]);
  const {business} = useOutletContext<DashboardContext>();
  const [isAddingUser, setIsAddingUser] = useState<boolean>(false);

  useEffect(() => {
    setUserData(business?.UserInvitations?.items || []);
  },[business]);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      {/* <h4 className="py-3 mb-4">Add User</h4> */}

      <div className="row">
        <div className='card user-list-card'>
          <div className='d-flex justify-content-between align-items-center header-section-dash-single-row'>
            
            <div className="card-header">
              <h5 className='mb-1'>User List</h5>
              <p>Rate Inquiries are only seen when submitted by a carrier</p>
            </div>

            <div className='page-header-right-side'>
              <div className='invites-info'>
                <p className='full-width'>
                  <strong>{business?.CurrentSourcingUsers}</strong> / {business?.MaxSourcingUsers} Invites
                </p>
                <Slider min={0} max={business?.MaxSourcingUsers || 0} value={business?.CurrentSourcingUsers || 0} progress className='progress-class mb-1 mt-1'/>
                <small className='invite-status'>
                  {business?.CurrentSourcingUsers === business?.MaxSourcingUsers
                    ? "Invite Limit Reached"
                    : "You can invite more users"}
                </small>
              </div>
              <Button label={"Add User"} loading={false} icon={<i className="bi bi-person-fill-add"></i>} onClick={() => setIsAddingUser(true)}></Button>
              <ModualPop child={<AddUser setIsAddingUser={setIsAddingUser}/>} show={isAddingUser}/>
            </div>
          </div>
        </div>
      </div>

      <UserTable userData={userData}/>
    </div>
  )
}
