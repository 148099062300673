// import React, { useEffect } from 'react';
// import { Auth } from 'aws-amplify';
// import { useNavigate, useParams } from 'react-router-dom';

// import { jwtDecode } from "jwt-decode";

const TokenLogin = () => {
  // const navigate = useNavigate();
  // const { token } = useParams();
  // useEffect(() => {
  //   const handleLogin = async () => {

  //     if (token) {
  //       try {
  //         const decoded:any = jwtDecode(token);
  //         const { username, ...userAttributes } = decoded;

  //         console.log(decoded);
  //         // Authenticate the user with AWS Cognito
  //         await Auth.signIn(username);
        
  //         //await Auth.sendCustomChallengeAnswer(username, token);
  //         // Redirect to the main app screen
  //         //navigate('/');
  //       } catch (error) {
  //         console.error('Login failed:', error);
  //       }
  //     }
  //   };

  //   handleLogin();
  // }, [navigate]);

  return (
    <div>Loading...</div>
  )
}

export default TokenLogin