import React, { useState } from 'react'
import Card from '../components/Card'
import { Reveal } from '../../../Components/Revel/Revel'
import { Link, useOutletContext } from 'react-router-dom';
import { Company } from '../../../API';
import Moment from 'react-moment';
import "./style.css";
import Slider from 'rsuite/Slider';
import 'rsuite/Slider/styles/index.css';
import noResult from "../../../assets/img/undraw_No_data_re_kwbl.png";
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons from react-icons
import { CarrierTable } from '../Carriers/CarrierTable';
import { UserTable } from '../UserList/UserTable';

interface DashboardContext {
  business: Company | null;
}

export const DashboardMain = () => {
  const { business } = useOutletContext<DashboardContext>();
  const [isVisible, setIsVisible] = useState(false); // State to manage visibility

  const toggleVisibility = () => {
      setIsVisible(prevState => !prevState);
  };

  const convertTimestampToDate = (timestamp: any):string => {
    // Convert the timestamp to milliseconds and create a new Date object
    const date = new Date(timestamp * 1000);
    // Format the date to a readable string
    return date.toLocaleString(); // You can customize the format as needed
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <h4 className="py-3 mb-4">Dashboard</h4>
      <div className="row">
        <Card business={business}/>
      </div>

      <div className="row">
        {/*  */}

        <div className="col-sm-8 col-lg-6 mb-4 child-div-full-width">
        <Reveal delay={.4}>
          <div className="card card-border-shadow-success">
            <div className="card-body">
              <div className="d-flex align-items-center pb-1"> 
                <h4 className="mb-0 fs-5">Current Plan Usage</h4>
              </div>
              <div className='mt-3 progress-bar-current-plan'>
                <div>
                  <div className='section-header-progress-dashboard'>
                    <small>Active Carrier Users</small>

                    <span>{business?.CurrentCarrierUsers}/{business?.MaxCarrierUsers}</span>
                  </div>
                  <Slider min={0} max={business?.MaxCarrierUsers || 0} value={business?.CurrentCarrierUsers || 0} progress className='progress-class'/>
                </div>
                <div>
                  <div className='section-header-progress-dashboard'>
                    <small>Active Sourcing Users</small>
                    <span>{business?.CurrentSourcingUsers}/{business?.MaxSourcingUsers}</span>
                  </div>
                  <Slider min={0} max={business?.MaxSourcingUsers || 0} value={business?.CurrentSourcingUsers || 0} progress className='progress-class'/>
                </div>
              </div>
            </div>
          </div>
          </Reveal>
        </div>
      </div>

      <div className="row mb-5 mt-3 child-div-full-width">
        <Reveal delay={.5}>
            <h5 className="card-header">User List</h5> 
          <UserTable userData={business?.UserInvitations?.items || []}/>
        </Reveal>
      </div>

      <div className="row mb-4 child-div-full-width">
        <Reveal delay={.3}>
            <h5 className="card-header">Carriers List</h5> 

            <CarrierTable isLoading={false} business={business}/>
        </Reveal>
      </div>

    </div> 
)
}
