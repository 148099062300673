import React, { useState } from 'react'
import { Reveal } from '../../../Components/Revel/Revel';
import Button from '../../../Components/Button/Button';
import { Link } from 'react-router-dom';
import { CgSmartphoneChip } from "react-icons/cg";
import { RiLoopLeftLine } from "react-icons/ri";
import { LuLayoutGrid } from "react-icons/lu";
import dashboard from "../../../assets/img/das.png";

export const Home = () => {
  const [faqOpenSection, setFaqOpenSection] = useState("one");

  const openBox = (location: any) => {
    if(faqOpenSection === location) {
      setFaqOpenSection("");
    } else {
      setFaqOpenSection(location);
    }
  }
  
  return (
    <>
    <div className='section-banner-post'>
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>  

        <div className='content-sizing section-container-banner'>
          <div className='left-side-info-banner'>
            <Reveal delay={.200}>
              <span className='tag-span'>OTM Rate Automation Tool <i className="bi bi-chevron-right"></i></span>
            </Reveal>
            <Reveal delay={.400}>
              <h1 className='main-page-front-text'>Streamline <br/>Rate Management Process With <br/>Our Tools</h1>
            </Reveal>
            <Reveal delay={.600}>
              <p className='main-page-sub-text'>Join the millions of companies of all sizes that use Stripe to accept payments online and in person, embed financial services, power custom revenue models, and build a more profitable business.</p>
            </Reveal>

            <Reveal delay={.800}>
              <div className='banner-action-button'>
                <Button loading={false} label="Learn More"></Button>
                <Link to="" className='text-link poppins-light'>Book a Demo <i className="bi bi-chevron-right"></i></Link>
              </div>
            </Reveal>
          </div>

         
          <div className='right-hand-side-section-banner'>
            {/* <div className='phone-box-banner'>
              <ul>
                <li>
                  <img src="https://cdn.discordapp.com/attachments/493954009330221066/1275306670666551418/cce070d10d99911c9b7143af8b1e8431_1.png?ex=66c56964&is=66c417e4&hm=5679d6be0e4216eb1cefacb9ccc2c9b16916a0db8cba0f8352eebd277f2a1a37&" />
                </li>
                <li className='abosolute-box'>
                  <img src="https://cdn.discordapp.com/attachments/493954009330221066/1275307255750721546/2.png?ex=66c569ef&is=66c4186f&hm=d4b72ea0b9a0df0f4a2f9e8b9f1775af2d89e152eef26d0d7a5f10ac7d9337e4&" />
                </li>
                <li style={{zIndex: 100}}>
                  <img src="https://cdn.discordapp.com/attachments/493954009330221066/1275307255453057065/cce070d10d99911c9b7143af8b1e8431_2.png?ex=66c569ef&is=66c4186f&hm=9099867a14e6a63420bf0f0e61ceb0b11dd883c8e6b9d48b4344b9debea41ca3&" />
                </li>
              </ul>
            </div> */}
            <div className='dashboard-image-banner'>
              <img src={dashboard} width={1100}/>
            </div>
          </div>
          
        </div>
      </div>

      <section className='overview-container'>
        <div className='content-sizing over-view-content'>
          <div className='overview-header'>
            <Reveal delay={.3}>
              <span className='primary-tag'>Modular solutions</span>
            </Reveal>
            <Reveal delay={.8}>
              <h2 className='overview-header-title'>AI-Enhanced <br/>Task Management</h2>
            </Reveal>
            <Reveal delay={1}>
              <p className='overview-header-paragraph'>Where AI transforms task management. Our innovative AI tool streamlines task collaboration for teams, making productivity effortless.</p>
            </Reveal>
          </div>

          <ul className='overview-list-data'>
            <Reveal delay={1.2}>
              <li className='overview-list-data-single'>
                <div>
                  <div>
                    <CgSmartphoneChip size={30} color={'#0b67e9'}/>
                  </div>
                  <h3>Smart Task Assignment</h3>
                  <p>AI intelligently assigns tasks based on workload and skills.</p>
                </div>

                <div className='image-placer-holder'>
                  <img src="https://cdn.discordapp.com/attachments/493954009330221066/1275306670666551418/cce070d10d99911c9b7143af8b1e8431_1.png?ex=66c56964&is=66c417e4&hm=5679d6be0e4216eb1cefacb9ccc2c9b16916a0db8cba0f8352eebd277f2a1a37&" />
                </div>
              </li>
            </Reveal>
            <Reveal delay={1.5}>
              <li className='overview-list-data-single'>
                <div className='image-placer-holder'>
                  <img src="https://cdn.discordapp.com/attachments/493954009330221066/1275307255750721546/2.png?ex=66c569ef&is=66c4186f&hm=d4b72ea0b9a0df0f4a2f9e8b9f1775af2d89e152eef26d0d7a5f10ac7d9337e4&" />
                </div>
                <div>
                  <div>
                    <RiLoopLeftLine size={30} color={'#0b67e9'}/>
                  </div>
                  <h3>Automated Reminders</h3>
                  <p>AI intelligently assigns tasks based on workload and skills.</p>
                </div>
              </li>
            </Reveal>
            <Reveal delay={1.8}>
            <li className='overview-list-data-single'>
              <div>
                <div>
                  <LuLayoutGrid size={30} color={'#0b67e9'}/>
                </div>
                <h3>Predictive Scheduling</h3>
                <p>AI intelligently assigns tasks based on workload and skills.</p>
              </div>
              <div className='image-placer-holder'>
                <img src="https://cdn.discordapp.com/attachments/493954009330221066/1275307255453057065/cce070d10d99911c9b7143af8b1e8431_2.png?ex=66c569ef&is=66c4186f&hm=9099867a14e6a63420bf0f0e61ceb0b11dd883c8e6b9d48b4344b9debea41ca3&" />
              </div>
            </li>
            </Reveal>
          </ul>
        </div>

        <div className='breaker-middle-get-started'>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>  
          <h2>Bring success to your business with us</h2>
          <p>Partner with us for business success: tailored solutions, expert guidance, and strategies await.</p>
          <Reveal>
            <Button label='Get Started' loading={false} className='mt-5'></Button>
          </Reveal>
        </div>

        <div className='main-scroll-effect'>
          <div className='content-sizing main-scroll-effect-content'>
            <div className='left-side-block-fixed'>
              <Reveal delay={.3}>
                <span className='primary-tag'>Modular solutions</span>
              </Reveal>
              <Reveal delay={.5}>
                <h2 className='left-side-block-fixed-header'>Set up multi-party payments and payouts</h2>
              </Reveal>
              <Reveal delay={.8}>
                <p className='left-side-block-fixed-para'>Where AI transforms task management. Our innovative AI tool streamlines task collaboration for teams, making productivity effortless.</p>
              </Reveal>

              <ul className="single-block-list-style">
                <li><i className="bi bi-check"></i> Effortless budget tracking made simple.</li>
                <li><i className="bi bi-check"></i>Clear spending insights for you.</li>
                <li><i className="bi bi-check"></i>Simplified financial planning just got easier.</li>
              </ul>

              <Reveal delay={1}>
                <Button label='Request a Demo' loading={false} className='mt-4'></Button>
              </Reveal>
            </div>

            <div className='main-sides-photo-holder'>
              <h1>400x500</h1>
            </div>
          </div>
        </div>

        <div className='main-scroll-effect'>
          <div className='content-sizing main-scroll-effect-content'>
            

            <div className='main-sides-photo-holder'>
              <h1>400x500</h1>
            </div>

            <div className='left-side-block-fixed'>
              <Reveal delay={.2}>
                <span className='primary-tag'>Modular solutions</span>
              </Reveal>
              <Reveal delay={.5}>
                <h2 className='left-side-block-fixed-header'>Set up multi-party payments and payouts</h2>
              </Reveal>
              <Reveal delay={.8}>
                <p className='left-side-block-fixed-para'>Where AI transforms task management. Our innovative AI tool streamlines task collaboration for teams, making productivity effortless.</p>
              </Reveal>

              <ul className="single-block-list-style">
                <li><i className="bi bi-check"></i> Effortless budget tracking made simple.</li>
                <li><i className="bi bi-check"></i>Clear spending insights for you.</li>
                <li><i className="bi bi-check"></i>Simplified financial planning just got easier.</li>
              </ul>


              <Reveal delay={1}>
                <Button label='Request a Demo' loading={false} className='mt-4'></Button>
              </Reveal>
            </div>
          </div>
        </div>

      </section>

      <section className='faq-info-main-page'>
        <div className='content-sizing faq-box-main-content'>
          
          <div className='faq-content'>
            <div className='left-title-faq'>
              <h2>Have Questions? <br/> Our Experts Has Answers!</h2>
            </div>

            <Reveal delay={.2}>
              <ul className='faq-list-container-main-page'>
                <li className={faqOpenSection === "one" ? "active faq" : "faq"} onClick={() => openBox("one")}>
                  <div className="question">
                    <h3>What security measures does your SAAS solution have in place to protect user data?</h3>
                    <i className="bi bi-chevron-down"></i>
                  </div> 
                  <div className="answer">
                    <p>At Syncafy, we take the security of user data very seriously. Our SAAS solution employs industry-standard encryption protocols to ensure that all data transmission and storage are secure. We also implement robust access control measures to restrict access to sensitive information only to authorized personnel. Regular security audits and updates are conducted to identify and address any potential vulnerabilities, ensuring that your data remains protected at all times.</p>
                  </div>
                </li>
                <li className={faqOpenSection === "two" ? "active faq" : "faq"} onClick={() => openBox("two")}>
                  <div className="question">
                    <h3>Can your SAAS platform integrate with other software systems we use?</h3>
                    <i className="bi bi-chevron-down"></i>
                  </div> 
                  <div className="answer">
                    <p>Yes, our SAAS platform is designed to seamlessly integrate with a wide range of other software systems commonly used by businesses. Whether it's customer relationship management (CRM) software, accounting tools, or project management platforms, our flexible integration capabilities enable smooth data exchange and workflow automation across multiple systems. Our team of integration specialists is available to assist with the setup and customization of integrations to meet your specific needs.</p>
                  </div>
                </li>
                <li className={faqOpenSection === "three" ? "active faq" : "faq"} onClick={() => openBox("three")}>
                  <div className="question">
                    <h3>How does your SAAS solution ensure scalability as our business grows?</h3>
                    <i className="bi bi-chevron-down"></i>
                  </div> 
                  <div className="answer">
                    <p> Syncafy's SAAS solution is built on a scalable architecture that allows it to grow with your business. Whether you're a small startup or a large enterprise, our platform can accommodate your changing needs and increasing demands without compromising performance or reliability. Our infrastructure is designed to handle high volumes of data and traffic, ensuring a seamless user experience even as your business expands.</p>
                  </div>
                </li>
                <li className={faqOpenSection === "four" ? "active faq" : "faq"} onClick={() => openBox("four")}>
                  <div className="question">
                    <h3>What level of customer support and maintenance is included with your SAAS subscription?</h3>
                    <i className="bi bi-chevron-down"></i>
                  </div> 
                  <div className="answer">
                    <p>As a Syncafy subscriber, you'll receive excellent customer support 24/7. Our team is here to help with any questions or technical issues you may have. Plus, we regularly update and maintain our platform for your security and performance. We're committed to your satisfaction and success with our SAAS solution.</p>
                  </div>
                </li>
              </ul>
            </Reveal>
          </div>

        </div>
      </section>
    </>
  )
}
