import React, { useEffect, useState } from 'react'
import "./style.css";
import { SelectField } from '../../../../../Components/InputField/InputField';
import { ITransportationType, TransportationTypes } from '../../../../../Interfaces/ITransportationType';
import { Carrier } from '../../../../../API';
import { useMultiStepForm } from './hooks/userMultiStepForm';
import { RateType } from './steps/RateType';
import { UploadRates } from './steps/UploadRates';
import { ReviewData } from './steps/ReviewData';
import { GLOBAL_RATE_TYPE } from '../../../../../Interfaces/IRateForm';


interface ICreateRates {
  carrier: Carrier | null;
  setCreatingRate: (data: boolean) => void
}



export const CreateRates = (prop : ICreateRates) => {
  const [progress, setProgress] = useState<number>(0);
  const [selectedTransportationType, setSelectedTransportationType] = useState<ITransportationType>(TransportationTypes[0]);
  const [selectedRateMode, setSelectedRateMode] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [companies, setCompanies] = useState<any>({});
  const [ratesData, setRatesData] = useState<GLOBAL_RATE_TYPE[] | null>(null);

  useEffect(() => {
    if(prop.carrier != null) {
      console.log(prop.carrier.CarrierCompanies);
      const data = prop.carrier.CarrierCompanies?.items.map(item => {
        return {value: item?.companyID, label: item?.Name}
      });
      setCompanies(data);
    }
  }, [prop.carrier]);

  const nextPage = () => {
    next();
  }

  const goBack = () => {
    back();
  }

  const createModule = () => {
    prop.setCreatingRate(false)
    goTo(0);
    setRatesData([]);
  }
  
  const {back, next, step, isFirstStep, isLastStep, currentStepIndex, goTo, steps} = useMultiStepForm(
    [
      <RateType selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} companies={companies} nextPage={nextPage} setSelectedTransportationType={setSelectedTransportationType} selectedTransportationType={selectedTransportationType}/>,
      <UploadRates setRatesData={setRatesData} nextPage={nextPage} selectedTransportationType={selectedTransportationType}/>,
      <ReviewData carrier={prop.carrier} selectedCompany={selectedCompany} ratesData={ratesData} setRatesData={setRatesData} selectedTransportationType={selectedTransportationType} createModule={createModule}/>
    ]
  );

  useEffect(() => {
    setProgress(((currentStepIndex) / steps.length) * 100);
  }, [currentStepIndex]);

  

  return (
    <div className='container-creating-rates'>
      <div className='header-creating-rates'>
        <div className='d-flex back-and-title-section'>
          {!isFirstStep && <button className='btn' onClick={back}><i className="bi bi-arrow-left"></i> Back</button> }
          {currentStepIndex === 0 && <h2 className='title-create-module'>Select Transportation Type</h2>}
          {currentStepIndex === 1 && <h2 className='title-create-module'>Upload Excel Rate Files</h2>}
        </div>
        <button className='btn' onClick={createModule}><i className="bi bi-x-lg"></i></button>
      </div>
      <div className='progress-bar-steps-uploading-data'>
        <div className='progress-data-value' style={{width: progress + "%"}}></div>
      </div>
      <div className='content-box-uploading-rates'>
        {step}
      </div>
    </div>
  )
}
