import React, { useContext, useMemo, useState } from 'react'
import InputField from '../../../Components/InputField/InputField'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { ITransportationType, TransportationTypes, TransportField } from '../../../Interfaces/ITransportationType';
import { getRateBatch, getRates } from '../../../graphql/queries';
import { Company, Rates, Status } from '../../../API';
import { ModualPop } from '../../../Components/ModulePop/ModulePop';
import { AcceptRate } from './components/AcceptRate';
import { AccountContext } from '../../../Context/AuthContext';
import { RejectRate } from './components/RejectRate';
import { json } from 'stream/consumers';
import "./style.css";

interface DashboardContext {
  business: Company | null;
}

export const ViewRatesSingle = () => {
  const {business} = useOutletContext<DashboardContext>();

  const navigation = useNavigate();
  const {getUser} = useContext(AccountContext);
  const [rateViewLayout, setRateViewLayout] = useState<TransportField[]>([]);
  const [rateData, setRateData] = useState<any[]>([]);
  const [carrierInfo, setCarrierInfo] = useState<Rates | null>(null);
  const [modalAccept, setModalAccept] = useState(false);
  const [modalReject, setModalReject] = useState(false);
  const {id} = useParams();
  
  useMemo(async () => {
    try {
      const response:any = await API.graphql({query: getRateBatch, authMode: "AMAZON_COGNITO_USER_POOLS", variables:{id: id}});
      if(response != undefined) {
        const carrier = response.data.getRateBatch;
        const rate = response.data.getRateBatch.Rates.items;
        const RateTransportationTypeObj = TransportationTypes.find((item: ITransportationType) => item.type === carrier.tsMode);
        if(RateTransportationTypeObj != undefined) {
          console.log(RateTransportationTypeObj.fields)
          setRateViewLayout(RateTransportationTypeObj.fields);
        }
        const cleanRateRate = rate.map((item: any) => {
          return JSON.parse(item.data);
        })
        setRateData(cleanRateRate);
        setCarrierInfo(carrier);
      }
    } catch(e: any) {
      console.log(e);
    }
  }, [id]);

  const onRateAction = async (status: Status, comment: string) => {
    try {
      console.log(business);
      const user = getUser();
      const userSession = user.signInUserSession;
      const idToken = userSession.idToken.jwtToken;
      const itm = await fetch("https://vsqwc4gr9f.execute-api.us-east-1.amazonaws.com/Test/company/rate-action",
        {
          mode: "cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": idToken,
          },

          body: JSON.stringify({
            rateOwner: carrierInfo?.owner,
            status: status,
            scac: carrierInfo?.scac,
            comment: comment,
            rateBatchId: id,
            companyName: business?.Name,
            tsMode: carrierInfo?.tsMode,
            userId: user.username
          }),
      });
      const data = await itm.json();
      console.log(data);
      // if(data.statusCode === 200) {
      //   navigation('/dashboard/pricing-sheets');
      // }
    } catch(e) {
      console.log(e);
    }   
  }

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="card pricing-sheet">
            <div className='d-flex justify-content-between align-items-center header-section-dash-single-row'>
              <div className='d-flex justify-content-between align-items-center'>
                <button className='btn btn-light' onClick={() => navigation('/dashboard/pricing-sheets')}><i className="bi bi-arrow-left"></i></button>
                <div className="card-header">
                  <h5 className='mb-1'>{carrierInfo?.serviceName}({carrierInfo?.scac})</h5>
                  <p>You can only upload rates when you have accepted a carrier's invitation</p>
                </div> 
              </div>

              <div className='gap-3 d-flex justify-content-between align-items-center'>
               <button className='btn btn-danger poppins-regular' onClick={() => setModalReject(true)}>Reject All</button>
                <button className='btn btn-success poppins-regular' onClick={() => setModalAccept(true)}>Approve All</button>
              </div>
            </div> 
          </div>
        </div>

        <div className="custom-table-container">
            <table className="custom-table">
                <thead>
                    <tr>
                        
                        {rateViewLayout.map((item, index) => (
                            <th className="custom-table-header" key={index}>
                                {item.label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rateData.map((rateDataSingle, mainIndex) => (
                        <tr
                            className={`custom-table-row`}
                            key={mainIndex}
                        >
                            
                            {rateViewLayout.map((item, index) => (
                                <td className="custom-table-cell" key={index}>
                                    {rateDataSingle[item.objectKey]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
      </div>
      <ModualPop show={modalAccept} child={<AcceptRate onRateAction={onRateAction}setModalAccept={setModalAccept}/>}/>
      <ModualPop show={modalReject} child={<RejectRate onRateAction={onRateAction} setModalReject={setModalReject}/>}/>
    </>
  )
}
