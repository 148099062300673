import React from 'react'
import { RateBatch, Rates } from '../../../../API';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import "./style.css";
import moment from 'moment';

interface IRejectedRates {
  rejectedRates: (RateBatch | null)[];
}

export const RejectedRates = (prop: IRejectedRates) => {
  return (
    prop.rejectedRates.length === 0 ? <div className='box-security-token no-rates-box mt-4 pt-6'> 
        <div className='no-result-found mt-1'>
          <div>
            <i className="bi bi-paperclip"></i>
            <h2>No Rejected Rates Found</h2>
            <p>For any questions or information regarding rates, please contact your carrier directly. They will be able to provide you with the most accurate and up-to-date details.</p>
            <Link to="/">Learn More <i className="bi bi-arrow-right"></i></Link>
          </div>
        </div> 
    </div> :

    <div className='d-flex justify-content-between align-items-center header-section-dash-single-row over-flow-box'>
      <table className='table-of-items activity-table rate-new-rates-table'>
        <thead>
          <th>FF <i className="bi bi-arrow-down"></i></th>
          <th>Rate Type</th>
          <th>Rate Count</th>
          <th>Duration</th>
          <th></th>
        </thead>
        <tbody>
          {prop.rejectedRates.map((item: any, index) => {
            return (
              <tr key={index}>
                <td>
                  {item?.scac}
                </td>
                <td>{item?.tsMode}</td>
                <td>
                  {item?.Rates?.items.length}
                </td>
                <td>
                  <p>
                    <Moment format="MMM DD, YYYY">{moment.utc(item?.startDate)}</Moment> - <Moment format="MMM DD, YYYY">{moment.utc(item?.endDate)}</Moment>
                  </p>
                </td>
                <td className='last-table-col'>
                  <div className='d-flex gap-2 end-table-col'>
                    {/* <button className='btn btn-outline-primary' onClick={() => handleViewDetails(item?.id)}>View Details</button> */}
                    <button className='btn btn-outline-secondary' onClick={() => handleContactCarrier(item?.carrierID)}>Contact Carrier</button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

// Example handlers (implement according to your app's logic)
const handleViewDetails = (id: string | undefined) => {
  // Navigate to the rate batch details page or show a modal with more information
  console.log('Viewing details for RateBatch ID:', id);
};

const handleContactCarrier = (carrierID: string | undefined) => {
  // Trigger a contact action, like opening an email modal or redirecting to a contact form
  console.log('Contacting carrier with ID:', carrierID);
};