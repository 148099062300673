import React, { useContext } from 'react'
import InputField from '../../../../Components/InputField/InputField'
import "./style.css";
import { useOutletContext } from 'react-router-dom';
import { Company } from '../../../../API';
import { AccountContext } from '../../../../Context/AuthContext';
import Button from '../../../../Components/Button/Button';

interface DashboardContext {
  business: Company | null;
}


export const General = () => {
  const {business} = useOutletContext<DashboardContext>();
  const {getUser} = useContext(AccountContext);
  return (
    <div className='general-box-setting'>
      <h3>General Settings</h3>
      <p>this are account setting for general account</p>
      <div className='single-col-box'>
        <div className='header-single-setting-box'>
          <h4>Full Name</h4>
          <p>This detail will be appearing on the website</p>
        </div>
        <InputField label='' name='name' value={getUser().attributes.name}/>
        
      </div>
      <div className='single-col-box'>
        <div className='header-single-setting-box'>
          <h4>Email Address</h4>
          <p>This detail will be appearing on the website</p>
        </div>
        <InputField label='' name='email' disabled value={getUser().attributes.email}/>
        
      </div>
      <div className='single-col-box'>
        <div className='header-single-setting-box'>
          <h4>Country</h4>
          <p>This detail will be appearing on the website</p>
        </div>
        <InputField label='' name='country' value={getUser().attributes.locale}/>
        
      </div>

      <h3 className='mt-4'>Account Settings</h3>
      <p>this are account setting for general account</p>

      <div className='single-col-box mt-3 mb-5'>
        <div className='header-single-setting-box'>
          <h4>Account Status</h4>
          <p>This detail will be appearing on the website</p>
        </div>
        <span className='label-small-box bg-label-success rounded avatar-initial'>{getUser().attributes.email_verified ? "Verified" : "Not Verified"}</span>
        
      </div>

      <div className='single-col-box mb-5'>
        <div className='header-single-setting-box'>
          <h4>User Identifiers</h4>
          <p>This detail will be appearing on the website</p>
        </div>
        <div className='copy-text-container'>
          <i className="bi bi-copy"></i>
          <p className='small-text'>{getUser().attributes.sub}</p>
        </div>
        
      </div>

      <div className='single-col-box mb-4'>
        <div className='header-single-setting-box'>
          <h4>Account Type</h4>
          <p>This detail will be appearing on the website</p>
        </div>
        <p>{getUser().attributes["custom:accountType"] === "BUSINESS" && "Corporate Account"}</p>
        
      </div>

      <h3 className='mt-4'>Company Settings</h3>
      <p>this are account setting for general account</p>

      <div className='single-col-box'>
        <div className='header-single-setting-box'>
          <h4>Name</h4>
          <p>This detail will be appearing on the website</p>
        </div>
        <InputField label='' name='name' value={business?.Name || ""}/>
        
      </div>

      <div className='single-col-box'>
        <div className='header-single-setting-box'>
          <h4>Phone Number</h4>
          <p>This detail will be appearing on the website</p>
        </div>
        <InputField label='' name='name' value={business?.PhoneNumber || ""}/>
        
      </div>
      <div className='single-col-box'>
        <div className='header-single-setting-box'>
          <h4>Domain</h4>
          <p>This detail will be appearing on the website</p>
        </div>
        <InputField label='' name='name' value={business?.Domain || ""}/>
        
      </div>
      <div className='single-col-box'>
        <div className='header-single-setting-box'>
          <h4>Street Address</h4>
        </div>
        <InputField label='' name='name' value={business?.Address?.Address || ""}/>
        
      </div>
      <div className='single-col-box'>
        <div className='header-single-setting-box'>
          <h4>City</h4>
        </div>
        <InputField label='' name='name' value={business?.Address?.City || ""}/>
        
      </div>
      <div className='single-col-box'>
        <div className='header-single-setting-box'>
          <h4>Country</h4>
        </div>
        <InputField label='' name='name' value={business?.Address?.Country || ""}/>  
      </div>


      <div>
        <Button label={"Save Changes"} loading={false} />
      </div>
    </div>
  )
}
