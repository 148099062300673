import React from 'react'
import { Reveal } from '../../../../Components/Revel/Revel'
import { Slider } from 'rsuite'
import { BillingHistory, Company } from '../../../../API'
import Moment from 'react-moment';
import { formatCurrency, formatTimestamp } from '../../../../Components/HelperFunctions/HelperFunctions';
import "./style.css";
import { useOutletContext } from 'react-router-dom';

interface DashboardContext {
  business: Company | null;
}

export const Subscription = () => {

  const {business} = useOutletContext<DashboardContext>();
  return (
    <>
      <div className="row p-3 pb-0 mt-2">
        <div className="col-sm-8 col-lg-6 mb-4 child-div-full-width">
          <div className="card card-border-shadow-success">
            <div className="card-body">
              <div className="d-flex align-items-center pb-1"> 
                <h4 className="mb-0 fs-5">Current Plan Usage</h4>
              </div>
              <div className='mt-3 progress-bar-current-plan'>
                <div>
                  <div className='section-header-progress-dashboard'>
                    <small>Active Carrier Users</small>

                    <span>{business?.CurrentCarrierUsers}/{business?.MaxCarrierUsers}</span>
                  </div>
                  <Slider min={0} max={business?.MaxCarrierUsers || 0} value={business?.CurrentCarrierUsers || 0} progress className='progress-class'/>
                </div>
                <div>
                  <div className='section-header-progress-dashboard'>
                    <small>Active Sourcing Users</small>
                    <span>{business?.CurrentSourcingUsers}/{business?.MaxSourcingUsers}</span>
                  </div>
                  <Slider min={0} max={business?.MaxSourcingUsers || 0} value={business?.CurrentSourcingUsers || 0} progress className='progress-class'/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-8 col-lg-6 mb-4 child-div-full-width">
          <div className="card card-border-shadow-success">
            <div className="card-body">
              <div className="d-flex align-items-center pb-1"> 
                <h4 className="mb-0 fs-5">Upgrade/Downgrade Subscription</h4>
              </div>
              <div className='mt-3 change-sub-box'>
                <p>Adjust your subscription to better match your needs. Choose the plan that works for you.</p>

                <button className='btn btn-secondary'>Request Change</button>
              </div>
            </div>
          </div>
        </div>
      </div>
        
        <div className='billing-history-table'>
          <h3>Billing History</h3>
          <ul>
            {business?.BillingHistories?.items.sort((a:any, b:any) => b?.startPeriod - a?.startPeriod).map((item: BillingHistory | any, index: any) => (
              <li key={index}>
                <div>
                  <p>Subscription Plan - ({formatTimestamp(item.startPeriod)})</p>
                </div>
                <div>
                  {formatCurrency(item.amount)}
                </div>
                <div>
                  <Moment format='MMM D, YYYY'>{item.billingDate}</Moment>
                </div>
                <div>
                  <span className={item.status !== "ACTIVE" ? "bg-danger" : "bg-success"}> {item.status === "ACTIVE" ? <><i className="bi bi-check-lg"></i> Paid</> : <><i className="bi bi-exclamation-triangle"></i> Failed</>}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
  )
}
