import React, {useEffect, useContext, useState} from 'react'
import {useNavigate, Outlet } from 'react-router-dom';
import { AccountContext } from '../../Context/AuthContext';
// import logo from "../../assets/Brand/logo.jpeg";
// import { IUserAttributes } from "../../common/Interfaces/IUserAttributes";
import "./style.css";
import SideBar from './components/SideBar';
import Header from './components/Header';
import { API } from 'aws-amplify';
import { LoadingFull } from '../../Components/PreLoader/Loading';
// import {listBusinesses, listLocations} from "../../graphql/queries";
// import { DatabaseContext } from '../../setup/context-manager/dbContext';
import { CarrierInvitation, Company, Departments } from '../../API';
import { listCompanies } from '../../graphql/queries';
import { ModualPop } from '../../Components/ModulePop/ModulePop';
import { WelcomeUser } from './components/WelcomeUser/WelcomeUser';
import { AccountError } from './components/AccountError/AccountError';
import PaymentErrorPopup from './components/PastDue/PastDue';


export const Dashboard = () => {
  const {isLoading, isAuthenticated, getUser} = useContext(AccountContext);
  //const [group, setGroup] = useState<string>();
  const navigate = useNavigate();
  const [business, setBusiness] = useState<Company | null>(null);
  const [pendingBoxSignUp, setPendingBoxSignUp] = useState<boolean>(false);
  const [pendingAccountMembership, setPendingAccountMembership] = useState<boolean>(false);
  const [passDuePayment, setPassDuePayment] = useState(false);
  // const [locations, setLocations] = useState<Location[]>([]);
  // const {fetchData} = useContext(DatabaseContext);


  useEffect(() => {
    if(!isLoading) {
      if(isAuthenticated) {
        const data = getUser();
        if(data.attributes['custom:accountType'] === "BUSINESS") {
          const signUpStatus = data?.attributes["custom:status"];
          if(signUpStatus === "PENDING") {
            const timeout = setTimeout(() => {
              setPendingBoxSignUp(true);
            }, 500);
            return () => clearTimeout(timeout);
          }
        } else {
          navigate('/carrier');
        }
      } else {
        navigate('/auth/login');
      }
    }
    
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    fetchBB();
  }, []);

  const fetchBB = async () => {
    try {
      const response:any = await API.graphql({query: listCompanies, authMode: "AMAZON_COGNITO_USER_POOLS"});
      if(response != undefined) {
        const myCompany:Company = response.data.listCompanies.items[0];
        if(myCompany != undefined) {
          if(myCompany.SubscriptionStatus === "INACTIVE") {
            setPendingAccountMembership(true);
          } else if (myCompany.SubscriptionStatus === "PAST_DUE") {
            setPassDuePayment(true);
          }
          setBusiness(myCompany);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const addDepartment = (department: Departments) => {
    setBusiness((prev: any) => {
      const updatedDepartments = prev.Departments?.items ? [...prev.Departments.items, department] : [department];
      return {
        ...prev,
        Departments: {
          ...prev.Departments,
          items: updatedDepartments
        }
      };
    });
  }

  const addInvitation = (invitation: CarrierInvitation) => {
    setBusiness((prev: any) => {
      const updatedInvitations = prev.CarrierInvitations?.items ? [...prev.CarrierInvitations.items, invitation] : [invitation];
      return {
        ...prev,
        CarrierInvitations: {
          ...prev.CarrierInvitations,
          items: updatedInvitations
        }
      };
    });
  };

  const removeDepartment = (id: string) => {
    setBusiness((prev: any) => {
      // Filter out the department with the given id
      const updatedDepartments = prev.Departments?.items
        ? prev.Departments.items.filter((department: any) => department.id !== id)
        : [];
  
      return {
        ...prev,
        Departments: {
          ...prev.Departments,
          items: updatedDepartments,
        },
      };
    });
  }

  // const fetchCarrier = async () => {
  //   try {
  //     const response:any = await API.graphql({query: listCompanies, authMode: "AMAZON_COGNITO_USER_POOLS"});
  //     if(response != undefined) {
  //       const myCompany:Company = response.data.listCompanies.items[0];
  //       setBusiness(myCompany);
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  return (
    isLoading ? <LoadingFull text={"Preparing Dashboard"}/>:
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />
          <div className="menu-inner-shadow"></div>
          <div className="layout-page page-dashboard-outlet-box">
            <Header />
            <div className="content-wrapper">
              <Outlet context={{fetchBB, business, addDepartment, addInvitation, removeDepartment}}/>
            </div>
          </div>
        </div>
      </div>

      <ModualPop show={pendingBoxSignUp} child={<WelcomeUser />}/>
      <ModualPop show={pendingAccountMembership} child={<AccountError />} />
      <ModualPop show={passDuePayment} child={<PaymentErrorPopup nextRetry={business?.PaymentNextRetryDate}/>} />
    </>
  );
}

export default Dashboard;