import React from 'react'
import "./style.css";
import { LoadingSpinner } from '../PreLoader/Loading';

interface ButtonProps {
  label?: string;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: () => void;
  checked?: boolean;
  loading: boolean;
  name?: string;
  icon?: any,
  alignCenter?: boolean
}

const Button = (prop: ButtonProps) => {
  return (
    <button className={`primary-button poppins-regular ${prop.className}` } onClick={prop.onClick} type={prop.type} disabled={prop.loading}>
      {prop.loading ? <LoadingSpinner /> : <>{prop.icon} {prop.label}</>}
    </button>
  )
}

export const ToggleButton = (props: ButtonProps) => {
  return (
    <div className="check-box">
      <input type="checkbox" checked={props.checked} onClick={props.onClick} />
    </div>
  )
}

export const CheckBox = (props: ButtonProps) => {
  return (
    <div className="checkbox-wrapper-13" style={{alignItems: props.alignCenter ? 'center' : 'flex-start'}}>
      <input id={props.name} type="checkbox" name={props.name} className='checkbox-field'/>
      <label htmlFor={props.name}>{props.label}</label>
    </div>
  )
}

export const DeleteButton = (prop: ButtonProps) => {
  return (
    <button className={`delete-button poppins-regular ${prop.className}` } onClick={prop.onClick} type={prop.type} disabled={prop.loading}>
      {prop.loading ? <LoadingSpinner /> : prop.label}
    </button>
  )
}

export default Button;