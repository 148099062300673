import React, { useEffect, useState } from 'react'
import { Carrier, Rates, RateType } from '../../../../API';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import noResult from "../../../../assets/img/undraw_No_data_re_kwbl.png";
import InputField, { SelectField } from '../../../../Components/InputField/InputField';
import Button from '../../../../Components/Button/Button';
import { TransportationTypes } from '../../../../Interfaces/ITransportationType';
import { Label } from '@mui/icons-material';
import Moment from 'react-moment';
import "./style.css";
import { ModualPop } from '../../../../Components/ModulePop/ModulePop';
import { API, graphqlOperation } from 'aws-amplify';
import { listRateBatches, listRates } from '../../../../graphql/queries';
import { DeleteRate } from '../components/DeleteRate/DeleteRate';
import { LoadingFull, LoadingSpinner } from '../../../../Components/PreLoader/Loading';
import moment from 'moment';


interface DashboardContext {
  carrier: Carrier | null,
}

const initialFilter: any = {
  originCity: "",
  originCountry: "",
  originRegion: "",
  DestinationCountry: "",
  DestinationRegion: "",
  DestinationCity: "",
  endDate: "",
  startDate: "",
  type: "",
}

export const CarrierRates = () => {
  const navigate = useNavigate();
  const {carrier} = useOutletContext<DashboardContext>();
  const [hideFilter, setHideFilter] = useState(true);
  const [rates, setRates] = useState<Rates[]>([]);
  const [selectCompany, setSelectedCompany] = useState<any>(null);
  const [selectType, setSelectedType] = useState<any>(null);
  const [nextTokenRate, setNextTokenRate] = useState<string | null>(null);
  const [companies, setCompanies] = useState<any>([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [transporation, setTransportationMode] = useState<any>([]);
  const [hasFilterShow, setHasFilterShow] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [currentTabSelected, setCurrentTabSelected] = useState('NEWRATES');
  useEffect(() => {
    if(carrier != null) {
      const data = carrier.CarrierCompanies?.items.map(item => {
        return {value: item?.companyID, label: item?.Name}
      });
      setCompanies(data);
    }
  }, [carrier]);
  
  useEffect(() => {
    const tsModel = TransportationTypes.map(item => {
      return {value: item.type, label: item.label}
    });
  
    setTransportationMode(tsModel);
    fetchRates();
  }, []);

  const fetchRates = async () => {
    try {
      const response:any = await API.graphql({query: listRateBatches, authMode: "AMAZON_COGNITO_USER_POOLS", variables: {limit: 10}});
      if(response != undefined) {
        setRates(response.data.listRateBatches.items);
        //setNextTokenRate(response.data.listRates.nextToken);
        setLoading(false);
      }
    } catch (error) {
      throw error;
    }
  }

  const fetchMoreRates = async () => {
    try {
      const response:any = await API.graphql({
        query: listRates, 
        authMode: "AMAZON_COGNITO_USER_POOLS", 
        variables: {
          limit: 10,
          nextToken: nextTokenRate
        }
      });

      if (response != undefined) {
        const newRates = response.data.listRates.items;
        const newNextToken = response.data.listRates.nextToken;
  
        // Append new rates to the existing ones
        setRates((prevRates: any[]) => [...prevRates, ...newRates]);
        // Update nextToken for the next fetch
        setNextTokenRate(newNextToken);

        
      }

    } catch (error) {
      console.error("Error fetching rates:", error);
      throw error;
    }
  }

  const cleanFilter = (filter: typeof initialFilter) => {
    const cleanedFilter = { ...filter }; // Make a shallow copy
  
    // Remove empty properties
    Object.keys(cleanedFilter).forEach((key) => {
      if (cleanedFilter[key as keyof typeof filter] === "") {
        delete cleanedFilter[key as keyof typeof filter];
      }
    });
  
    return cleanedFilter;
  };

  const filterQuery = async (e: any) => {
    //setNextTokenRate(null);
    e.preventDefault();
    const cleanedFilter = cleanFilter(filter);
    if(selectType) {
      cleanedFilter.tsMode = {eq: selectType.value};
    }
    if(selectCompany) {
      cleanedFilter.companyID = {eq: selectCompany.value};
    }
    if(cleanedFilter.startDate) {
      cleanedFilter.startDate = {gt: cleanedFilter.startDate}
    }
    if(cleanedFilter.endDate) {
      cleanedFilter.endDate = {lt: cleanedFilter.endDate}
    }
    
    try {
      const response:any = await API.graphql({
        query: listRates, 
        authMode: "AMAZON_COGNITO_USER_POOLS", 
        variables: {
          filter: cleanedFilter,
        }});

        //console.log(response);
      if(response != undefined) {
        //console.log(response);
        setRates(response.data.listRates.items);
        setNextTokenRate(response.data.listRates.nextToken);
        // if(response.data.listRates.items.length === 0) {
        //   //filterQuery(e);
        // }
        setLoading(false);
      }
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    if (!hideFilter) {
      setHasFilterShow(true);
    }
  }, [hideFilter]);

  const onChange = (name:any, field:any) => {
    //console.log(name, field);
    setFilter((prev: any) => {
      return {...prev, [name]: field}
    });
  }

  return (
    <>
    <div className="container-xxl flex-grow-1 container-p-y">
      {/* <h4 className="py-3 mb-4">Add User</h4> */}

      <div className="row">
        <div className="card pricing-sheet">
          <div className='d-flex justify-content-between align-items-center header-section-dash-single-row'>
            <div className="card-header">
              <h5 className='mb-1'>My Rates</h5>
              <p>You can only upload rates when you have accepted a carrier's invitation</p>
            </div>
            <div>
              
            </div>
          </div>

          <form className='main-form-box' onSubmit={filterQuery}>
            <div className='search-filed-pricing-sheet'>
              <SelectField list={companies} onChange={setSelectedCompany} label="Company Name" error={""} value={selectCompany} name="company"/>
              <SelectField list={transporation} onChange={setSelectedType} label="Transportation Mode" error={""} value={selectType} name="tsMode"/>
              <InputField onChange={(field) => onChange('startDate', field.startDate)}  label='Start Date' name="startDate" type="date" placeholder='Search by lane id, carrier name'/>
              <InputField onChange={(field) => onChange('endDate', field.endDate)} label='End Date' name="endDate" type="date" placeholder='Search by lane id, carrier name'/>
              <Button label='Filter' loading={false}></Button>
            </div>

            <div className='advance-button-filter' onClick={() => setHideFilter(!hideFilter)}>
              <button className='button-link filter-button' onClick={() => setHideFilter(!hideFilter)} type='button'>Advance Options</button>
              <div className='line-h-long'></div>
              <i className={hideFilter ? 'bi bi-chevron-down transition' : "bi bi-chevron-down transition flip-aero" }></i>
            </div>
            {/* <Select ></Select> */}
            <div className={hideFilter ? hasFilterShow ? "extra-filter extra-filter-hide" : "extra-filter" : hasFilterShow ? "extra-filter show-filter" : ""}>
              <div>
                <div className='header-single-line-filter'>
                  <div className='line-h'></div>
                  <p>Origin Filter</p>
                  <div className='line-h-long'></div>
                </div>
                <div className='origin-box-filter-single-line'>
                  <InputField label='City / Zip' name="originCity" type="text" onChange={(field) => onChange('originCity', field.originCity)}  placeholder=''/>
                  <InputField label='State / Province' name="originRegion" type="text" onChange={(field) => onChange('originRegion', field.originRegion)}  placeholder=''/>
                  <InputField label='Country' name="originCountry" type="text" onChange={(field) => onChange('originCountry', field.originCountry)}  placeholder=''/>
                </div>
              </div>
              <div>
                <div className='header-single-line-filter'>
                  <div className='line-h'></div>
                  <p>Destination Filter</p>
                  <div className='line-h-long'></div>
                </div>
                <div className='origin-box-filter-single-line pb-4'>
                  <InputField label='City / Zip' name="DestinationCity" onChange={(field) => onChange('DestinationCity', field.DestinationCity)}  type="text" placeholder=''/>
                  <InputField label='State / Province' name="DestinationRegion" onChange={(field) => onChange('DestinationRegion', field.DestinationRegion)}  type="text" placeholder=''/>
                  <InputField label='Country' name="DestinationCountry" onChange={(field) => onChange('DestinationCountry', field.DestinationCountry)}  type="text" placeholder=''/>
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>

      
      <div className="row mt-4">
          <div className='tabs-rate-page'>
            <ul>
              <li onClick={() => setCurrentTabSelected("NEWRATES")} className={currentTabSelected === "NEWRATES" ? 'selected-tab-rate' : ''}>Pending Rates</li>
              <li onClick={() => setCurrentTabSelected("APPROVEDRATES")} className={currentTabSelected === "APPROVEDRATES" ? 'selected-tab-rate' : ''}>Approved Rates</li>
              <li onClick={() => setCurrentTabSelected("EXPIREDRATES")} className={currentTabSelected === "EXPIREDRATES" ? 'selected-tab-rate' : ''}>Rejected Rates</li>
              <li onClick={() => setCurrentTabSelected("EXPIREDRATES")} className={currentTabSelected === "EXPIREDRATES" ? 'selected-tab-rate' : ''}>Expired Rates</li>
            </ul>
          </div>
          <div className="card pricing full-width">
            {/* {currentTabSelected === "NEWRATES" && <NewRates newRates={newRates}/>} */}
            {/* {currentTabSelected === "EXPIREDRATES" && <ExpiredRates expiredRates={expiredRates}/>}
            {currentTabSelected === "APPROVEDRATES" && <ApprovedRates approvedRates={approvedRates}/>} */}
        </div>
      </div>
      {/* {isLoading ?  <div className='box-security-token mt-5'> 
          <div className='no-result-found'>
            <LoadingSpinner/>
          </div> 
        </div>  : rates != null && rates.length === 0 ? 
         
          <div className='box-security-token mt-5'> 
              <div className='no-result-found mt-5'>
                <div>
                  <i className="bi bi-paperclip"></i>
                  <h2>No Rate Inquires found</h2>
                  <p>For any questions or information regarding rates, please contact your carrier directly. They will be able to provide you with the most accurate and up-to-date details.</p>
                  <Link to="/">Learn More <i className="bi bi-arrow-right"></i></Link>
                </div>
              </div> 
        </div> : <div className="row mt-4">
        <div className="card pricing-sheet">
          <div className='d-flex justify-content-between align-items-center header-section-dash-single-row'>
          <table className='table-of-items activity-table rate-carrier-portal-table'>
            <thead>
              <th>Origin <i className="bi bi-arrow-down"></i></th>
              <th>Destination <i className="bi bi-arrow-down"></i></th>
              <th>Rate Type</th>
              <th>Rate Duration</th>
              <th></th>
            </thead>
            <tbody>
              {rates.map((item: any, index) => {
                const start = moment(item.startDate).utcOffset(0).format('DD MMM YY');
                const end = moment(item.endDate).utcOffset(0).format('DD MMM YY');
                return (
                  <tr key={index}>
                    <td>
                      <div className='d-flex gap-1 navigation-aero-rate-table'>
                        {item.originCity} <i className="bi bi-arrow-right"></i> {item.originProvince}  <i className="bi bi-arrow-right"></i> {item.originCountry}
                      </div>
                    </td>
                    <td>
                      <div className='d-flex gap-1 navigation-aero-rate-table'>
                      {item.destinationCountry}  <i className="bi bi-arrow-right"></i> {item.destinationProvince} <i className="bi bi-arrow-right"></i> {item.destinationCity} 
                      </div>
                    </td>
                    <td>{item.tsMode}</td>
                    <td>
                      <div>
                        {start} - {end}
                      </div>
                    </td>
                    <td className='last-table-col'>
                      <div className='d-flex gap-2 end-table-col'>
                        <button className='btn btn-danger' onClick={() => setConfirmDelete(true)}><i className="bi bi-trash3"></i></button>
                        <button className='btn primary-color' onClick={() => navigate(`/carrier/my-rates/${item.id}`)}><i className="bi bi-pencil-square"></i></button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          </div>
        </div>
      </div>}

      {!isLoading && nextTokenRate != null && 
        <div className='load-more-rates-carrier-portal'>
          <button className='btn primary-color gap-2' onClick={() => fetchMoreRates()}><i className="bi bi-three-dots"></i> LOAD MORE RATES</button>
        </div>
      } */}
    </div>
    <ModualPop show={confirmDelete} child={<DeleteRate setConfirmDelete={setConfirmDelete}/>}/>
    </>
  )
}
