import React, { useState } from 'react'
import { Header } from './components/Header'
import "./style.css";
import { Link, Outlet } from 'react-router-dom';

import { Footer } from './components/Footer';
export const Main = () => {

  
  return (
    <div className='main-page'>
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}
