import React from 'react';
// Replace with your actual Button componen
import "../AccountError/style.css";
import Button from '../../../../Components/Button/Button';
import { useNavigate } from 'react-router-dom';


const PaymentErrorPopup = (prop: any) => {
  const navigate = useNavigate();

  
  const convertTimestampToDate = (timestamp: any):string => {
    // Convert the timestamp to milliseconds and create a new Date object
    const date = new Date(timestamp * 1000);
    // Format the date to a readable string
    return date.toLocaleString(); // You can customize the format as needed
  };

  return (
    <div className='container-pop-up-box-error-account'>
      <i className="bi bi-exclamation-triangle"></i>
      <div>
        <h2>Action Required: Payment Issue</h2>
        <p>We couldn't process your payment, which has affected your account access. Please review your payment details and try again to regain full access.</p>
        <span className='bg-label-danger p-2 mt-3'>Next Payment Retry: {convertTimestampToDate(prop.nextRetry)}</span>
      </div>
      
      <Button loading={false} label='Manage Membership' onClick={() => navigate('/process_payment_success')}></Button>
    </div>

  );
};

export default PaymentErrorPopup;
