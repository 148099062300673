import { Rates, RateType } from "../../API";
import { GLOBAL_RATE_TYPE } from "../../Interfaces/IRateForm";
import { FTLGenerator_Rate_Geo } from "../TSModes/FTL/FTLGenerator_Rate_Geo";
import { FTLGenerator_Rate_Geo_Cost } from "../TSModes/FTL/FTLGenerator_Rate_Geo_Cost";
import { FTLGenerator_Rate_Geo_Cost_Group } from "../TSModes/FTL/FTLGenerator_Rate_Geo_Cost_Group";
import { FTLGenerator_Rate_Offering } from "../TSModes/FTL/FTLGenerator_Rate_Offering";
import { FTLGenerator_X_Lane } from "../TSModes/FTL/FTLGenerator_X_Lane";

export interface ICSVGenerator {
  generateCsv(data: Rates[], companyData: any): string;
  getFileName(): string;
}

export class CSVGeneratorFactory {
  static getGenerator(tsMode: RateType): ICSVGenerator[] {
    switch (tsMode) {
      case RateType.FTL:
        return [
          new FTLGenerator_X_Lane(),
          //new FTLGenerator_Rate_Offering(),
          //new FTLGenerator_Rate_Geo(),
          new FTLGenerator_Rate_Geo_Cost_Group(),
          // new FTLGenerator_Rate_Geo_Cost(),
        ];
      // case "LTL":
      //   return [
      //     new LTLCSVGenerator(),
      //   ];
      // Add cases for other modes
      default:
        throw new Error("Unsupported tsMode");
    }
  }
}
