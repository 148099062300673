import React, { useContext } from 'react'
import "./style.css";
import { AccountContext } from '../../../../Context/AuthContext';
import { Reveal } from '../../../../Components/Revel/Revel';
import Button from '../../../../Components/Button/Button';
import {useNavigate } from 'react-router-dom';

export const WelcomeUser = () => {
  const navigation = useNavigate();
  const {isLoading, isAuthenticated, getUser} = useContext(AccountContext);

  return (
    <div className='welcome-box-container'>
      <Reveal delay={3}>
        <h3 className='header-name-welcome-box'>Welcome to RateGenie</h3>
      </Reveal>
      <Reveal delay={1}>
        <h1>Hello, <span className='name-captilization'>{isAuthenticated && getUser().attributes.name}</span>! 👋</h1>
      </Reveal>
      <Reveal delay={2}>
        <p className='text-info-welcome-user'>
          We're glad to have you here! There are a few more pieces of information we need before you can start using the app to its full potential.
        </p>  
      </Reveal>

      <Reveal delay={4}>
        <Button label="Get Started" loading={false} onClick={() => navigation("/finish-account-setup")}/>
      </Reveal>
  </div>
  )
}
