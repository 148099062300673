import React from 'react'
import Button from '../../../../Components/Button/Button'
import noResult from "../../../../assets/img/undraw_No_data_re_kwbl.png";
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { Carrier, Status } from '../../../../API';
import Moment from 'react-moment';
import "./style.css";

interface DashboardContext {
  carrier: Carrier | null;
}

export const CarrierInvite = () => {
  const navigate = useNavigate();
  const {carrier} = useOutletContext<DashboardContext>();

  return (
    <>
    <div className="container-xxl flex-grow-1 container-p-y">
      {/* <h4 className="py-3 mb-4">Add User</h4> */}

      <div className="row">
        <div className="card pricing-sheet">
          <div className='d-flex justify-content-between align-items-center header-section-dash-single-row'>
            <div className="card-header">
              <h5 className='mb-1'>Carrier Invitation</h5>
              <p>You can only upload rates when you have accepted a carrier's invitation</p>
            </div>
            <div>
              <Button loading={false} label='' icon={<i className="bi bi-arrow-clockwise"></i>}></Button>
            </div>
          </div>
        </div>
      </div>

     

      {carrier?.CarrierInvitations != null && carrier?.CarrierInvitations?.items.filter(item => item?.status === Status.PENDING).length > 0 ?  <div className="row mt-4">
        <div className="card pricing-sheet">
          <div className='d-flex justify-content-between align-items-center header-section-dash-single-row'>
          <table className='table-of-items activity-table carrier-invatation'>
                <thead>
                  <th>Invitation <i className="bi bi-arrow-down"></i></th>
                  <th>Created Date</th>
                  <th></th>
                </thead>
                <tbody>
                  {carrier?.CarrierInvitations?.items.filter(item => item?.status === Status.PENDING).map((item, index) => (
                    <tr key={index}>
                      <td>{item?.CompanyName}</td>
                      <td><Moment format='DD MMM YYYY @ HH:MM'>{item?.createdAt}</Moment></td>
                      <td style={{width: '100% !important'}}>
                        <div className='invitation-action-button-carrier-portal'>
                          <button className='btn-success' onClick={() => navigate(`/carrier-invitation-portal/${item?.id}`)}>Accept Invitation</button>
                          <button className='btn-danger' onClick={() => navigate(`/carrier-invitation-portal/${item?.id}`)}>Reject Invitation</button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> 
          </div>
        </div>
      </div> : <div className='box-security-token mt-5'>
        <div className='no-result-found mt-5'>
            <div>
              <i className="bi bi-people"></i>
              <h2>No Carriers Invited</h2>
              <p> You haven't invited any carriers yet. To start working with carriers, send out invitations from your dashboard.</p>
              <Link to="/">Learn More <i className="bi bi-arrow-right"></i></Link>
            </div>
          </div> 
        </div>}
    </div>
    </>
  )
}
