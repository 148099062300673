import React from 'react'
import Card from '../components/Card'
import { Reveal } from '../../../../Components/Revel/Revel'
import { Carrier, Status } from '../../../../API';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Moment from 'react-moment';
import noResult from "../../../../assets/img/undraw_No_data_re_kwbl.png";


interface DashboardContext {
  carrier: Carrier | null;
  rateStats: any
}

export const CarrierDashboard = () => {
  const navigate = useNavigate();
  const {carrier, rateStats} = useOutletContext<DashboardContext>();
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <h4 className="py-3 mb-4">Welcome to Carrier Portal</h4>
      <div className="row">
        <Card totalCompanies={carrier?.CarrierCompanies?.items.length} rateStats={rateStats}/>
      </div>

      {/* <div className="row mb-4">
        <div className='card'>
          <h5 className="card-header">User List</h5> 
        </div>
      </div> */}

      <div className="row child-div-full-width">
        <Reveal delay={.4}>
          <div className='card'>
            <h5 className="card-header">Company List</h5> 

            {carrier?.CarrierCompanies != null && carrier?.CarrierCompanies?.items.length > 0 ?

            <div className='d-flex justify-content-between align-items-center header-section-dash-single-row'>
            <table className='table-of-items activity-table carrier-invatation'>
                <thead>
                  <th>Company <i className="bi bi-arrow-down"></i></th>
                  <th>Created Date</th>
                  <th></th>
                </thead>
                <tbody>
                  {carrier?.CarrierCompanies?.items.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.Name}</td>
                      <td><Moment format='DD MMM YYYY @ HH:MM'>{item?.createdAt}</Moment></td>
                      {/* <td style={{width: '100% !important'}}>
                        <div className='invitation-action-button-carrier-portal'>
                          <button className='btn-success' onClick={() => navigate(`/carrier-invitation-portal/${item?.id}`)}>Accept Invitation</button>
                          <button className='btn-danger' onClick={() => navigate(`/carrier-invitation-portal/${item?.id}`)}>Reject Invitation</button>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table> 
            </div> : <div className='box-security-token mb-5 mt-3'> 
          <div className='no-result-found'>
            <div>
              <img src={noResult} width={150}/>
              <h2>No Companies found</h2>
              <p>Please contact the company's sourcing cleark to send you an invitation.</p>
            </div>
          </div> 
        </div>}
          </div>
        </Reveal>
      </div>

    </div> 
  )
}
