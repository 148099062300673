import React, { useContext } from 'react'
import { Login } from './Pages/Auth/Login/Login';
import {Navigate, Route, Routes} from "react-router-dom";
import TokenLogin from './Pages/Auth/TokenLogin';
import { Auth } from './Pages/Auth/Auth';
import { Register } from './Pages/Auth/Register/Register';
import { ForgotPassword } from './Pages/Auth/Forgot-Password/Forgot-Password';
import { VerifyAccount } from './Pages/Auth/Verify-Account/VerifyAccount';
import { AccountContext } from './Context/AuthContext';
import Dashboard from './Pages/backend/Dashboard';
import './assets/css/core.css';
import './assets/css/theme-default.css';
import "./App.css";
import { Main } from './Pages/frontend/Main';
import { DashboardMain } from './Pages/backend/DashboardMain/DashboardMain';
import { PricingSheets } from './Pages/backend/PricingSheets/PricingSheets';
import { ModualPop } from './Components/ModulePop/ModulePop';
import LogoutPopup from './Pages/backend/components/LogoutPopup/LogoutPopup';
import { UserList } from './Pages/backend/UserList/UserList';
import { OTMManagement } from './Pages/backend/OTMManagement/OTMManagement';
import { FinishAccountSetup } from './Pages/backend/FinishAccountSetup/FinishAccountSetup';
import { CarrierLogin } from './Pages/Auth/CarrierLogin/CarrierLogin';
import { Carriers } from './Pages/backend/Carriers/Carriers';
import ProtectedRoutes from './Components/ProtectedRoute/ProtectedRoutes';
import { CarrierPortal } from './Pages/backend/CarrierPortal/CarrierPortal';
import { CarrierDashboard } from './Pages/backend/CarrierPortal/Pages/CarrierDashboard';
import { CarrierRates } from './Pages/backend/CarrierPortal/Pages/CarrierRates';
import { CarrierInvite } from './Pages/backend/CarrierPortal/Pages/CarrierInvite';
import { FinishCarrierAccount } from './Pages/backend/FinishAccountSetup/FinishCarrierAccount';
import { CarrierInvitationPortal } from './Pages/backend/CarrierInvitationProtal/CarrierInvitationPortal';
import { ProtectedCompanyRoute } from './Components/ProtectedRoute/ProtectedCompanyRoute';
import { ProtectedCarrierRoute } from './Components/ProtectedRoute/ProtectedCarrierRoute';
import { ViewRate } from './Pages/backend/CarrierPortal/Pages/ViewRate';
import { ProcessPaymentSuccess } from './Pages/backend/ProcessPaymentSuccess/ProcessPaymentSuccess';
import { ViewRatesSingle } from './Pages/backend/PricingSheets/ViewRatesSingle';
import { Settings } from './Pages/backend/Settings/Settings';
import { General } from './Pages/backend/Settings/tabs/General';
import { Subscription } from './Pages/backend/Settings/tabs/Subscription';
import { Security } from './Pages/backend/Settings/tabs/Security';
import { Billing } from './Pages/backend/Settings/tabs/Billing';
import { Home } from './Pages/frontend/Home/Home';

const App = () => {
  const {isAuthenticated, isLoading, getUser, getUserProfile, isLoggingOut, user} = useContext(AccountContext);
  return (
    <>
      <Routes>
        
        <Route path="/auth" element={<Auth />}>
          <Route path="login" element={<Login />}/> 
          <Route path="token-authentication/:token" element={<TokenLogin />}/> 
          <Route path="register" element={<Register/>}></Route>
          <Route path="forgot-password" element={<ForgotPassword />}></Route>
          <Route path="verify-account" element={<VerifyAccount />}></Route>
          <Route path="carrier/login" element={<CarrierLogin />} />
        </Route>
      
        <Route path='/' element={<Main />}>
          <Route index element={<Home />} />
          <Route path="pricing" element={<h1>Pricing</h1>} />
          <Route path="resources" element={<h1>Re</h1>} />
          <Route path="products" element={<h1>Pr</h1>} />
          <Route path="faq" element={<h1>Faq</h1>} />
        </Route>

        <Route element={<ProtectedRoutes />}>
          <Route element={<ProtectedCompanyRoute/>}>
            <Route path="/finish-account-setup" element={<FinishAccountSetup />} />
            <Route path="/process_payment_success" element={<ProcessPaymentSuccess />} />
            <Route path="/dashboard" element={<Dashboard />}>
              <Route index element={<DashboardMain />}/>
              
              <Route path="pricing-sheets" element={<PricingSheets />} />
              <Route path="pricing-sheets/new-rates/:id" element={<ViewRatesSingle />}/>
              <Route path="carriers" element={<Carriers />}/>
              <Route path="otm-management" element={<OTMManagement />} />
              
              <Route path="user-list" element={<UserList />}/>

              <Route path="settings" element={<Settings />}>
                <Route index element={<General />} />
                <Route path="subscription" element={<Subscription />} />
                <Route path="security" element={<Security />} />
                <Route path="billing" element={<Billing />} />
              </Route>
            </Route>
          </Route>


          <Route element={<ProtectedCarrierRoute/>}>
            <Route path="/carrier" element={<CarrierPortal />}>
              <Route index element={<CarrierDashboard/>} />
              <Route path="my-rates" element={<CarrierRates/>} />
              <Route path="my-rates/:id" element={<ViewRate />} />
              <Route path="invites" element={<CarrierInvite />} />
            </Route>

            <Route path="/carrier-account-completion" element={<FinishCarrierAccount />} />
            <Route path="/carrier-invitation-portal/:id" element={<CarrierInvitationPortal />} />
          </Route>
        </Route>
      </Routes>

      <ModualPop show={isLoggingOut} child={<LogoutPopup />}/>
    </>
  )
}

export default App;