import React, { useState } from 'react'
import { Company, RateBatch } from '../../../../API';
import InputField, { SelectField } from '../../../../Components/InputField/InputField';
import "./style.css";

interface IGeneratePop {
  setIsGenerating: (data: boolean) => void
  business: Company | null;
  currentSelectedRate: RateBatch | null,
  departmentsList: DepartmentOption[],
  generateRates: (data: any, companyData: any) => void
}

interface DepartmentOption {
  value: string;
  label: string;
}

export const GeneratePop = (prop: IGeneratePop) => {
  const [selectedOption, setSelectedOption] = useState<DepartmentOption | null>(null);
  
  const submitGeneration = () => {
    const company = {
      domain: prop.business?.Domain,
      subDomain: selectedOption?.value,
      tsMode: prop.currentSelectedRate?.tsMode,
    }
    prop.generateRates(prop.currentSelectedRate, company);
  }

  return (
    <div className='accept-box-container-rate'>
      <h1 className='mb-2'>Generate CSV</h1>
      <p>After accepting the rate, the carrier will be notified and the rates will added to the accepted column. This further gives you the ability to download them or import them to OTM directly.</p>
      
      <section className='input-field-container-generator'>
        <SelectField list={prop.departmentsList} label={"Department (Sub Domain)"} error={""} name={""} onChange={setSelectedOption} value={selectedOption}/>
        <ul className='user-view-list'>
          <li>
            <span>Domain</span>
            <p>{prop.business?.Domain}</p>
          </li>
          <li>
            <span>Transport Type</span>
            <p>{prop.currentSelectedRate?.tsMode}</p>
          </li>
          <li>
            <span>Total Rates</span>
            <p>{prop.currentSelectedRate?.Rates?.items.length}</p>
          </li>
          <li>
            <span>SCAC</span>
            <p>{prop.currentSelectedRate?.scac}</p>
          </li>
        </ul>
      </section>
      
      <div>
        <button className='btn btn-success' onClick={() => submitGeneration()}>Generate</button>
        <button className='btn btn-secondary' onClick={() => prop.setIsGenerating(false)}>Cancel</button>
      </div>
    </div> 
  )
}
