import React, { useContext, useEffect, useState } from 'react';
import { Route, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../Context/AuthContext';
import { LoadingFull } from '../PreLoader/Loading';

export const ProtectedCarrierRoute = () => {
  const navigate = useNavigate();
  const {isAuthenticated, getUser, isLoading} = useContext(AccountContext);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if(!isLoading) {
      onLoad();
    }
  }, [isLoading, isAuthenticated]);

  const onLoad = () => {
    const userData = getUser();
    if(userData != null) {
      const userGroup = userData.attributes['custom:accountType'];
      if(userGroup === "CARRIER") {
        setLoading(false);
      } else {
        navigate("/dashboard");
      }
    } else {
      navigate("/");
    }    
  }

  return loading ? <LoadingFull text="Please Wait..."/> : <Outlet />;
}
