import React from 'react'
import "./style.css";
import { Status } from '../../../../API';
import { STATUS } from 'rsuite/esm/Animation/Transition';

interface IAcceptRate {
  onRateAction: (status: Status, comment: "") => void,
  setModalAccept: (show: boolean) => void,
}

export const AcceptRate = (prop: IAcceptRate) => {
  return (
    <div className='accept-box-container-rate'>
      <h1>Accept Rate</h1>
      <p>After accepting the rate, the carrier will be notified and the rates will added to the accepted column. This further gives you the ability to download them or import them to OTM directly.</p>

      <div>
        <button className='btn btn-success' onClick={() => prop.onRateAction(Status.ACCEPTED, "")}>Confirm Accept</button>
        <button className='btn btn-secondary' onClick={() => prop.setModalAccept(false)}>Do it later</button>
      </div>
    </div>
  )
}
