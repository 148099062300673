import { API } from 'aws-amplify';
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getRates } from '../../../../graphql/queries';
import { GLOBAL_RATE_TYPE } from '../../../../Interfaces/IRateForm';
import "./style.css";
import { ITransportationType, TransportationTypes, TransportField } from '../../../../Interfaces/ITransportationType';
import InputField from '../../../../Components/InputField/InputField';

export const ViewRate = () => {
  const navigation = useNavigate();
  const [rateViewLayout, setRateViewLayout] = useState<TransportField[]>([]);
  const [rateData, setRateData] = useState<any | null>(null);
  const {id} = useParams();
  
  useMemo(async () => {
    // try {
    //   const response:any = await API.graphql({query: getRates, authMode: "AMAZON_COGNITO_USER_POOLS", variables:{id: id}});
    //   console.log(response);
    //   if(response != undefined) {
    //     const rate = response.data.getRates;
    //     const RateTransportationTypeObj = TransportationTypes.find((item: ITransportationType) => item.type === rate.tsMode);
    //     if(RateTransportationTypeObj != undefined) {
    //       console.log(RateTransportationTypeObj.fields)
    //       setRateViewLayout(RateTransportationTypeObj.fields);
    //     }
    //     setRateData(JSON.parse(rate.data));
    //   }
    // } catch(e: any) {
    //   console.log(e);
    // }
  }, [id]);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="card pricing-sheet">
          <div className='d-flex justify-content-between align-items-center header-section-dash-single-row'>
            <div className='d-flex justify-content-between align-items-center'>
              <button className='btn btn-light' onClick={() => navigation('/carrier/my-rates')}><i className="bi bi-arrow-left"></i></button>
              <div className="card-header">
                <h5 className='mb-1'>My Rate</h5>
                <p>You can only upload rates when you have accepted a carrier's invitation</p>
              </div> 
            </div>

            <div className='gap-3 d-flex justify-content-between align-items-center'>
              <button className='btn btn-danger poppins-regular'>Delete</button>
              <button className='btn primary-color poppins-regular'>Update Rate</button>
            </div>
          </div> 
        </div>
      </div>

      <div className="row mt-4">
        <div className="card card-box">
          <div className='d-flex justify-content-between align-items-center header-section-dash-single-row'>
            <div className='d-flex gap-4 main-top-field field-box'>
              {rateViewLayout.map((item, index) => (
                <InputField
                  key={index}
                  type={item.type}
                  label={item.label}
                  disabled={!item.editable}
                  name={item.objectKey}
                  value={rateData[item.objectKey]}
                  // error={errors[`${rowIndex}-${field.objectKey}`] || ''}
                  // onChange={(data) => handleChange(rowIndex, field.objectKey, data[field.objectKey])}
                />
              ))} 
            </div>
          </div>
        </div>
      </div>
    </div>
        
  )
}
