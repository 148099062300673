import React, {useState, useContext} from 'react'
import InputField from '../../../Components/InputField/InputField'
import "./style.css";
import Button from '../../../Components/Button/Button';
import { Link } from 'react-router-dom';
import { AccountContext } from '../../../Context/AuthContext';
import { useNavigate} from 'react-router-dom';
import { Reveal } from '../../../Components/Revel/Revel';
import logo from "../../../assets/img/logo.png";
import { ResetPassword } from './ResetPassword';
import { toast } from 'react-toastify';


const INITIAL_ERROR = {
  email: '',
}

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<any>(INITIAL_ERROR);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const {forgotPassword} = useContext(AccountContext);
  const [confirmReset, setConfirmReset] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');

  const onSubmit = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    setError(INITIAL_ERROR);

    const data = new FormData(e.target);
    const email = Object.fromEntries(data.entries()).email;

   
    if(email !== "") {
      try {
        const res = await forgotPassword(email);
        setConfirmReset(true);
        setUserEmail(email.toString());
        toast.success(`Email sent successfully to ${email}`, {toastId: "EMAILSENT"});
        e.target.reset();
      } catch (e: any) {  
        if(e.code === "UserNotFoundException") {
          setError({email: "The email address was not found"});
        }

        if(e.code === "LimitExceededException") {
          setError({email: e.message});
        }

        if(e.code === "NotAuthorizedException") {
          setError({email: e.message});
          // setMessage((prev) => {
          //   return {...prev, form: "User password cannot be reset in the current state. Please contact Technical Support for further help.", type: "ERROR", header: "Unexpected Error"}
          // });
        }
      }
    } else {
      if(email === "") {
        setError((prev: any) => {
          return {...prev, email: "Enter a valid email"}
        });
      }
    }

    setFormSubmitted(false);
  }

  return (
    <div className='logo-container'>
      <img src={logo} width={130} /> 
      {!confirmReset ? <Reveal>
        <div className='login-form-container'>
          <div className='login-header'>
            <h1 className='poppins-regular'>Reset your password</h1>
            <p className='poppins-light'>Enter the email address associated with your account, and we'll send you a link to reset your password.</p>
          </div>
          <form onSubmit={onSubmit} className='login-form'>
            <InputField name='email' label='Email' type='email' error={error.email}/>
            <Button label='Continue' type='submit' loading={formSubmitted} className='mt-2'/>
            <Link className="text-link return-back-link" to="/auth/login">Return to sign in</Link>
          </form>
          <div className='bottom-login-form'> 
            <p className="poppins-light">New to Rate Genie? <Link className="text-link" to="/auth/register">Create an Account</Link></p>
          </div>
        </div>
      </Reveal> : <ResetPassword userEmail={userEmail}/>}
    </div>
  )
}
