import React, { useContext, useEffect, useState } from 'react'
import { AccountContext } from '../../../Context/AuthContext'
import { useNavigate, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { listCarriers } from '../../../graphql/queries';
import { Carrier, CarrierInvitation, Status } from '../../../API';
import { LoadingFull, LoadingSpinner } from '../../../Components/PreLoader/Loading';
import "./style.css";
import { Reveal } from '../../../Components/Revel/Revel';
import Button from '../../../Components/Button/Button';

export const CarrierInvitationPortal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(true);
  const {isLoading, isAuthenticated, getUser} = useContext(AccountContext);
  const [currentInvitation, setCurrentInvitation] = useState<CarrierInvitation | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [submissionComplete, setSubmissionComplete] = useState<boolean>(false);
  const [carrier, setCarrier] = useState<Carrier | undefined>();
  useEffect(() => {
    const onLoad = async () => {
      if(!isLoading) {
        if(isAuthenticated) {
          const data = getUser();
          if(data.attributes['custom:accountType'] === "CARRIER") {
            const invitationId = id;
            const invitations:Carrier | undefined = await fetchCarrier();
            setCarrier(invitations);
            if(invitations != undefined) {
              const findItem = invitations.CarrierInvitations?.items.find(item => item?.id === invitationId);
              if(findItem !== undefined && findItem?.status === Status.PENDING) {
                setCurrentInvitation(findItem);
                setPageLoading(false);
              } else {
                navigate("/carrier");
              }
            }
          } else {
            navigate("/");
          }
        } else {
          navigate("/auth/carrier/login");
        }
      }
    }

    onLoad();
   }, [isLoading, isAuthenticated]);


  const fetchCarrier = async () => {
    try {
      const response:any = await API.graphql({query: listCarriers, authMode: "AMAZON_COGNITO_USER_POOLS"});
      if(response != undefined) {
        const carrier:Carrier = response.data.listCarriers.items[0];
        return carrier;
      }
    } catch (error) {
      throw error;
    }
  };


  const onSubmit = async (status: Status) => {
    setFormSubmitted(true);
    try {
      const user = getUser();
      const userSession = user.signInUserSession;
      const idToken = userSession.idToken.jwtToken;
      //console.log(carrier);
      const itm = await fetch("https://vsqwc4gr9f.execute-api.us-east-1.amazonaws.com/Test/company/invitation-action",
        {
          mode: "cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": idToken,
          },

          body: JSON.stringify({
            status: status,
            invitationID: currentInvitation?.id,
            companyID: currentInvitation?.companyID,
            carrierID: currentInvitation?.carrierID,
            companyName: currentInvitation?.CompanyName,
            companyEmail: currentInvitation?.InviterEmail,
            carrierName: carrier?.ServiceName,
            scac: carrier?.SCAC,
          }),
      });
      const data = await itm.json();
      if(data.statusCode === 200) {
        setSubmissionComplete(true);
      }
      //console.log(data);
    } catch(e) {
      console.log(e);
    }   
    setFormSubmitted(false);
  }

  return (
    pageLoading ? <LoadingFull text="Preparing Invitation"/> : 
    <div className='full-page-container-invitation'>
      {!submissionComplete ? <Reveal>
        <>
          <div className='d-absolute'>
            <button className='btn btn-secondary' onClick={() => navigate("/carrier/invites")}>Back</button>
          </div>
          <div className='box-container-small-invitation'>
            <div className='icon-box-container-invitation'>
              <i className="bi bi-envelope-paper"></i>
            </div>
            <h2 className='invitation-header'>Do you want to accept the invitation?</h2>
            <p className='invitation-message'>By accepting this invitation, you will be granted the privilege to submit rates directly to the company, allowing you to contribute valuable insights and information.</p>
            <p className='mb-4 company-name-highlight'>Company <div>{currentInvitation?.CompanyName}</div></p>
            <div className='full-width d-flex action-button-invitation'>
              <button className='btn btn-success' onClick={() => onSubmit(Status.ACCEPTED)}>Accept Invitation</button>
              <button className='btn btn-danger' onClick={() => onSubmit(Status.REJECTED)}>Reject Invitation</button>
            </div>

            {formSubmitted && <div className='overlap-loading-box'><LoadingSpinner/><p>Processing action...</p></div>}
          </div>
        </>
      </Reveal> : <Reveal>
        <div className='box-container-small-invitation'>
          <div className='icon-box-container-invitation'>
            <i className="bi bi-envelope-paper"></i>
          </div>
          <h2 className='invitation-header'>Thank you</h2>
          <p className='invitation-message'>Your submission has been successfully processed and will be updated on your account shortly. Thank you for your patience as we ensure everything is accurately reflected.</p>
          <div className='full-width d-flex action-button-invitation'>
            <Button loading={false} label="Go to Dashboard" onClick={() => navigate("/carrier")}></Button>
          </div>
        </div>
      </Reveal>}
    </div>
  )
}
