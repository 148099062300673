import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../Context/AuthContext';
import { Reveal } from '../../../Components/Revel/Revel';
import InputField from '../../../Components/InputField/InputField';
import Button, { CheckBox } from '../../../Components/Button/Button';
import logo from "../../../assets/img/logo.png";
import { CreateNewPassword } from '../CreateNewPassword/CreateNewPassword';
import { CognitoUser } from '@aws-amplify/auth';
import { toast } from 'react-toastify';


const INITIAL_ERROR = {
  email: '',
  password: '',
}

export const CarrierLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState<any>(INITIAL_ERROR);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const {login, getSession, isAuthenticated, logoutInvalidUser, completeUserPassword} = useContext(AccountContext);
  const [loginMessage, setLoginMessage] = useState<any>(null);
  const [tempUser, setTempUser] = useState<CognitoUser>();
  const [createNewPassword, setCreateNewPassword] = useState<boolean>(false);
  useEffect(() => {
    const onLoad = async () => {
      if(location.state != null) {
        if(location.state.response === "INVALID_USER") {
          setLoginMessage({type: "W", message:"The account your signed into is not a carrier account. Please use the regular login page to access your account."});
        }
      }
    }

    onLoad();
  }, [location.state, navigate]);

  const onSubmit = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    setError(INITIAL_ERROR);

    const data = new FormData(e.target);
    const email = Object.fromEntries(data.entries()).email;
    const password = Object.fromEntries(data.entries()).password;
    //const remember = Object.fromEntries(data.entries()).remember;

   
    if(email !== "" && password !== "") {
      try {
        const response = await login(email, password);  
        if(response.challengeName === "NEW_PASSWORD_REQUIRED") {
          setTempUser(response);
          setCreateNewPassword(true);
        } else {
          if(response.attributes['custom:accountType'] === "CARRIER") {
            if(response.attributes['custom:status'] === "PENDING") {
              navigate('/carrier-account-completion');
            } else {
              //console.log(location.state);
              await getSession();

              if(location.state?.from) {
                navigate(location.state.from);
              } else {
                navigate('/carrier');
              }
            }
          } else {
            await logoutInvalidUser();
            navigate("/auth/carrier/login", {state: {response: "INVALID_USER"}});
          } 
        }
      } catch(e: any) {
        if(e.code === "UserNotFoundException" || e.code === "NotAuthorizedException") {
          setError({
            password: "Incorrect email or password"
          });
        }

        if(e.code === "UserNotConfirmedException") {
          navigate('/auth/verify-account', {state: {username: email, userConfirmed: false, from: "LOGIN"}});
          // toast.info("Account needs to be verified before login in.", {toastId: "REMOVE_EXISTING_USER"});
        }
      }
    } else {
      if(email === "") {
        setError((prev: any) => {
          return {...prev, email: "Enter a valid email"}
        });
      }
      
      if(password === "") {
        setError((prev: any) => {
          return {...prev, password: "Please enter your password"}
        });
      }
      
    }

    setFormSubmitted(false);
  }


  const updateUserPassword = async (password: string) => {
    try {
      const res = await completeUserPassword(tempUser, password, []);
      await getSession();
      navigate('/carrier');
    } catch(e: any) {
      console.log(e);
      if(e.code === "NotAuthorizedException") {
        logoutInvalidUser();
        setCreateNewPassword(false);
        toast.error("An unexpected error occured, please login and try again.", {toastId: "REMOVE_EXISTING_USER"});
      }
      // setChangePasswordMessage((prev) => {
      //   return {...prev, form: e.message, type: "ERROR", header: "Error"}
      // });
    }
  }


  return (
    <div className='logo-container'>
      <img src={logo} width={130} /> 
      <Reveal>
        {!createNewPassword ? <div className='login-form-container'>
          <div className='login-header'>
            <h1 className='poppins-regular'>Carrier Portal</h1>
            <p className='poppins-light'>Welcome to RateGenie, please enter your login details below to start using the app</p>
          </div>
          {loginMessage != null && <p className={loginMessage.type === 'S' ? 'success_message' : "warning_message"}>{loginMessage.message}</p>}
          <form onSubmit={onSubmit} className='login-form'>
            <InputField name='email' label='Email' type='email' error={error.email}/>
            <InputField name='password' pattern=".{8,}" label='Password' type='password' error={error.password}/>
            <div className='remember-pass-forgot-pass-section'>
              <CheckBox loading={false} label='Stay signed in for a week' alignCenter={true} name="rememberID"/>
              <div className="poppins-light forgot-password-link"><Link to="/auth/forgot-password" className="text-link poppins-light">Forgot your password?</Link></div>
            </div>
            <Button label='Sign in' type='submit' loading={formSubmitted} />
            <Link to="/auth/login" className="text-link poppins-light mt-4 fs-12">Sign in as Regular User</Link>
          </form>
        </div> : <CreateNewPassword updateUserPassword={updateUserPassword}/>}
      </Reveal>
    </div>
  )
}
