import React, {useContext} from 'react'
import { AccountContext } from '../../../../Context/AuthContext';
import "./style.css";
import Button, { CheckBox } from '../../../../Components/Button/Button';

const LogoutPopup = () => {
  const {setIsLoggingOut, isLoading, logout} = useContext(AccountContext);

  return (
    <div className='logout-container'>
      <div className='top-info-logout'>
        <div>
          <i className="bi bi-box-arrow-right"></i>
        </div>
        <h2>Confirm Logout</h2>
        <p>Are you sure you want to logout</p>
        
      </div>
      
      <div className='button-bottom-logout'>
        <CheckBox loading={false} label='Sign out all the users'/>
        <Button loading={isLoading} label='Logout' onClick={() => logout()}/>
        <button onClick={() => setIsLoggingOut(false)} className='button-link'>Do it Later</button>
      </div>
    </div>
  )
}

export default LogoutPopup