import React, { useState } from 'react'
import "./style.css";
import InputField from '../../../../Components/InputField/InputField'
import Button from '../../../../Components/Button/Button';
import { useOutletContext } from 'react-router-dom';
import { ModualPop } from '../../../../Components/ModulePop/ModulePop';
import { NewPaymentMethod } from '../components/NewPaymentMethod';


export const Billing = () => {
  const {business,paymentInfo} = useOutletContext<any>();
  const [addingNewPayment, setAddingNewPayment] = useState(false);
  //console.log(paymentInfo);
  const convertTimestampToDate = (timestamp: any):string => {
    const date = new Date(timestamp * 1000);

    // Custom date format options
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }; // Example: September 4, 2024
    return date.toLocaleDateString(undefined, options);
  };
  return (
    <div className='general-box-setting'>
        <h3>Payment Method</h3>
        <p>Payment Information connected to your account</p>
        <div className='single-col-box'>
            <div className='header-single-setting-box'>
                <h4>CardName</h4>
                <p>The Name Of the card holder</p>
            </div>
            <InputField label='' name='cardName' value={paymentInfo?.paymentMethods.data[0].billing_details.name.toUpperCase()||""}/>
            
        </div>
        <div className='single-col-box'>
            <div className='header-single-setting-box'>
                <h4>Card Type:</h4>
                <p>Type of card that was used for mayment</p>
            </div>
            <InputField label='' name='bankName' value={paymentInfo?.paymentMethods.data[0].card.brand.toUpperCase()||""}/>
        </div>
        <div className='multi-col-box'>
            <div className='header-single-setting-box'>
                <h4>Card:</h4>
                <p>Your Card that is in Use</p>               
            </div>
            <div className='cridet-card-values'>
              <InputField label='Card Number' disabled name='cardNumber' value={(paymentInfo&&`**** **** **** ${paymentInfo.paymentMethods.data[0].card.last4}`)||"Not Provided"}  />
              <InputField label='Expiry' disabled name='expriNumber' value= {(`${paymentInfo?.paymentMethods.data[0].card.exp_month} / ${paymentInfo?.paymentMethods.data[0].card.exp_year}`)||"Not Provided"} />
              <Button label='Update Default Payment' loading={false} onClick={() => setAddingNewPayment(true)}></Button>
            </div>
        </div>

        

        <div className='multi-col-box'>
            <div className='header-single-setting-box'>
                <h4>Address:</h4>
                <p>Billing address on the card</p>
            </div>
            <div>
                <InputField label='Address' name='billingAddress-streat' value= {paymentInfo?.paymentMethods.data[0].billing_details.address.postal_code||"Not Provided"}/>
                <InputField label='Country' name='billingAddress-country' value= {paymentInfo?.paymentMethods.data[0].billing_details.address.country||"Not Provided"} />
            </div>
        </div>




      <h3 className='mt-4'>Subscription Details</h3>
      <p>Subscription Information connected to your account</p>

      <div className='single-col-box mt-3 mb-5'>
        <div className='header-single-setting-box'>
          <h4>Account Status</h4>
          <p>The Information About Your Subscription</p>
        </div>
        <span className='label-small-box bg-label-success rounded avatar-initial'>{paymentInfo?.subscriptions.data[0].status.toUpperCase()||""}</span>
        
      </div>

        <div className='single-col-box mb-4'>
            <div className='header-single-setting-box'>
                <h4>Price:</h4>
                <p>Cost of your next month plan</p>
            </div>
            <InputField label='' name='billing-cost' disabled value= {`$ ${business?.SubscriptionNextBillCost?.toFixed(2)}`||""} />        
        </div>

        <div className='single-col-box mb-4'>
            <div className='header-single-setting-box'>
                <h4>Next Payment:</h4>
                <p></p>
            </div>
            
            <InputField label='' name='next-billing-date' disabled value= {convertTimestampToDate(paymentInfo?.subscriptions.data[0].current_period_end)||""} />        
        </div>

      <div className='multi-col-box mb-5'>
        <div className='header-single-setting-box'>
          <h4>Plan Include</h4>
          <p>What you are being charged for.</p>
        </div>
        <div className='list-of-paymeny'>
          <div className='list-of-user-carriers'>
            <InputField label='' name='plan-carrier' disabled value= {`${business?.CurrentCarrierUsers} Carriers`} />
            <InputField label='' name='plan-users' disabled value= {`${business?.CurrentSourcingUsers} Users`} />
          </div>
          <InputField label='' name='plan-users'  disabled value= "No Custom Templete" />
        </div>
      </div>

      <div className='single-col-box mb-4'>
        <div className='header-single-setting-box'>
          <h4>Billing Type:</h4>
          <p>Type of payment</p>
        </div>
        <InputField label='' name='billing-frequency' disabled value= {paymentInfo?.subscriptions.data[0].object.toUpperCase()||""} />        
      </div>

      <div className='single-col-box mb-4'>
        <div className='header-single-setting-box'>
          <h4>Start Date:</h4>
          <p>The data of when subscription started</p>
        </div>
        <InputField label='' name='start-date' disabled value= {convertTimestampToDate(paymentInfo?.subscriptions.data[0].start_date)||""} />        
      </div>
      
      <div>
        <Button label={"Save Changes"} loading={false} />
      </div>

      <ModualPop show={addingNewPayment} child={<NewPaymentMethod setAddingNewPayment={setAddingNewPayment}/>}/>
    </div>
  )
}
