import React, { useState } from 'react'
import { Reveal } from '../../../../Components/Revel/Revel'
import InputField, { PhoneInputCustom } from '../../../../Components/InputField/InputField'
import Button from '../../../../Components/Button/Button'
import departmentImage from "../../../../assets/img/department.png";
import "./style.css";

export const DomainPage = (prop: any) => {
  const [error, setError] = useState("");
  const onChange = (name:any, field:any) => {
    //console.log(name, field);
    prop.setBusinessInfo((prev: any) => {
      return {...prev, [name]: field}
    });
  }

  const onSubmit = (e: any) =>{
    e.preventDefault();
    setError("");

    if(prop.businessInfo.domain !== "") {
      prop.nextPage();
    } else {
      setError("Enter a valid Domain");
    }
  }

  return (
    <div>
      <Reveal>
        <div className='header-business-box-step'>
          <h2>Parent Domain Setup</h2>
          <p>This information will help us help you achive your tasks</p>
        </div>
      </Reveal>

      <form className='d-flex form-business-info domain-form' onSubmit={onSubmit}>

        <Reveal delay={.1}>
          <div className='info-not-department-adding'>
            <img src={departmentImage} width={200} alt="excel sheet"/>
            <p>The department you are adding will be used to generate a CSV file, which will then be uploaded to the OTM software.</p>
          </div>
        </Reveal>

        <Reveal delay={.3}>
          <InputField error={error} label='Domain / Parent Domain' value={prop.businessInfo.domain} name="domain" placeholder='META' onChange={(field) => onChange('domain', field.domain)}/>
        </Reveal>


        <Reveal delay={.5}>
          <div className='d-flex gap-4 mt-4'>
            <button className='button-link' onClick={() => prop.goBack()} type='button'>Back</button>
            <Button label='Finish' loading={false} type='submit'></Button>
          </div>
        </Reveal>
      </form>
    </div>
  )
}
