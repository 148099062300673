import React, { useState } from 'react'
import InputField, { PhoneInputCustom, SelectField } from '../../../../Components/InputField/InputField'
import Button from '../../../../Components/Button/Button'
import "./style.css";
import { Reveal } from '../../../../Components/Revel/Revel';
import { AddressAutofill } from '@mapbox/search-js-react';

import { countries } from 'countries-list';

const INITIAL_ERROR = {
  name: '',
  country: ''
}

interface CountryOption {
  value: string;
  label: string;
}

export const CarrierPersonal = (prop: any) => {
  const [userInfo, setUserInfo] = useState({
    name: '',
  });

  const countryOptions: CountryOption[] = Object.keys(countries).map((countryCode) => ({
    value: countryCode,
    label: countries[countryCode as keyof typeof countries].name,
  }));


  const [selectedOption, setSelectedOption] = useState<CountryOption | null>(null);
  const [error, setError] = useState(INITIAL_ERROR);

  const onChange = (name:any, field:any) => {
    //console.log(name, field);
    prop.setCarrierPersonal((prev: any) => {
      return {...prev, [name]: field}
    });
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    setError(INITIAL_ERROR);
    const data = new FormData(e.target);
    const name = Object.fromEntries(data.entries()).cName;

    if(name !== "" && prop.carrierPersonal.country !== null) {
      prop.nextPage();
    } else {
      if(name === "") {
        setError((prev) => {
          return {...prev, name: "Enter a valid name"}
        })
      }

      if(prop.carrierPersonal.country === null) {
        setError((prev) => {
          return {...prev, country: "Select a valid country"}
        })
      }
    }   
  }

  const selectChange = (data: any) => {
    prop.setCarrierPersonal((prev: any) => {
      return {...prev, country: data}
    });
  }

  return (
    <div className='max-width-500'>
      <Reveal>
        <div className='header-business-box-step'>
          <h2>Personal Information</h2>
          <p>This information will help us help you achive your tasks</p>
        </div>
      </Reveal>

      <form className='d-flex form-personal-account' onSubmit={onSubmit}>
        <Reveal>
          <InputField label='Full Name' error={error.name} name="cName" placeholder='John Smith' value={prop.carrierPersonal.name} onChange={(field) => onChange('name', field.cName)}/>
        </Reveal>
        <Reveal>
          <div className='d-flex flex-col select-field-account-complection'>
            <SelectField name="country" value={prop.carrierPersonal.country} label={"Country"} error={error.country} onChange={selectChange} list={countryOptions}/>
          </div>
        </Reveal>
        <Reveal delay={.5}>
          <div className='d-flex gap-4 mt-4'>
            <button className='button-link' onClick={() => prop.goBack()} type='button'>Back</button>
            <Button label='Next' loading={false} type='submit'></Button>
          </div>
        </Reveal>
      </form>
    </div>
  )
}
