import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <footer>
        <div className='content-sizing footer-container'>
          <div className='single-short-link-footer-section'>
            <h3>Products</h3>
            <ul>
              <li>
                <Link to="">Working capital</Link>
              </li>
              <li>
                <Link to="">AP automation</Link>
              </li>
              <li>
                <Link to="">Smart accounting</Link>
              </li>
              <li>
                <Link to="">Pricing</Link>
              </li>
            </ul>
          </div>

          <div className='single-short-link-footer-section'>
            <h3>Company</h3>
            <ul>
              <li>
                <Link to="">About Us</Link>
              </li>
              <li>
                <Link to="">Support</Link>
              </li>
              <li>
                <Link to="">Pricing</Link>
              </li>
              <li>
                <Link to="">Blog</Link>
              </li>
            </ul>
          </div>

          <div className='single-short-link-footer-section'>
            <h3>Solutions</h3>
            <ul>
              <li>
                <Link to="">For small business</Link>
              </li>
              <li>
                <Link to="">For Partners</Link>
              </li>
              <li>
                <Link to="">Customized Templates</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className='content-sizing footer-copy-right'>
          <div className='d-flex gap-3'>
            <p>&copy;2024 Rate360 built by <a href=''>Syncafy.</a></p>
            <div className='d-flex gap-3'>
              <Link to="">Privacy Policy</Link>
              <Link to="">Terms & Condition</Link>
            </div>
          </div>
        </div>
      </footer>
  )
}
