import React, { useState } from 'react'
import InputField from '../../../Components/InputField/InputField'
import Button from '../../../Components/Button/Button'
import PasswordStrengthBar from 'react-password-strength-bar';
import { Reveal } from '../../../Components/Revel/Revel';

interface ICreateNewPassword {
  updateUserPassword: (password: string) => void
}

const INITIAL_ERROR = {
  password: "",
  rePassword: ""
}

export const CreateNewPassword = (prop: ICreateNewPassword) => {
  const [password, setPassword] = useState<string>();
  const [score, setScore] = useState<number>(0);
  const [error, setError] = useState<any>(INITIAL_ERROR);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const changeScore = (score: number) => {
    setScore(score);
  }

  const passwordUpdate = (fields: any) => {
    setPassword(fields.password);
  }

  const onSubmit = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    setError(INITIAL_ERROR);
    setFormSubmitted(true);
    const data = new FormData(e.target);
    const newPass:string = Object.fromEntries(data.entries()).password.toString();
    const confirmNewPass:string = Object.fromEntries(data.entries()).rePassword.toString();

    if(newPass !== "" && confirmNewPass !== "" && newPass === confirmNewPass && score >= 3) {
      await prop.updateUserPassword(newPass);
    } else {
      setFormSubmitted(false);
      
      if(newPass === "") {
        setError((prev: any) => {
          return {...prev, password: "Enter a valid password"}
        })
      } else if (confirmNewPass === "") {
        setError((prev: any) => {
          return {...prev, rePassword: "Enter a valid password"}
        });
      } else if (newPass !== confirmNewPass) {
        setError((prev: any) => {
          return {...prev, rePassword: "Password don't match"}
        });
      } else if (score < 3) {
        setError((prev: any) => {
          return {...prev, password: "Password is weak"}
        })
      }

      
    }

    //
  }

  return (
    <Reveal>
      <div className='login-form-container'>
        <div className='login-header'>
          <h1 className='poppins-regular'>Create New Password</h1>
          <p className='poppins-light'>Please enter a new password for your account. The one-time password will be replaced with the password you enter.</p>
        </div>
        {/* {loginMessage != null && <p className='success_message'>{loginMessage}</p>} */}
        <form className='login-form' onSubmit={onSubmit}>
          <InputField name='password' pattern=".{8,}" label='New Password' type='password' error={error.password} onChange={passwordUpdate}/>
          <PasswordStrengthBar password={password} className="password-bar mb-0" onChangeScore={changeScore}/>
          <InputField name='rePassword' pattern=".{8,}" className="remove-label-space" label='Confirm New Password' type='password' error={error.rePassword}/>
          
          {/* <div className='remember-pass-forgot-pass-section'>
            <CheckBox loading={false} label='Stay signed in for a week' alignCenter={true}/>
            <div className="poppins-light forgot-password-link"><Link to="/auth/forgot-password" className="text-link poppins-light">Forgot your password?</Link></div>
          </div> */}
          <Button label='Change Password' type='submit' loading={formSubmitted} className='mt-2'/>
        </form>
      </div>
    </Reveal>
  )
}
