import React from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'

export const UserTable = (prop: any) => {
  return (
    prop.userData.length === 0 ?
      <div className='box-security-token mt-5'> 
        <div className='no-result-found mt-5'>
          <div>
            <i className="bi bi-person-x"></i>
            <h2>No Users Found</h2>
            <p>For any questions or information regarding rates, please contact your carrier directly. They will be able to provide you with the most accurate and up-to-date details.</p>
            <Link to="/">Learn More <i className="bi bi-arrow-right"></i></Link>
          </div>
        </div> 
      </div> :

    <div className="row mt-4">
      <div className="card pricing-sheet">
        <div className='d-flex justify-content-between align-items-center header-section-dash-single-row'>
        <table className='table-of-items activity-table'>
              <thead>
                <th>Users <i className="bi bi-arrow-down"></i></th>
                <th>Created Date</th>
                <th>Role</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {prop.userData.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>
                      <div className='first-item-left-side'>
                        {/* <CheckBox loading={false}/> */}
                        <div className='carrier-info-table-box'>
                          <h4>{item?.inviteeEmail}</h4>
                          <p>{item?.id}</p>
                        </div>
                      </div>
                    </td>
                    
                    <td><Moment format='MMM DD, YYYY'>{item?.createdAt}</Moment></td>
                    <td>EMPLOYEE</td>
                    <td className='last-item-table'><button className='btn-danger btn'>Delete</button></td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
      </div>
    </div>
  )
}
