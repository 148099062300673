import React, { useContext, useEffect, useState } from 'react'
import logo from "../../../assets/img/logo.png";
import "./style.css";
import Button from '../../../Components/Button/Button';
import { WelcomeRG } from './Pages/WelcomeRG';
import { useMultiStepForm } from './hooks/userMultiStepForm';
import { BusinessInfo } from './Pages/BusinessInfo';
import { Domain } from 'domain';
import { DomainPage } from './Pages/DomainPage';
import { Finish } from './Pages/Finish';
import { useNavigate } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { ModualPop } from '../../../Components/ModulePop/ModulePop';
import { SubmittingLoading } from './SubmittingLoading';
import { createCompany } from '../../../graphql/mutations';
import { CreateCompanyInput } from '../../../API';
import { AccountContext } from '../../../Context/AuthContext';
import { Membership } from './Pages/Membership';
export const FinishAccountSetup = () => {
  const navigate = useNavigate();
  const {getUser, getSession} = useContext(AccountContext);
  const [businessInfo, setBusinessInfo] = useState({
    name: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    postal: '',
    state: '',
    domain: ''
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);


  const nextPage = () => {
    next();
  }

  const goBack = () => {
    back();
  }

  const closeSetup = async () => {
    await getSession();
  }

  const submitUserProfile = async (userCount: number, carrierCount: number) => {
    setIsSubmitting(true);
    try {
      const user = getUser();
      const userSession = user.signInUserSession;
      const idToken = userSession.idToken.jwtToken;
      const itm = await fetch("https://vsqwc4gr9f.execute-api.us-east-1.amazonaws.com/Test/company",
        {
          mode: "cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": idToken,
          },

          body: JSON.stringify({
            businessInfo: businessInfo,
            userId: user.username,
            carrier: carrierCount,
            user: userCount,
            email: user.attributes.email,
          }),
      });
      const data = await itm.json();
      //console.log(data);
      if(data.code === 200) {
        window.location = data.url;
      }
      //console.log(data);
      // if(data.statusCode === 200) {
      //   nextPage();
      // }
    } catch(e) {
      console.log(e);
    }   
    setIsSubmitting(false);
  }

  const {back, next, step, isFirstStep, isLastStep, currentStepIndex, goTo} = useMultiStepForm(
    [
      <WelcomeRG next={nextPage}/>,
      <BusinessInfo goBack={goBack} nextPage={nextPage} setBusinessInfo={setBusinessInfo} businessInfo={businessInfo}/>,
      <DomainPage goBack={goBack} nextPage={nextPage} setBusinessInfo={setBusinessInfo} businessInfo={businessInfo}/>,
      <Membership goBack={goBack} submitUserProfile={submitUserProfile}/>,
      // <Finish closeSetup={closeSetup}/>
    ]
  );


  useEffect(() => {
    const data = getUser();
    if(data !== null) {
      if(data.attributes['custom:status'] === "COMPLETE") {
        navigate('/dashboard');
      }
    } 
  }, [navigate, getUser]);
 
  
  return (
    <>
      <div className='user-content-setup-finish-auth'>
        <div className='setups-left-side'>
          <div className='header-info-account-setup'>
            <img src={logo} width={120}/>
          </div> 
        
          <ul className='setups-list-account'>
            <li className={currentStepIndex >= 0 ? "active-selection-step" : ""}>
              <div className='number-id'>
                1
              </div>
              <div>
                <h3>Welcome to RateGenie</h3>
                <p>What is the system of yours</p>
              </div>
              <div className='line-setup-connect'></div>
            </li>

            <li className={currentStepIndex >= 1 ? "active-selection-step" : ""}>
              <div className='number-id'>
                2
              </div>
              <div>
                <h3>Business Information</h3>
                <p>What is the system of yours</p>
              </div>
              <div className='line-setup-connect'></div>
            </li>

           

            <li className={currentStepIndex >= 2 ? "active-selection-step" : ""}>
              <div className='number-id'>
                3
              </div>
              <div>
                <h3>Parent Domain Setup</h3>
                <p>What is the system of yours</p>
              </div>

              <div className='line-setup-connect'></div>
            </li>

            <li className={currentStepIndex >= 3 ? "active-selection-step" : ""}>
              <div className='number-id'>
                4
              </div>
              <div>
                <h3>Subscription</h3>
                <p>What is the system of yours</p>
              </div>
            </li>
          </ul>

          <div className='help-section-account-setup'>
            <i className="bi bi-life-preserver"></i>
            <h3>Having trouble?</h3>
            <p>Feel free to contact us and we will always help you through the process</p>
          </div>
        </div>

        <div className='setups-right-side'>
          {step}
        </div>
      </div>
      <ModualPop show={isSubmitting} child={<SubmittingLoading />}/>
    </>
  )
}
