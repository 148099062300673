/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getPaymentResult = /* GraphQL */ `query GetPaymentResult($id: ID!) {
  getPaymentResult(id: $id) {
    id
    billinghistoryID
    Invoice
    date
    transactionStatus
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentResultQueryVariables,
  APITypes.GetPaymentResultQuery
>;
export const listPaymentResults = /* GraphQL */ `query ListPaymentResults(
  $filter: ModelPaymentResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaymentResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      billinghistoryID
      Invoice
      date
      transactionStatus
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentResultsQueryVariables,
  APITypes.ListPaymentResultsQuery
>;
export const paymentResultsByBillinghistoryID = /* GraphQL */ `query PaymentResultsByBillinghistoryID(
  $billinghistoryID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentResultFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentResultsByBillinghistoryID(
    billinghistoryID: $billinghistoryID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      billinghistoryID
      Invoice
      date
      transactionStatus
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentResultsByBillinghistoryIDQueryVariables,
  APITypes.PaymentResultsByBillinghistoryIDQuery
>;
export const getRateBatch = /* GraphQL */ `query GetRateBatch($id: ID!) {
  getRateBatch(id: $id) {
    id
    Rates {
      items {
        id
        tsMode
        data
        scac
        serviceName
        startDate
        endDate
        originCity
        originCountry
        originProvince
        destinationCountry
        destinationCity
        destinationProvince
        originRegion
        destinationRegion
        ownerId
        ownerEmail
        ratebatchID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    companyID
    carrierID
    status
    tsMode
    scac
    startDate
    endDate
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRateBatchQueryVariables,
  APITypes.GetRateBatchQuery
>;
export const listRateBatches = /* GraphQL */ `query ListRateBatches(
  $filter: ModelRateBatchFilterInput
  $limit: Int
  $nextToken: String
) {
  listRateBatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Rates {
        items {
          id
          tsMode
          data
          scac
          serviceName
          startDate
          endDate
          originCity
          originCountry
          originProvince
          destinationCountry
          destinationCity
          destinationProvince
          originRegion
          destinationRegion
          ownerId
          ownerEmail
          ratebatchID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyID
      carrierID
      status
      tsMode
      scac
      startDate
      endDate
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRateBatchesQueryVariables,
  APITypes.ListRateBatchesQuery
>;
export const rateBatchesByCompanyID = /* GraphQL */ `query RateBatchesByCompanyID(
  $companyID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRateBatchFilterInput
  $limit: Int
  $nextToken: String
) {
  rateBatchesByCompanyID(
    companyID: $companyID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      Rates {
        items {
          id
          tsMode
          data
          scac
          serviceName
          startDate
          endDate
          originCity
          originCountry
          originProvince
          destinationCountry
          destinationCity
          destinationProvince
          originRegion
          destinationRegion
          ownerId
          ownerEmail
          ratebatchID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyID
      carrierID
      status
      tsMode
      scac
      startDate
      endDate
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RateBatchesByCompanyIDQueryVariables,
  APITypes.RateBatchesByCompanyIDQuery
>;
export const rateBatchesByCarrierID = /* GraphQL */ `query RateBatchesByCarrierID(
  $carrierID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRateBatchFilterInput
  $limit: Int
  $nextToken: String
) {
  rateBatchesByCarrierID(
    carrierID: $carrierID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      Rates {
        items {
          id
          tsMode
          data
          scac
          serviceName
          startDate
          endDate
          originCity
          originCountry
          originProvince
          destinationCountry
          destinationCity
          destinationProvince
          originRegion
          destinationRegion
          ownerId
          ownerEmail
          ratebatchID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyID
      carrierID
      status
      tsMode
      scac
      startDate
      endDate
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RateBatchesByCarrierIDQueryVariables,
  APITypes.RateBatchesByCarrierIDQuery
>;
export const getUserInvitation = /* GraphQL */ `query GetUserInvitation($id: ID!) {
  getUserInvitation(id: $id) {
    id
    inviteeEmail
    inviteeId
    companyID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserInvitationQueryVariables,
  APITypes.GetUserInvitationQuery
>;
export const listUserInvitations = /* GraphQL */ `query ListUserInvitations(
  $filter: ModelUserInvitationFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      inviteeEmail
      inviteeId
      companyID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserInvitationsQueryVariables,
  APITypes.ListUserInvitationsQuery
>;
export const userInvitationsByCompanyID = /* GraphQL */ `query UserInvitationsByCompanyID(
  $companyID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserInvitationFilterInput
  $limit: Int
  $nextToken: String
) {
  userInvitationsByCompanyID(
    companyID: $companyID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inviteeEmail
      inviteeId
      companyID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserInvitationsByCompanyIDQueryVariables,
  APITypes.UserInvitationsByCompanyIDQuery
>;
export const getBillingHistory = /* GraphQL */ `query GetBillingHistory($id: ID!) {
  getBillingHistory(id: $id) {
    id
    amount
    status
    billingDate
    companyID
    startPeriod
    endPeriod
    PaymentResult {
      items {
        id
        billinghistoryID
        Invoice
        date
        transactionStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBillingHistoryQueryVariables,
  APITypes.GetBillingHistoryQuery
>;
export const listBillingHistories = /* GraphQL */ `query ListBillingHistories(
  $filter: ModelBillingHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listBillingHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      amount
      status
      billingDate
      companyID
      startPeriod
      endPeriod
      PaymentResult {
        items {
          id
          billinghistoryID
          Invoice
          date
          transactionStatus
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBillingHistoriesQueryVariables,
  APITypes.ListBillingHistoriesQuery
>;
export const billingHistoriesByCompanyID = /* GraphQL */ `query BillingHistoriesByCompanyID(
  $companyID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBillingHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  billingHistoriesByCompanyID(
    companyID: $companyID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      status
      billingDate
      companyID
      startPeriod
      endPeriod
      PaymentResult {
        items {
          id
          billinghistoryID
          Invoice
          date
          transactionStatus
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BillingHistoriesByCompanyIDQueryVariables,
  APITypes.BillingHistoriesByCompanyIDQuery
>;
export const getCarrierCompanies = /* GraphQL */ `query GetCarrierCompanies($id: ID!) {
  getCarrierCompanies(id: $id) {
    id
    Name
    companyID
    carrierID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCarrierCompaniesQueryVariables,
  APITypes.GetCarrierCompaniesQuery
>;
export const listCarrierCompanies = /* GraphQL */ `query ListCarrierCompanies(
  $filter: ModelCarrierCompaniesFilterInput
  $limit: Int
  $nextToken: String
) {
  listCarrierCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Name
      companyID
      carrierID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCarrierCompaniesQueryVariables,
  APITypes.ListCarrierCompaniesQuery
>;
export const carrierCompaniesByCarrierID = /* GraphQL */ `query CarrierCompaniesByCarrierID(
  $carrierID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCarrierCompaniesFilterInput
  $limit: Int
  $nextToken: String
) {
  carrierCompaniesByCarrierID(
    carrierID: $carrierID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      Name
      companyID
      carrierID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CarrierCompaniesByCarrierIDQueryVariables,
  APITypes.CarrierCompaniesByCarrierIDQuery
>;
export const getRates = /* GraphQL */ `query GetRates($id: ID!) {
  getRates(id: $id) {
    id
    tsMode
    data
    scac
    serviceName
    startDate
    endDate
    originCity
    originCountry
    originProvince
    destinationCountry
    destinationCity
    destinationProvince
    originRegion
    destinationRegion
    ownerId
    ownerEmail
    ratebatchID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRatesQueryVariables, APITypes.GetRatesQuery>;
export const listRates = /* GraphQL */ `query ListRates(
  $filter: ModelRatesFilterInput
  $limit: Int
  $nextToken: String
) {
  listRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tsMode
      data
      scac
      serviceName
      startDate
      endDate
      originCity
      originCountry
      originProvince
      destinationCountry
      destinationCity
      destinationProvince
      originRegion
      destinationRegion
      ownerId
      ownerEmail
      ratebatchID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRatesQueryVariables, APITypes.ListRatesQuery>;
export const ratesByRatebatchID = /* GraphQL */ `query RatesByRatebatchID(
  $ratebatchID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRatesFilterInput
  $limit: Int
  $nextToken: String
) {
  ratesByRatebatchID(
    ratebatchID: $ratebatchID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tsMode
      data
      scac
      serviceName
      startDate
      endDate
      originCity
      originCountry
      originProvince
      destinationCountry
      destinationCity
      destinationProvince
      originRegion
      destinationRegion
      ownerId
      ownerEmail
      ratebatchID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RatesByRatebatchIDQueryVariables,
  APITypes.RatesByRatebatchIDQuery
>;
export const getDepartments = /* GraphQL */ `query GetDepartments($id: ID!) {
  getDepartments(id: $id) {
    id
    DepartmentName
    DepartmentSCAC
    companyID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDepartmentsQueryVariables,
  APITypes.GetDepartmentsQuery
>;
export const listDepartments = /* GraphQL */ `query ListDepartments(
  $filter: ModelDepartmentsFilterInput
  $limit: Int
  $nextToken: String
) {
  listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      DepartmentName
      DepartmentSCAC
      companyID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDepartmentsQueryVariables,
  APITypes.ListDepartmentsQuery
>;
export const departmentsByCompanyID = /* GraphQL */ `query DepartmentsByCompanyID(
  $companyID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDepartmentsFilterInput
  $limit: Int
  $nextToken: String
) {
  departmentsByCompanyID(
    companyID: $companyID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      DepartmentName
      DepartmentSCAC
      companyID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DepartmentsByCompanyIDQueryVariables,
  APITypes.DepartmentsByCompanyIDQuery
>;
export const getCarrier = /* GraphQL */ `query GetCarrier($id: ID!) {
  getCarrier(id: $id) {
    id
    Email
    Phone
    Address {
      Address
      City
      Country
      Zipcode
      State
      __typename
    }
    ServiceName
    SCAC
    CarrierInvitations {
      items {
        id
        InviterId
        inviteeEmail
        status
        inviteeId
        CompanyName
        companyID
        carrierID
        InviterEmail
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    CarrierCompanies {
      items {
        id
        Name
        companyID
        carrierID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    RateBatches {
      items {
        id
        Rates {
          items {
            id
            tsMode
            data
            scac
            serviceName
            startDate
            endDate
            originCity
            originCountry
            originProvince
            destinationCountry
            destinationCity
            destinationProvince
            originRegion
            destinationRegion
            ownerId
            ownerEmail
            ratebatchID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyID
        carrierID
        status
        tsMode
        scac
        startDate
        endDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCarrierQueryVariables,
  APITypes.GetCarrierQuery
>;
export const listCarriers = /* GraphQL */ `query ListCarriers(
  $filter: ModelCarrierFilterInput
  $limit: Int
  $nextToken: String
) {
  listCarriers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Email
      Phone
      Address {
        Address
        City
        Country
        Zipcode
        State
        __typename
      }
      ServiceName
      SCAC
      CarrierInvitations {
        items {
          id
          InviterId
          inviteeEmail
          status
          inviteeId
          CompanyName
          companyID
          carrierID
          InviterEmail
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CarrierCompanies {
        items {
          id
          Name
          companyID
          carrierID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      RateBatches {
        items {
          id
          Rates {
            items {
              id
              tsMode
              data
              scac
              serviceName
              startDate
              endDate
              originCity
              originCountry
              originProvince
              destinationCountry
              destinationCity
              destinationProvince
              originRegion
              destinationRegion
              ownerId
              ownerEmail
              ratebatchID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyID
          carrierID
          status
          tsMode
          scac
          startDate
          endDate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCarriersQueryVariables,
  APITypes.ListCarriersQuery
>;
export const getCarrierInvitation = /* GraphQL */ `query GetCarrierInvitation($id: ID!) {
  getCarrierInvitation(id: $id) {
    id
    InviterId
    inviteeEmail
    status
    inviteeId
    CompanyName
    companyID
    carrierID
    InviterEmail
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCarrierInvitationQueryVariables,
  APITypes.GetCarrierInvitationQuery
>;
export const listCarrierInvitations = /* GraphQL */ `query ListCarrierInvitations(
  $filter: ModelCarrierInvitationFilterInput
  $limit: Int
  $nextToken: String
) {
  listCarrierInvitations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      InviterId
      inviteeEmail
      status
      inviteeId
      CompanyName
      companyID
      carrierID
      InviterEmail
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCarrierInvitationsQueryVariables,
  APITypes.ListCarrierInvitationsQuery
>;
export const carrierInvitationsByCompanyID = /* GraphQL */ `query CarrierInvitationsByCompanyID(
  $companyID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCarrierInvitationFilterInput
  $limit: Int
  $nextToken: String
) {
  carrierInvitationsByCompanyID(
    companyID: $companyID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      InviterId
      inviteeEmail
      status
      inviteeId
      CompanyName
      companyID
      carrierID
      InviterEmail
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CarrierInvitationsByCompanyIDQueryVariables,
  APITypes.CarrierInvitationsByCompanyIDQuery
>;
export const carrierInvitationsByCarrierID = /* GraphQL */ `query CarrierInvitationsByCarrierID(
  $carrierID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCarrierInvitationFilterInput
  $limit: Int
  $nextToken: String
) {
  carrierInvitationsByCarrierID(
    carrierID: $carrierID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      InviterId
      inviteeEmail
      status
      inviteeId
      CompanyName
      companyID
      carrierID
      InviterEmail
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CarrierInvitationsByCarrierIDQueryVariables,
  APITypes.CarrierInvitationsByCarrierIDQuery
>;
export const getCompany = /* GraphQL */ `query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    Name
    PhoneNumber
    Address {
      Address
      City
      Country
      Zipcode
      State
      __typename
    }
    Domain
    CarrierInvitations {
      items {
        id
        InviterId
        inviteeEmail
        status
        inviteeId
        CompanyName
        companyID
        carrierID
        InviterEmail
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    Departments {
      items {
        id
        DepartmentName
        DepartmentSCAC
        companyID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    SubscriptionStatus
    SubscriptionEndDate
    BillingHistories {
      items {
        id
        amount
        status
        billingDate
        companyID
        startPeriod
        endPeriod
        PaymentResult {
          items {
            id
            billinghistoryID
            Invoice
            date
            transactionStatus
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    UserInvitations {
      items {
        id
        inviteeEmail
        inviteeId
        companyID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    MaxSourcingUsers
    MaxCarrierUsers
    CurrentSourcingUsers
    CurrentCarrierUsers
    SubscriptionId
    Email
    RateBatches {
      items {
        id
        Rates {
          items {
            id
            tsMode
            data
            scac
            serviceName
            startDate
            endDate
            originCity
            originCountry
            originProvince
            destinationCountry
            destinationCity
            destinationProvince
            originRegion
            destinationRegion
            ownerId
            ownerEmail
            ratebatchID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyID
        carrierID
        status
        tsMode
        scac
        startDate
        endDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    SubscriptionNextBillCost
    PaymentNextRetryDate
    CustomerId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyQueryVariables,
  APITypes.GetCompanyQuery
>;
export const listCompanies = /* GraphQL */ `query ListCompanies(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Name
      PhoneNumber
      Address {
        Address
        City
        Country
        Zipcode
        State
        __typename
      }
      Domain
      CarrierInvitations {
        items {
          id
          InviterId
          inviteeEmail
          status
          inviteeId
          CompanyName
          companyID
          carrierID
          InviterEmail
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Departments {
        items {
          id
          DepartmentName
          DepartmentSCAC
          companyID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      SubscriptionStatus
      SubscriptionEndDate
      BillingHistories {
        items {
          id
          amount
          status
          billingDate
          companyID
          startPeriod
          endPeriod
          PaymentResult {
            items {
              id
              billinghistoryID
              Invoice
              date
              transactionStatus
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      UserInvitations {
        items {
          id
          inviteeEmail
          inviteeId
          companyID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      MaxSourcingUsers
      MaxCarrierUsers
      CurrentSourcingUsers
      CurrentCarrierUsers
      SubscriptionId
      Email
      RateBatches {
        items {
          id
          Rates {
            items {
              id
              tsMode
              data
              scac
              serviceName
              startDate
              endDate
              originCity
              originCountry
              originProvince
              destinationCountry
              destinationCity
              destinationProvince
              originRegion
              destinationRegion
              ownerId
              ownerEmail
              ratebatchID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyID
          carrierID
          status
          tsMode
          scac
          startDate
          endDate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      SubscriptionNextBillCost
      PaymentNextRetryDate
      CustomerId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompaniesQueryVariables,
  APITypes.ListCompaniesQuery
>;
