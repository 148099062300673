import { Rates } from "../../../API";
import { GLOBAL_RATE_TYPE } from "../../../Interfaces/IRateForm";
import { ICSVGenerator } from "../../GeneratorFactory/GeneratorFactory";

export class FTLGenerator_X_Lane implements ICSVGenerator {
  generateCsv(data: Rates[], companyData: any): string {
    const headers = [
      { label: "X_LANE_GID", key: "X_LANE_GID" },
      { label: "X_LANE_XID", key: "X_LANE_XID" },
      { label: "SOURCE_LOCATION_GID", key: "SOURCE_LOCATION_GID" },
      { label: "SOURCE_CITY", key: "SOURCE_CITY" },
      { label: "SOURCE_PROVINCE_CODE", key: "SOURCE_PROVINCE_CODE" },
      { label: "SOURCE_POSTAL_CODE", key: "SOURCE_POSTAL_CODE" },
      { label: "SOURCE_COUNTRY_CODE3_GID", key: "SOURCE_COUNTRY_CODE3_GID" },
      { label: "SOURCE_ZONE4", key: "SOURCE_ZONE4" },
      { label: "SOURCE_ZONE1", key: "SOURCE_ZONE1" },
      { label: "SOURCE_ZONE2", key: "SOURCE_ZONE2" },
      { label: "SOURCE_ZONE3", key: "SOURCE_ZONE3" },
      { label: "SOURCE_GEO_HIERARCHY_GID", key: "SOURCE_GEO_HIERARCHY_GID" },
      { label: "DEST_LOCATION_GID", key: "DEST_LOCATION_GID" },
      { label: "DEST_CITY", key: "DEST_CITY" },
      { label: "DEST_PROVINCE_CODE", key: "DEST_PROVINCE_CODE" },
      { label: "DEST_POSTAL_CODE", key: "DEST_POSTAL_CODE" },
      { label: "DEST_COUNTRY_CODE3_GID", key: "DEST_COUNTRY_CODE3_GID" },
      { label: "DEST_ZONE4", key: "DEST_ZONE4" },
      { label: "DEST_ZONE1", key: "DEST_ZONE1" },
      { label: "DEST_ZONE2", key: "DEST_ZONE2" },
      { label: "DEST_ZONE3", key: "DEST_ZONE3" },
      { label: "DEST_GEO_HIERARCHY_GID", key: "DEST_GEO_HIERARCHY_GID" },
      { label: "SOURCE_REGION_GID", key: "SOURCE_REGION_GID" },
      { label: "DEST_REGION_GID", key: "DEST_REGION_GID" },
      { label: "CONSTRAINT_SET_GID", key: "CONSTRAINT_SET_GID" },
      { label: "PLANNING_PARAMETER_SET_GID", key: "PLANNING_PARAMETER_SET_GID" },
      { label: "SOURCE_RAIL_SPLC_GID", key: "SOURCE_RAIL_SPLC_GID" },
      { label: "DEST_RAIL_SPLC_GID", key: "DEST_RAIL_SPLC_GID" },
      { label: "SOURCE_RAIL_STATION_CODE_GID", key: "SOURCE_RAIL_STATION_CODE_GID" },
      { label: "DEST_RAIL_STATION_CODE_GID", key: "DEST_RAIL_STATION_CODE_GID" },
      { label: "P_RULE_SET_GID", key: "P_RULE_SET_GID" },
      { label: "DOMAIN_NAME", key: "DOMAIN_NAME" },
    ];

    const csv_data = [
      {
        X_LANE_GID: "EXEC SQL ALTER SESSION SET NLS_DATE_FORMAT = 'YYYYMMDDHH24MISS'",
        X_LANE_XID: "",
        SOURCE_LOCATION_GID: "",
        SOURCE_CITY: "",
        SOURCE_PROVINCE_CODE: "",
        SOURCE_POSTAL_CODE: "",
        SOURCE_COUNTRY_CODE3_GID: "",
        SOURCE_ZONE4: "",
        SOURCE_ZONE1: "",
        SOURCE_ZONE2: "",
        SOURCE_ZONE3: "",
        SOURCE_GEO_HIERARCHY_GID: "",
        DEST_LOCATION_GID: "",
        DEST_CITY: "",
        DEST_PROVINCE_CODE: "",
        DEST_POSTAL_CODE: "",
        DEST_COUNTRY_CODE3_GID: "",
        DEST_ZONE4: "",
        DEST_ZONE1: "",
        DEST_ZONE2: "",
        DEST_ZONE3: "",
        DEST_GEO_HIERARCHY_GID: "",
        SOURCE_REGION_GID: "",
        DEST_REGION_GID: "",
        CONSTRAINT_SET_GID: "",
        PLANNING_PARAMETER_SET_GID: "",
        SOURCE_RAIL_SPLC_GID: "",
        DEST_RAIL_SPLC_GID: "",
        SOURCE_RAIL_STATION_CODE_GID: "",
        DEST_RAIL_STATION_CODE_GID: "",
        P_RULE_SET_GID: "",
        DOMAIN_NAME: "",
      },
    ];
    
    for(let i = 0; i < data.length; i++) {
      const file = JSON.parse(data[i].data || "");
      if(file) {
        const singleRow = {
          X_LANE_GID: `${companyData.domain}/${companyData.subDomain}.${file.laneId}`,
          X_LANE_XID: file.laneId,
          SOURCE_LOCATION_GID: "",
          SOURCE_CITY: "",
          SOURCE_PROVINCE_CODE: "",
          SOURCE_POSTAL_CODE: "",
          SOURCE_COUNTRY_CODE3_GID: "",
          SOURCE_ZONE4: "",
          SOURCE_ZONE1: "",
          SOURCE_ZONE2: "",
          SOURCE_ZONE3: "",
          SOURCE_GEO_HIERARCHY_GID: "",
          DEST_LOCATION_GID: "",
          DEST_CITY: "",
          DEST_PROVINCE_CODE: "",
          DEST_POSTAL_CODE: "",
          DEST_COUNTRY_CODE3_GID: "",
          DEST_ZONE4: "",
          DEST_ZONE1: "",
          DEST_ZONE2: "",
          DEST_ZONE3: "",
          DEST_GEO_HIERARCHY_GID: "",
          SOURCE_REGION_GID: "",
          DEST_REGION_GID: "",
          CONSTRAINT_SET_GID: "",
          PLANNING_PARAMETER_SET_GID: "",
          SOURCE_RAIL_SPLC_GID: "",
          DEST_RAIL_SPLC_GID: "",
          SOURCE_RAIL_STATION_CODE_GID: "",
          DEST_RAIL_STATION_CODE_GID: "",
          P_RULE_SET_GID: "",
          DOMAIN_NAME: `${companyData.domain}/${companyData.subDomain}`,
        }

        csv_data.push(singleRow);
      }
    }

    return this.convertToCsv(headers, csv_data);
  }

  getFileName(): string {
    return "1_X_LANE.csv";
  }

  private convertToCsv(headers: any[], data: any[]): string {
    const headerRow = headers.map(header => header.label).join(",");
    const rows = data.map(row => headers.map(header => row[header.key] || "").join(",")).join("\n");
    return `${headerRow}\n${rows}`;
  }
}