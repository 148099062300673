import { RateType } from "../API"
import { GLOBAL_RATE_TYPE } from "./IRateForm"

export const RateMode = [
  {value: "ZIP", label: "ZIP3 to ZIP3"},
  {value: "State", label: "State to State"},
  {value: "City", label: "City to City"},
]

export const TransportationMode = [
  {value: "AIROCEAN", label: "Air / Ocean"},
  {value: "TL", label: "TL"},
  {value: "FTLLTL", label: "FTL / LTL"},
]

export interface TransportField {
  label: string,
  type: string,
  objectKey: string,
  required: boolean,
  editable: boolean,
}

export interface ITransportationType {
  value: string,
  type: RateType,
  description: string,
  label: string,
  icon: string,
  fields: TransportField[],
  headers: string[],
  object: GLOBAL_RATE_TYPE,
}

export const TransportationTypes: ITransportationType[] = [
  {
    value: "AIR",
    type: RateType.AIROCEAN,
    description: "Reliable air and ocean freight services for international shipping.",
    label: "Air",
    icon: 'bi bi-airplane',
    headers: [
      "FF",
      "Start Date",
      "End Date",
      "Lane ID",
      "Origin Region",
      "Origin Country",
      "Origin Airport",
      "Origin City",
      "Destination Region",
      "Destination Country",
      "Destination Airport",
      "Destination City",
      "Service level",
      "DTD SLA",
      "Origin Customs per Shipment(CSE)",
      "Origin Inland Pick-up Minimum(PUC)",
      "Origin Inland Pick-up per KG(PUC)",
      "Origin Terminal Minimum(TER)",
      "Origin Terminal per KG(TER)",
      "Other Charges - Origin Minimum(HHB)",
      "Other Charges - Origin per KG",
      "Air Freight Minimum(400)",
      "Air Freight per KG(400)",
      "EMS per KG(EMS)",
      "Destination Terminal Minimum(DTC)",
      "Destination Terminal per KG(DTC)",
      "Destination Delivery per KG(DEL)",
      "Destination Customs Per Shipment(CSE)"
    ],
    object: {
      ff: "",
      startDate: "",
      endDate: "",
      laneId: "",
      originRegion: "",
      originCountry: "",
      originAirport: "",
      originCity: "",
      destinationRegion: "",
      destinationCountry: "",
      destinationAirport: "",
      destinationCity: "",
      serviceLevel: "",
      dtdSla: "",
      originCustomsPerShipment: "",
      originInlandPickupMinimum: "",
      originInlandPickupPerKg: "",
      originTerminalMinimum: "",
      originTerminalPerKg: "",
      otherChargesOriginMinimum: "",
      otherChargesOriginPerKg: "",
      airFreightMinimum: "",
      airFreightPerKg: "",
      emsPerKg: "",
      destinationTerminalMinimum: "",
      destinationTerminalPerKg: "",
      destinationDeliveryPerKg: "",
      destinationCustomsPerShipment: ""
    },
    fields: [
      { label: "FF", type: 'text', objectKey: 'ff', required: true, editable: false },
      { label: "Start Date", type: 'date', objectKey: 'startDate', required: true, editable: true },
      { label: "End Date", type: 'date', objectKey: 'endDate', required: true, editable: true },
      { label: "Lane ID", type: 'text', objectKey: 'laneId', required: true, editable: true },
      { label: "Origin Region", type: 'text', objectKey: 'originRegion', required: true, editable: true },
      { label: "Origin Country", type: 'text', objectKey: 'originCountry', required: true, editable: true },
      { label: "Origin Airport", type: 'text', objectKey: 'originAirport', required: true, editable: true },
      { label: "Origin City", type: 'text', objectKey: 'originCity', required: true, editable: true },
      { label: "Destination Region", type: 'text', objectKey: 'destinationRegion', required: true, editable: true },
      { label: "Destination Country", type: 'text', objectKey: 'destinationCountry', required: true, editable: true },
      { label: "Destination Airport", type: 'text', objectKey: 'destinationAirport', required: true, editable: true },
      { label: "Destination City", type: 'text', objectKey: 'destinationCity', required: true, editable: true },
      { label: "Service Level", type: 'text', objectKey: 'serviceLevel', required: true, editable: true },
      { label: "DTD SLA", type: 'text', objectKey: 'dtdSla', required: true, editable: true },
      { label: "Origin Customs per Shipment(CSE)", type: 'text', objectKey: 'originCustomsPerShipment', required: true, editable: true },
      { label: "Origin Inland Pick-up Minimum(PUC)", type: 'text', objectKey: 'originInlandPickupMinimum', required: true, editable: true },
      { label: "Origin Inland Pick-up per KG(PUC)", type: 'text', objectKey: 'originInlandPickupPerKg', required: true, editable: true },
      { label: "Origin Terminal Minimum(TER)", type: 'text', objectKey: 'originTerminalMinimum', required: true, editable: true },
      { label: "Origin Terminal per KG(TER)", type: 'text', objectKey: 'originTerminalPerKg', required: true, editable: true },
      { label: "Other Charges - Origin Minimum(HHB)", type: 'text', objectKey: 'otherChargesOriginMinimum', required: true, editable: true },
      { label: "Other Charges - Origin per KG", type: 'text', objectKey: 'otherChargesOriginPerKg', required: true, editable: true },
      { label: "Air Freight Minimum(400)", type: 'text', objectKey: 'airFreightMinimum', required: true, editable: true },
      { label: "Air Freight per KG(400)", type: 'text', objectKey: 'airFreightPerKg', required: true, editable: true },
      { label: "EMS per KG(EMS)", type: 'text', objectKey: 'emsPerKg', required: false, editable: true },
      { label: "Destination Terminal Minimum(DTC)", type: 'text', objectKey: 'destinationTerminalMinimum', required: true, editable: true },
      { label: "Destination Terminal per KG(DTC)", type: 'text', objectKey: 'destinationTerminalPerKg', required: true, editable: true },
      { label: "Destination Delivery per KG(DEL)", type: 'text', objectKey: 'destinationDeliveryPerKg', required: true, editable: true },
      { label: "Destination Customs Per Shipment(CSE)", type: 'text', objectKey: 'destinationCustomsPerShipment', required: true, editable: true }
    ]
  },
  {
    value: "ltl",
    type: RateType.LTL,
    icon: 'bi bi-truck-flatbed',
    description: "LTL (Less-than-Truckload) shipping for smaller freight shared with other shippers.",
    label: "LTL",
    headers: [
      "FF",
      "Effective Date",
      "Expiration Date",
      "Lane ID",
      "Origin State",
      "Origin Country",
      "Origin City/Zip",
      "Destination State",
      "Destination Country",
      "Destination City/Zip",
      "Equipment",
      "Rate Service",
      "Charge UOM",
      "LTL Discount%",
      "LTL Minimum Charge",
    ],
    object: {
      ff: "",
      startDate: "",
      endDate: "",
      laneId: "",
      originProvince: "",
      originCountry: "",
      originCity: "",
      destinationProvince: "",
      destinationCountry: "",
      destinationCity: "",
      rateService: "",
      equipment: "",
      chargeUom: "",
      ltlDiscount: "",
      ltlMinimumCharge: "",
    },
    fields: [
      { label: "FF", type: 'text', objectKey: 'ff', required: true, editable: false },
      { label: "Effective Date", type: 'date', objectKey: 'startDate', required: true, editable: true },
      { label: "Expiration Date", type: 'date', objectKey: 'endDate', required: true, editable: true },
      { label: "Lane ID", type: 'text', objectKey: 'laneId', required: true, editable: true },
      { label: "Origin State", type: 'text', objectKey: 'originProvince', required: true, editable: true },
      { label: "Origin Country", type: 'text', objectKey: 'originCountry', required: true, editable: true },
      { label: "Origin City/Zip", type: 'text', objectKey: 'originCity', required: true, editable: true },
      { label: "Destination State", type: 'text', objectKey: 'destinationProvince', required: true, editable: true },
      { label: "Destination Country", type: 'text', objectKey: 'destinationCountry', required: true, editable: true },
      { label: "Destination City/Zip", type: 'text', objectKey: 'destinationCity', required: true, editable: true },
      { label: "Equipment", type: 'text', objectKey: 'equipment', required: true, editable: true },
      { label: "Rate Service", type: 'text', objectKey: 'rateService', required: true, editable: true },
      { label: "Charge UOM", type: 'text', objectKey: 'chargeUom', required: true, editable: true },
      { label: "LTL Discount%", type: 'text', objectKey: 'ltlDiscount', required: true, editable: true },
      { label: "LTL Minimum Charge", type: 'text', objectKey: 'ltlMinimumCharge', required: true, editable: true },
    ]
  },
  {
    value: "TL",
    type: RateType.FTL,
    description: "Dedicated truckload services for large shipments and containers.",
    label: "TL / FTL",
    icon: 'bi bi-truck',
    headers: [
      "FF",
      "Effective Date",
      "Expiration Date",
      "Transport Mode",
      "Lane ID",
      "Transit Time",
      "Source Location ID",
      "Source City",
      "Source Province Code",
      "Source Postal Code",
      "Source Country Code",
      "Source Geo Hierarchy",
      "Destination Location ID",
      "Destination City",
      "Destination Province Code",
      "Destination Postal Code",
      "Destination Country Code",
      "Destination Geo Hierarchy",
      "Equipment",
      "Amount",
      "Currency",
      "Unit"
    ],
    object: {
      ff: "",
      startDate: "",
      endDate: "",
      transportMode: "",
      laneId: "",
      transitTime: "",
      sourceLocationId: "",
      originCity: "",
      originProvince: "",
      originPostalCode: "",
      originCountry: "",
      sourceGeoHierarchy: "",
      destinationLocationId: "",
      destinationCity: "",
      destinationProvince: "",
      destinationPostalCode: "",
      destinationCountry: "",
      destinationGeoHierarchy: "",
      equipment: "",
      amount: "",
      currency: "",
      unit: "",
    },
    fields: [
      { label: "FF", type: 'text', objectKey: 'ff', required: true, editable: false },
      { label: "Effective Date", type: 'date', objectKey: 'startDate', required: true, editable: true },
      { label: "Expiration Date", type: 'date', objectKey: 'endDate', required: true, editable: true },
      { label: "Transport Mode", type: 'text', objectKey: 'transportMode', required: true, editable: true },
      { label: "Lane ID", type: 'text', objectKey: 'laneId', required: true, editable: true },
      { label: "Transit Time", type: 'text', objectKey: 'transitTime', required: true, editable: true },
      { label: "Source Location ID", type: 'text', objectKey: 'sourceLocationId', required: true, editable: true },
      { label: "Source City", type: 'text', objectKey: 'originCity', required: true, editable: true },
      { label: "Source Province Code", type: 'text', objectKey: 'originProvince', required: true, editable: true },
      { label: "Source Postal Code", type: 'text', objectKey: 'originPostalCode', required: true, editable: true },
      { label: "Source Country Code", type: 'text', objectKey: 'originCountry', required: true, editable: true },
      { label: "Source Geo Hierarchy", type: 'text', objectKey: 'sourceGeoHierarchy', required: true, editable: true },
      { label: "Destination Location ID", type: 'text', objectKey: 'destinationLocationId', required: true, editable: true },
      { label: "Destination City", type: 'text', objectKey: 'destinationCity', required: true, editable: true },
      { label: "Destination Province Code", type: 'text', objectKey: 'destinationProvince', required: true, editable: true },
      { label: "Destination Postal Code", type: 'text', objectKey: 'destinationPostalCode', required: true, editable: true },
      { label: "Destination Country Code", type: 'text', objectKey: 'destinationCountry', required: true, editable: true },
      { label: "Destination Geo Hierarchy", type: 'text', objectKey: 'destinationGeoHierarchy', required: true, editable: true },
      { label: "Equipment", type: 'text', objectKey: 'equipment', required: true, editable: true },
      { label: "Amount", type: 'text', objectKey: 'amount', required: true, editable: true },
      { label: "Currency", type: 'text', objectKey: 'currency', required: true, editable: true },
      { label: "Unit", type: 'text', objectKey: 'unit', required: true, editable: true },
    ],
  },
]