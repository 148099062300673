import React, {useEffect, useContext, useState} from 'react'
import {useNavigate, Outlet } from 'react-router-dom';
import { AccountContext } from '../../../Context/AuthContext';

import "./style.css";
import SideBar from './components/SideBar';
import Header from './components/Header';
import { API } from 'aws-amplify';
import { LoadingFull } from '../../../Components/PreLoader/Loading';
// import {listBusinesses, listLocations} from "../../graphql/queries";
// import { DatabaseContext } from '../../setup/context-manager/dbContext';
import { Carrier, Company, Rates } from '../../../API';
// import { listCompanies } from '../../graphql/queries';
import { ModualPop } from '../../../Components/ModulePop/ModulePop';
import { WelcomeCarrier } from './components/WelcomeCarrier/WelcomeCarrier';
import { listCarriers, listRates } from '../../../graphql/queries';
import { CreateRates } from './Pages/Rates/CreateRates';


const listRatesDashboard = /* GraphQL */ `query ListRates(
  $filter: ModelRatesFilterInput
  $limit: Int
  $nextToken: String
) {
  listRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tsMode
    }
    nextToken
  }
}
`;

export const CarrierPortal = () => {
  const {isLoading, isAuthenticated, getUser} = useContext(AccountContext);
  //const [group, setGroup] = useState<string>();
  const navigate = useNavigate();
  const [carrier, setCarrier] = useState<Carrier | null>(null);
  const [pendingBoxSignUp, setPendingBoxSignUp] = useState<boolean>(false);
  const [creatingRate, setCreatingRate] = useState<boolean>(false);
  const [rateStats, setRateStats] = useState<any>(null);

  useEffect(() => {
    if(!isLoading) {
      if(isAuthenticated) {
        const data = getUser();
        const signUpStatus = data?.attributes["custom:status"];
        if(signUpStatus === "PENDING") {
          const timeout = setTimeout(() => {
            setPendingBoxSignUp(true);
          }, 500);
          return () => clearTimeout(timeout);
        }
      } else {
        navigate('/auth/login');
      }
    }
    
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    fetchCarrier();
    fetchAndCountRatesByType();
  }, []);

  const fetchCarrier = async () => {
    try {
      const response:any = await API.graphql({query: listCarriers, authMode: "AMAZON_COGNITO_USER_POOLS"});
      if(response != undefined) {
        const myCompany:Carrier = response.data.listCarriers.items[0];
        setCarrier(myCompany);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchAndCountRatesByType = async () => {
    try {
      let allRates: any[] = [];
      let nextToken: string | null = null;
  
      // Fetch all rates with pagination
      do {
        const response: any = await API.graphql({
          query: listRatesDashboard,
          authMode: "AMAZON_COGNITO_USER_POOLS",
          variables: {
            limit: 100, // Adjust limit based on API capacity
            nextToken: nextToken,
          },
        });
  
        if (response?.data?.listRates?.items) {
          allRates = [...allRates, ...response.data.listRates.items];
          nextToken = response.data.listRates.nextToken; // Update nextToken for the next request
        } else {
          nextToken = null; // Exit loop if no more data
        }
      } while (nextToken);
  
      // Count rates by type
      const rateTypeCounts: { [key: string]: number } = {
        total: 0,
        LTL: 0,
        FTL: 0,
      };
  
      allRates.forEach((rate) => {
        const rateType = rate.tsMode;
        if (rateTypeCounts[rateType]) {
          rateTypeCounts[rateType]++;
        } else {
          rateTypeCounts[rateType] = 1;
        }
        
        rateTypeCounts['total']++;

      });
  
      // Log or use the rateTypeCounts as needed
      console.log('Rate Type Counts:', rateTypeCounts);
  
      // Optionally, you can set it to state for displaying on the dashboard
      setRateStats(rateTypeCounts);
  
    } catch (error) {
      console.error("Error fetching and counting rates by type:", error);
      throw error;
    }
  }

  

 
  // const fetchCarrier = async () => {
  //   try {
  //     const response:any = await API.graphql({query: listCompanies, authMode: "AMAZON_COGNITO_USER_POOLS"});
  //     if(response != undefined) {
  //       const myCompany:Company = response.data.listCompanies.items[0];
  //       setBusiness(myCompany);
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  return (
    isLoading ? <LoadingFull text={"Preparing Dashboard"}/>:
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar invitation={carrier?.CarrierInvitations?.items} setCreatingRate={setCreatingRate}/>
          <div className="menu-inner-shadow"></div>
          <div className="layout-page page-dashboard-outlet-box">
            <Header />
            <div className="content-wrapper">
              <Outlet context={{carrier, rateStats}}/>
            </div>
          </div>
        </div>
      </div>
      <ModualPop show={creatingRate} child={<CreateRates carrier={carrier} setCreatingRate={setCreatingRate}/>} />
      <ModualPop show={pendingBoxSignUp} child={<WelcomeCarrier />}/>
    </>
  );
}

export default CarrierPortal;