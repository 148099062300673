import React, { useContext, useEffect, useState } from 'react'
import logo from "../../../assets/img/logo.png";
import "./style.css";
import { AccountContext } from '../../../Context/AuthContext';
import { ModualPop } from '../../../Components/ModulePop/ModulePop';
import { SubmittingLoading } from './SubmittingLoading';
import { useMultiStepForm } from './hooks/userMultiStepForm';
import { WelcomeCarrier } from './Pages/WelcomeRG';
import { CarrierInfo } from './Pages/CarrierInfo';
import { API, Auth } from 'aws-amplify';
import { updateCarrier } from '../../../graphql/mutations';
import { UpdateCarrierInput } from '../../../API';
import { Finish } from './Pages/Finish';
import { CarrierPersonal } from './Pages/CarrierPersonal';
import { useNavigate } from 'react-router-dom';

interface CarrierProfile{
  name: string,
  country: CountryOption | null
}

interface CountryOption {
  value: string;
  label: string;
}

export const FinishCarrierAccount = () => {
  const navigate = useNavigate();
  const {getUser, getSession} = useContext(AccountContext);

  const [carrierPersonal, setCarrierPersonal] = useState<CarrierProfile>({
    name: '',
    country: null
  });

  const [carrierInfo, setCarrierInfo] = useState({
    name: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    postal: '',
    state: '',
    scac: ''
  }); 

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);


  const nextPage = () => {
    next();
  }

  const goBack = () => {
    back();
  }

  const closeSetup = async () => {
    await getSession();
    navigate("/carrier");
  }

  const updateCarrierInfo = async () => {
    setIsSubmitting(true);
    try {
      const user = getUser();
      const updateCarrierOBJ: UpdateCarrierInput = {
        id: user.username,
        ServiceName: carrierInfo.name,
        SCAC: carrierInfo.scac,
        Phone: carrierInfo.phone,
        Address: {
          Address: carrierInfo.address,
          City: carrierInfo.city,
          Zipcode: carrierInfo.postal,
          Country: carrierInfo.country,
          State: carrierInfo.state
        }
      }

      const updatedCarrier:any = await API.graphql({query: updateCarrier, variables: { input: updateCarrierOBJ}, authMode: "AMAZON_COGNITO_USER_POOLS"});
      
      if(updatedCarrier.data !== null) {
        const userObjectUpdate = await Auth.currentAuthenticatedUser().then(item => {
          Auth.updateUserAttributes(item, {
            'custom:status': 'COMPLETE',
            'locale': carrierPersonal.country?.label,
            'name': carrierPersonal.name
          });
        }); 

        if(userObjectUpdate === undefined) {
          nextPage();
        }
      }
    } catch(e) {
      console.log(e);
    }

    setIsSubmitting(false);
  }

  const {back, next, step, isFirstStep, isLastStep, currentStepIndex, goTo} = useMultiStepForm(
    [
      <WelcomeCarrier next={nextPage}/>,
      <CarrierPersonal goBack={goBack} nextPage={nextPage} setCarrierPersonal={setCarrierPersonal} carrierPersonal={carrierPersonal}/>,
      <CarrierInfo goBack={goBack} nextPage={nextPage} setCarrierInfo={setCarrierInfo} carrierInfo={carrierInfo} updateCarrierInfo={updateCarrierInfo}/>,
      // <WelcomeRG next={nextPage}/>,
      // <BusinessInfo goBack={goBack} nextPage={nextPage} setBusinessInfo={setBusinessInfo} businessInfo={businessInfo}/>,
      // <DomainPage goBack={goBack} nextPage={nextPage} setBusinessInfo={setBusinessInfo} businessInfo={businessInfo} submitUserProfile={submitUserProfile}/>,
      <Finish closeSetup={closeSetup}/>
    ]
  );

  useEffect(() => {
    const data = getUser();
    if(data !== null) {
      if(data.attributes['custom:status'] === "COMPLETE") {
        navigate('/dashboard');
      }
    } 
  }, [navigate, getUser]);

  return (
    <>
      <div className='user-content-setup-finish-auth'>
        <div className='setups-left-side'>
          <div className='header-info-account-setup'>
            <img src={logo} width={120}/>
          </div> 
        
          <ul className='setups-list-account'>
            <li onClick={() => goTo(0)} className={currentStepIndex >= 0 ? "active-selection-step" : ""}>
              <div className='number-id'>
                1
              </div>
              <div>
                <h3>Welcome to RateGenie</h3>
                <p>What is the system of yours</p>
              </div>
              <div className='line-setup-connect'></div>
            </li>

            <li onClick={() => goTo(1)} className={currentStepIndex >= 1 ? "active-selection-step" : ""}>
              <div className='number-id'>
                2
              </div>
              <div>
                <h3>Personal Information</h3>
                <p>What is the system of yours</p>
              </div>
              <div className='line-setup-connect'></div>
            </li>

            <li onClick={() => goTo(2)} className={currentStepIndex >= 2 ? "active-selection-step" : ""}>
              <div className='number-id'>
                3
              </div>
              <div>
                <h3>Carrier Information</h3>
                <p>What is the system of yours</p>
              </div>
              <div className='line-setup-connect'></div>
            </li>

            <li onClick={() => goTo(3)} className={currentStepIndex >= 3 ? "active-selection-step" : ""}>
              <div className='number-id'>
                4
              </div>
              <div>
                <h3>Finalize Account</h3>
                <p>What is the system of yours</p>
              </div>

            </li>
          </ul>

          <div className='help-section-account-setup'>
            <i className="bi bi-life-preserver"></i>
            <h3>Having trouble?</h3>
            <p>Feel free to contact us and we will always help you through the process</p>
          </div>
        </div>

        <div className='setups-right-side'>
          {step}
        </div>
      </div>
      <ModualPop show={isSubmitting} child={<SubmittingLoading />}/>
    </>
  )
}
