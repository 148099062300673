import React from 'react'
import "./style.css";
import Button from '../../../../Components/Button/Button';
import error from "../../../../assets/img/error.png";
import { useNavigate } from 'react-router-dom';

export const AccountError = () => {
  const navigate = useNavigate();
  return (
    <div className='container-pop-up-box-error-account'>
      <img src={error} />
      <div>
        <h2>Oups! Something went wrong!</h2>
        <p>We encountered an error while trying to load your dashboard. Your payment didn't go througth. Please try again one more time? Pretty please? </p>
      </div>
      <Button loading={false} label='Continue to Completion' onClick={() => navigate('/process_payment_success')}></Button>
    </div>
  )
}
