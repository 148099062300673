import React from 'react'
import { ITransportationType, TransportationTypes, RateMode } from '../../../../../../Interfaces/ITransportationType'
import "./style.css";
import Button from '../../../../../../Components/Button/Button';
import { Reveal } from '../../../../../../Components/Revel/Revel';
import { FaPlane, FaShip, FaTruck, FaTruckLoading } from 'react-icons/fa';
import { SelectField } from '../../../../../../Components/InputField/InputField';

interface IRateType {
  companies: any[]
  nextPage: () => void,
  setSelectedTransportationType: (data: ITransportationType) => void,
  selectedTransportationType: ITransportationType,
  setSelectedCompany: (data: any) => void,
  selectedCompany: any,
}

export const RateType = (prop: IRateType) => {
  const changeCompany = (data: any) => {
    prop.setSelectedCompany(data);
  }

  return (
    <div className='rate-type-container'>
      <SelectField error={""} name="company" list={prop.companies} onChange={changeCompany} value={prop.selectedCompany} label="Select the Company for Rate Submission"  />
      <ul className='select-tsmode-step-1 mb-0'>
        {
          TransportationTypes != null && TransportationTypes.map((item, index) => (
            <li key={index} onClick={() => prop.setSelectedTransportationType(item)} className={prop.selectedTransportationType.type === item.type ? "active-selection-tsmode" : ""}>
              <div className='icon-tmode'>
                <i className={item.icon} />
              </div>
              {/* <FaPlane style={{ marginRight: '10px' }} /> */}
              <h2>{item.label}</h2>
              <p>{item.description}</p>

              {prop.selectedTransportationType.type === item.type && <div className='selected-checkbkox-tsmode'><i className="bi bi-check-circle-fill"></i></div>}
            </li>
          ))
        }
      </ul>
      <SelectField error={""} name="company" list={RateMode} onChange={changeCompany} value={prop.selectedCompany} label="Select Rate Type"  />
      <Button label="Continue" loading={false} onClick={() => prop.nextPage()}/>
    </div>
  )
}
