import React, { useContext, useEffect, useRef, useState } from 'react'
import { AccountContext } from '../../../Context/AuthContext'
import { API } from 'aws-amplify';
import { listCompanies } from '../../../graphql/queries';
import { Company } from '../../../API';
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import "./style.css";
import Button from '../../../Components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { LoadingFull } from '../../../Components/PreLoader/Loading';
import failedPayment from "../../../assets/img/payment-failed.png";
import { Reveal } from '../../../Components/Revel/Revel';
export const ProcessPaymentSuccess = () => {
  const navigate = useNavigate();
  const {getUser} = useContext(AccountContext);
  const hasFetched = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectPaymentLink, setPaymentLink] = useState<any>("");
  const [isComplete, setIsComplete] = useState(false);
  useEffect(() => {
    const fetchBB = async () => {
      if (hasFetched.current) return;
      hasFetched.current = true;

      try {
        const response:any = await API.graphql({query: listCompanies, authMode: "AMAZON_COGNITO_USER_POOLS"});
        if(response != undefined) {
          const myCompany:Company = response.data.listCompanies.items[0];
          const subscriptionId = myCompany.SubscriptionId;
          await verifyPayment(subscriptionId);
        }
      } catch (error) {
        throw error;
      }
    };

    fetchBB();
  }, []);

  const verifyPayment = async (id: any) => {
    try {
      const user = getUser();
      const userSession = user.signInUserSession;
      const idToken = userSession.idToken.jwtToken;
      const itm = await fetch("https://vsqwc4gr9f.execute-api.us-east-1.amazonaws.com/Test/company/payment-success",
        {
          mode: "cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": idToken,
          },

          body: JSON.stringify({
            sessionId: id,
            userId: user.username
          }),
      });
      const data = await itm.json();
      console.log(data);
      if(data.statusCode === 200) {
        setIsComplete(true);
      } else if (data.statusCode === 401) {
        setPaymentLink(data.url);
        setIsComplete(false);
      }
      // if(data.code === 401) {
      //   s
      //   
      // } else if (data.code === 200) {
      //   setIsComplete(true);
      // } else if (data.code === 201) {
      //   navigate('/dashboard', {replace: true});
      // }
      setIsLoading(false);
    } catch(e) {
      console.log(e);
    }   
  }

  const navigateToPayment = () => {
    //console.log(redirectPaymentLink);
    window.location = redirectPaymentLink;
  }
  

  return (
    isLoading ? <LoadingFull text="Fetching Error..."/> : isComplete ? <>
      <Fireworks autorun={{ speed: 2, duration: 800 }}/>
      <div className='box-content-center-membersip-success'>
        <Reveal> 
          <div className='failed-payment-box-container'>
            <div className="success-animation">
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
            </div>
            <div>
              <h1 className='dark-color-text'>Payment Confirmed</h1>
              <p>Your payment was successfully processed. A confirmation email has been sent. If you need assistance, our support team is here to help.</p>
            </div>
            <Button label='Go to Dashboard' loading={false} onClick={() => navigate('/dashboard', {replace: true})}></Button>
          </div>
        </Reveal>
      </div>
    </> : <>
      <div className='box-content-center-membersip-success failed-payment-box'>
        <Reveal>
          <div className='failed-payment-box-container'>
            <img src={failedPayment} />
            <div>
              <h1>Payment Pending</h1>
              <p>Your payment could not be completed. Please click the button below to return to the payment page and try again.</p>
            </div>
            <Button label='Pay to Continue' loading={false} onClick={navigateToPayment}></Button>
          </div>
        </Reveal>
      </div>
    </>
  )
}
