import { Rates, RateType } from "../API";
import {GLOBAL_RATE_TYPE} from "../Interfaces/IRateForm";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { CSVGeneratorFactory } from "./GeneratorFactory/GeneratorFactory";

export class CSVProcessor {
  private expectedHeaders: string[];

  constructor(expectedHeader: string[]) {
    this.expectedHeaders = expectedHeader;
  }

  private removeEmptyRows = (data: any[][]): any[][] => {
    return data.filter(row => row.some(cell => cell !== null && cell !== ''));
  };

  private excelDateToJSDate = (serial: number): string => {
    const date_info = new Date(Date.UTC(0, 0, serial - 1, 17)).toISOString();
    return date_info.split("T")[0];
  };

  public validateCSV(results: any[][]): { isValid: boolean, error?: string, data?: any[][] } {
    const cleanedData = this.removeEmptyRows(results);
    const headers = cleanedData[0].map(header => {
      // Remove special characters and whitespace
      let cleanedHeader = header.replace(/[\r\n]/g, '').trim();
    
      return cleanedHeader;
    });

    if (headers.length !== this.expectedHeaders.length) {
      return { isValid: false, error: "Invalid file upload, Please upload data in proper format" };
    }

    for (let i = 0; i < this.expectedHeaders.length; i++) {
      if (headers[i].toLowerCase().replace(/\s+/g, '') !== this.expectedHeaders[i].toLowerCase().replace(/\s+/g, '')) {
        return { isValid: false, error: `Header mismatch at position ${i + 1}. Expected: "${this.expectedHeaders[i]}", found: "${headers[i]}"` };
      }
    }

    const data = cleanedData.slice(1);
    
    if (data.length <= 0) {
      return { isValid: false, error: "Invalid file upload, ERROR: No Rates found" };
    }

    for(let i = 0; i < data.length; i++) {
      if(data[i].length !== this.expectedHeaders.length) {
        return { isValid: false, error: `Row mismatch at position ${i + 1}. Expected length: "${this.expectedHeaders.length}", found: "${data[i].length}"` };
      }
    }

    return { isValid: true, data: data };
  }

  public processData(results: any[][], initialObj: any): GLOBAL_RATE_TYPE[] {
    const cleanedData = this.removeEmptyRows(results);

    return cleanedData.map((entry: any[]): any => {
      const keys = Object.keys(initialObj);
      const dataObj: any = { ...initialObj };

      keys.forEach((key, index) => {
        if (typeof entry[index] === 'number' && (key === 'startDate' || key === 'endDate')) {
          dataObj[key] = this.excelDateToJSDate(entry[index]);
        } else {
          dataObj[key] = entry[index];
        }
      });

      return dataObj;
    });
  }


  public async generateData(tsMode: RateType, data: Rates[], companyData: any) {
    const zip = new JSZip();
    const generators = CSVGeneratorFactory.getGenerator(tsMode);
    
    generators.forEach(generator => {
      const csvContent = generator.generateCsv(data, companyData);
      zip.file(generator.getFileName(), csvContent);
    });

    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, `${tsMode}_data.zip`);
  }
}