import React from 'react'
import { Reveal } from '../../../../Components/Revel/Revel'
import Button from '../../../../Components/Button/Button'

export const Finish = (prop: any) => {
  return (
    <div className='welcome-box-setup-account'>
      <Reveal>
        <h2>You're All Set!</h2>
      </Reveal>
      <Reveal delay={.200}>
        <p>With RateGenie, you’re using the world's most innovative and efficient platform for managing rates on OTM. Say goodbye to the hassle and hello to seamless, streamlined operations! 🚀</p>
      </Reveal>
      <Reveal delay={.400}>
        <Button loading={false} label='Close' onClick={() => prop.closeSetup()}></Button>
      </Reveal>
    </div>
  )
}
