import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import "./style.css";
import {Link, useNavigate, } from 'react-router-dom';

import { AccountContext } from '../../Context/AuthContext';
import { LoadingFull } from '../../Components/PreLoader/Loading';

export const Auth = () => {
  const {getUser, isLoading} = useContext(AccountContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   

    const onLoad = () => {
      const dataUser = getUser();
      if(dataUser !== null) {
        if(dataUser.attributes['custom:accountType'] === "CARRIER") {
          navigate('/carrier', {replace: true});
        } else {
          navigate('/dashboard', {replace: true});
        }
      } else {
        setLoading(false);
      }
    }

    if(!isLoading) {
      onLoad();
    }
  }, [navigate, getUser]);

  return (
    loading ? <LoadingFull text="Loading..."/> : <div className='main-auth-container'>

      <button className='back-button-left-side-auth' onClick={() => navigate('/')}><i className="bi bi-chevron-left"></i> Back</button>
      <div className='line-container-style'>
        <div className='lines'></div>
        <div className='lines'></div>
        <div className='lines'></div>
        <div className='lines'></div>
        <div className='lines'></div>
        <div className='lines'></div>
        <div className='lines'></div>
      </div>
      <div className='right-side-auth-main'>
        <Outlet />
      </div>
    </div>
  )
}
