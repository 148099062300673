import React from 'react'
import "./style.css";
export const LoadingFull = (prop: any) => {
  return (
    <div className='loading-full-screen'>
      <span className="loader-spin fullSpinner"></span>
      <h1>{prop.text}</h1>
    </div>
  )
}

export const LoadingSpinner = () => {
  return (
    <span className="loader-spin"></span>
  )
}