import React from 'react'
import { LoadingFull } from '../../../Components/PreLoader/Loading'
import Moment from 'react-moment'
import { Status } from '../../../API'
import { Link } from 'react-router-dom'

export const CarrierTable = (prop: any) => {
  return (
    prop.isLoading ? <LoadingFull /> : prop.business?.CarrierInvitations != undefined && prop.business?.CarrierInvitations?.items.length > 0 ? <div className="row mt-4">
      <div className='card'>
      <table className='table-of-items activity-table'>
        <thead>
          <th>Department <i className="bi bi-arrow-down"></i></th>
          <th>Created Date</th>
          <th>Status</th>
          <th></th>
        </thead>
        <tbody>
          {prop.business?.CarrierInvitations?.items.map((item: any, index: any) => (
            <tr key={index}>
              <td>
                <div className='carrier-info-table-box'>
                  <h4>{item?.inviteeEmail}</h4>
                  <p>{item?.id}</p>
                </div>
              </td>
              
              <td><Moment format='DD MMM, YYYY'>{item?.createdAt}</Moment></td>
              <td>
                {item?.status === Status.PENDING && <span className='light-box'>{item?.status}</span>}
                {item?.status === Status.ACCEPTED && <span className='light-box light-color btn-success'>{item?.status}</span>}
                {item?.status === Status.REJECTED && <span className='light-box light-color btn-danger'>{item?.status}</span>}
              </td>
              <td className='last-item-table'>
                {item?.status === Status.PENDING && <span className='light-box success-box'>Invitation Sent</span>}
                {item?.status === Status.ACCEPTED && <button className='light-box light-color btn-warning'>Request Removal</button>}
                {item?.status === Status.REJECTED && <button className='light-box light-color btn-danger'>Delete Invitation</button>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div> </div> : <div className='box-security-token mt-5'>
      <div className='no-result-found mt-5'>
          <div>
            <i className="bi bi-people"></i>
            <h2>No Carriers Invited</h2>
            <p> You haven't invited any carriers yet. To start working with carriers, send out invitations from your dashboard.</p>
            <Link to="/">Learn More <i className="bi bi-arrow-right"></i></Link>
          </div>
        </div> 
      </div>
  )
}
