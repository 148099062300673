import React,{useContext, useEffect, useState} from 'react'
import "./style.css";
import { Subscription } from './tabs/Subscription';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { Company } from '../../../API';
import { AccountContext } from "../../../Context/AuthContext";
interface DashboardContext {
  business: Company | null;
}

export const Settings = () => {
  const navigation = useNavigate();
  const {business} = useOutletContext<DashboardContext>();
  const {getUser} = useContext(AccountContext);
  const [paymentInfo,setPaymentInfo] = useState();
  useEffect( () =>{
    verifyPayment();
  },[])
  const verifyPayment = async () => {
    try {
      const user = getUser();
      const userSession = user.signInUserSession;
      const idToken = userSession.idToken.jwtToken;
      const itm = await fetch("https://vsqwc4gr9f.execute-api.us-east-1.amazonaws.com/Test/company/get-billing",
        {
          mode: "cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": idToken,
          },

          body: JSON.stringify({
            userId: user.username
          }),
      });
      const data = await itm.json();
      setPaymentInfo(data);
      console.log(data);
      // if(data.code === 401) {
      //   s
      //   
      // } else if (data.code === 200) {
      //   setIsComplete(true);
      // } else if (data.code === 201) {
      //   navigate('/dashboard', {replace: true});
      // }
    } catch(e) {
      //console.log(e);
    }   
  }
  return (
    <div className='container-xxl flex-grow-1 container-p-y'>
      <div className='row'>
        <div className='card'>
          <div className='header-setting-section'>
            <h2>Settings</h2>
          </div>
          <div className='content-inside-setting'>
            <div className='side-bar-setting'>
              <ul>
                <li onClick={() => navigation('')}>General</li>
                <li onClick={() => navigation('security')}>Security</li>
                <li onClick={() => navigation('subscription')}>Plan & Subscription</li>
                <li onClick={() => navigation('billing')}>Billing</li>
              </ul>
            </div>
            <div className='outlet-setting-box'>
              <Outlet context={{business,paymentInfo}}/>
              {/* <Subscription business={business}/> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
