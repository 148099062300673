import React, { useContext, useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { TbSmartHome, TbFileSpreadsheet, TbUsersPlus, TbUser, TbListTree, TbSettings } from "react-icons/tb";
import { VscLink } from "react-icons/vsc";
import { GiCarrier } from "react-icons/gi";

import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../Context/AuthContext';
import logo from "../../../assets/img/logo.png";
import Button from '../../../Components/Button/Button';

const SideBar: React.FC = () => {
    const navigate = useNavigate()
    const {logout, setIsLoggingOut} = useContext(AccountContext);
    // const [activeTab, setActiveTab] = useState<string>();
    const [subMenu, setSubMenu] = useState(false)

    // const handleTabClick = (tab: string) => {
    //     setActiveTab(tab);
    // };



  return (
    <aside id="layout-menu" className="sidebar-dashboard layout-menu menu-vertical menu bg-menu-theme" data-bg-className="bg-menu-theme">
      <div className="app-brand demo">
        <Link to="/dashboard" className="app-brand-link">
          <img src={logo} width={180}/>
        </Link>

      </div>
      <div className="menu-inner-shadow"></div>
      <ul className="menu-inner ps ps--active-y side-bar-dash">
        <li className="menu-item upload-button-main">
          <Button label="Invite Carrier" loading={false} icon={<i className="bi bi-send"></i>}></Button>
        </li>
      <li className="menu-item">
          <Link to="/dashboard" className="menu-link"
        //   onClick={() => handleTabClick('tab1')}
        //   className={`menu-link menu-toggle ${activeTab === 'tab1' ? 'active' : ''}`}
          >
            <TbSmartHome size={22}/>
            <div>Dashboards</div>
          </Link>
        </li>
      <li className="menu-item">
        <Link to="pricing-sheets" className="menu-link"
        // onClick={() => handleTabClick('tab2')}
        // className={`menu-link menu-toggle ${activeTab === 'tab2'? 'active' : ''}`}
        >
            <TbFileSpreadsheet  size={22}/>
            <div>Rate Inquiries</div>
        </Link>
      </li>
      <li className="menu-item">
        <Link to="carriers" className="menu-link"
        // onClick={() => handleTabClick('tab2')}
        // className={`menu-link menu-toggle ${activeTab === 'tab2'? 'active' : ''}`}
        >
            <GiCarrier size={22}/>
            <div>Carriers</div>
        </Link>
      </li>
      <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="Forms &amp; Tables">Company</span>
      </li>
      <li className="menu-item">
        <Link to="otm-management" className="menu-link"
        // onClick={() => handleTabClick('tab2')}
        // className={`menu-link menu-toggle ${activeTab === 'tab2'? 'active' : ''}`}
        >
          <VscLink  size={22}/>
            <div>OTM Management</div>
        </Link>
      </li>
      <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="Forms &amp; Tables">Systems</span>
      </li>
      <li className="menu-item">
        <Link to="user-list" className="menu-link"
        // onClick={() => handleTabClick('tab2')}
        // className={`menu-link menu-toggle ${activeTab === 'tab2'? 'active' : ''}`}
        >
          <TbUser  size={22}/>
            <div>User Management</div>
        </Link>
      </li>
      {/* <li onClick={()=> setSubMenu(true)}
      className={`menu-item ${subMenu == true ? 'open' : ''}`}>
        <a href="#"
        className="menu-link menu-toggle">
            <TbUser  size={22}/>
            <div>Users</div>
        </a>
        <ul className="menu-sub">
        <li className="menu-item">
              <Link to="user-list" className="menu-link"
            //   onClick={() => handleTabClick('tab4')}
            //   className={`menu-link ${activeTab === 'tab4'? 'active' : ''}`}
              >
                  <TbListTree/>
                <div data-i18n="ChartJS">User List</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="add-user" className="menu-link "
            //   onClick={() => handleTabClick('tab3')}
            //   className={`menu-link ${activeTab === 'tab3'? 'active' : ''}`}
              >
                  <TbUsersPlus/>
                <div data-i18n="ChartJS">Add User</div>
              </Link>
            </li>
          </ul>
      </li> */}
      <li className="menu-item">
        <Link to="settings" className="menu-link"
        // onClick={() => handleTabClick('tab2')}
        // className={`menu-link menu-toggle ${activeTab === 'tab2'? 'active' : ''}`}
        > 
        <TbSettings size={22}/>
            <div>Settings</div>
        </Link>
      </li>
      <li className="menu-item log">
              <Link to="#" className="menu-link"
              onClick={() => setIsLoggingOut(true)}
            //   className={`menu-link ${activeTab === 'tab3'? 'active' : ''}`}
              >
                <div data-i18n="ChartJS">Logout</div>
              </Link>
            </li>
      </ul>
    </aside>
  )
}

export default SideBar