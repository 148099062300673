export const formatCurrency = (amount, currency = 'USD') => {
  // Convert amount from cents to dollars
  const amountInDollars = amount;
  // Format currency
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amountInDollars);
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's current time zone
  return date.toLocaleString('en-US', {
    timeZone: userTimeZone, // Use the user's time zone
    year: 'numeric',
    month: 'long', // Use 'long' to get the full month name (e.g., 'January')
  });
};