import { GLOBAL_RATE_TYPE } from "../../../Interfaces/IRateForm";
import { ICSVGenerator } from "../../GeneratorFactory/GeneratorFactory";
import { Rates } from "../../../API";

export class FTLGenerator_Rate_Geo_Cost_Group implements ICSVGenerator {
  generateCsv(data: Rates[], companyData: any): string {
    const headers = [
      { label: "RATE_GEO_COST_GROUP_GID", key: "RATE_GEO_COST_GROUP_GID" },
      { label: "RATE_GEO_COST_GROUP_XID", key: "RATE_GEO_COST_GROUP_XID" },
      { label: "RATE_GEO_GID", key: "RATE_GEO_GID" },
      { label: "RATE_GEO_COST_GROUP_SEQ", key: "RATE_GEO_COST_GROUP_SEQ" },
      { label: "GROUP_NAME", key: "GROUP_NAME" },
      { label: "DEFICIT_CALCULATIONS_TYPE", key: "DEFICIT_CALCULATIONS_TYPE" },
      { label: "MULTI_RATES_RULE", key: "MULTI_RATES_RULE" },
      { label: "RATE_GROUP_TYPE", key: "RATE_GROUP_TYPE" },
      { label: "ROUNDING_TYPE", key: "ROUNDING_TYPE" },
      { label: "ROUNDING_INTERVAL", key: "ROUNDING_INTERVAL" },
      { label: "ROUNDING_FIELDS_LEVEL", key: "ROUNDING_FIELDS_LEVEL" },
      { label: "ROUNDING_APPLICATION", key: "ROUNDING_APPLICATION" },
      { label: "DOMAIN_NAME", key: "DOMAIN_NAME" },
    ];

    const csv_data = [
      {
        RATE_GEO_COST_GROUP_GID: "EXEC SQL ALTER SESSION SET NLS_DATE_FORMAT = 'YYYYMMDDHH24MISS'",
        RATE_GEO_COST_GROUP_XID: "",
        RATE_GEO_GID: "",
        RATE_GEO_COST_GROUP_SEQ: "",
        GROUP_NAME: "",
        DEFICIT_CALCULATIONS_TYPE: "",
        MULTI_RATES_RULE: "",
        RATE_GROUP_TYPE: "",
        ROUNDING_TYPE: "",
        ROUNDING_INTERVAL: "",
        ROUNDING_FIELDS_LEVEL: "",
        ROUNDING_APPLICATION: "",
        DOMAIN_NAME: ""
      },
    ];

    for(let i = 0; i < data.length; i++) {
      const file:GLOBAL_RATE_TYPE = JSON.parse(data[i].data || "");
      if(file) {
        const singleRow = {
          RATE_GEO_COST_GROUP_GID: `${companyData.domain}/${companyData.subDomain}.${file.ff}_${companyData.tsMode}_${file.laneId}`,
          RATE_GEO_COST_GROUP_XID: `${file.ff}_${companyData.tsMode}_${file.laneId}`,
          RATE_GEO_GID: `${companyData.domain}/${companyData.subDomain}.${file.ff}_${companyData.tsMode}_${file.laneId}`,
          RATE_GEO_COST_GROUP_SEQ: ``,
          GROUP_NAME: "",
          DEFICIT_CALCULATIONS_TYPE: "",
          MULTI_RATES_RULE: "A",
          RATE_GROUP_TYPE: "B",
          ROUNDING_TYPE: "N",
          ROUNDING_INTERVAL: "",
          ROUNDING_FIELDS_LEVEL: "0",
          ROUNDING_APPLICATION: "A",
          DOMAIN_NAME: `${companyData.domain}/${companyData.subDomain}`
        }

        csv_data.push(singleRow);
      }
    }
    // const csvData = data.map((entry) => ({
    //   X_LANE: entry.X_LANE || "",
    //   X_LANE_GID: entry.X_LANE_GID || "",
    //   X_LANE_XID: entry.X_LANE_XID || "",
    //   // map more fields as needed
    // }));
    
    return this.convertToCsv(headers, csv_data);
  }

  getFileName(): string {
    return "4_RATE_GEO_COST_GROUP.csv";
  }

  private convertToCsv(headers: any[], data: any[]): string {
    const headerRow = headers.map(header => header.label).join(",");
    const rows = data.map(row => headers.map(header => row[header.key] || "").join(",")).join("\n");
    return `${headerRow}\n${rows}`;
  }
}