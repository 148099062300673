/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createPaymentResult = /* GraphQL */ `mutation CreatePaymentResult(
  $input: CreatePaymentResultInput!
  $condition: ModelPaymentResultConditionInput
) {
  createPaymentResult(input: $input, condition: $condition) {
    id
    billinghistoryID
    Invoice
    date
    transactionStatus
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentResultMutationVariables,
  APITypes.CreatePaymentResultMutation
>;
export const updatePaymentResult = /* GraphQL */ `mutation UpdatePaymentResult(
  $input: UpdatePaymentResultInput!
  $condition: ModelPaymentResultConditionInput
) {
  updatePaymentResult(input: $input, condition: $condition) {
    id
    billinghistoryID
    Invoice
    date
    transactionStatus
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentResultMutationVariables,
  APITypes.UpdatePaymentResultMutation
>;
export const deletePaymentResult = /* GraphQL */ `mutation DeletePaymentResult(
  $input: DeletePaymentResultInput!
  $condition: ModelPaymentResultConditionInput
) {
  deletePaymentResult(input: $input, condition: $condition) {
    id
    billinghistoryID
    Invoice
    date
    transactionStatus
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentResultMutationVariables,
  APITypes.DeletePaymentResultMutation
>;
export const createRateBatch = /* GraphQL */ `mutation CreateRateBatch(
  $input: CreateRateBatchInput!
  $condition: ModelRateBatchConditionInput
) {
  createRateBatch(input: $input, condition: $condition) {
    id
    Rates {
      items {
        id
        tsMode
        data
        scac
        serviceName
        startDate
        endDate
        originCity
        originCountry
        originProvince
        destinationCountry
        destinationCity
        destinationProvince
        originRegion
        destinationRegion
        ownerId
        ownerEmail
        ratebatchID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    companyID
    carrierID
    status
    tsMode
    scac
    startDate
    endDate
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRateBatchMutationVariables,
  APITypes.CreateRateBatchMutation
>;
export const updateRateBatch = /* GraphQL */ `mutation UpdateRateBatch(
  $input: UpdateRateBatchInput!
  $condition: ModelRateBatchConditionInput
) {
  updateRateBatch(input: $input, condition: $condition) {
    id
    Rates {
      items {
        id
        tsMode
        data
        scac
        serviceName
        startDate
        endDate
        originCity
        originCountry
        originProvince
        destinationCountry
        destinationCity
        destinationProvince
        originRegion
        destinationRegion
        ownerId
        ownerEmail
        ratebatchID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    companyID
    carrierID
    status
    tsMode
    scac
    startDate
    endDate
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRateBatchMutationVariables,
  APITypes.UpdateRateBatchMutation
>;
export const deleteRateBatch = /* GraphQL */ `mutation DeleteRateBatch(
  $input: DeleteRateBatchInput!
  $condition: ModelRateBatchConditionInput
) {
  deleteRateBatch(input: $input, condition: $condition) {
    id
    Rates {
      items {
        id
        tsMode
        data
        scac
        serviceName
        startDate
        endDate
        originCity
        originCountry
        originProvince
        destinationCountry
        destinationCity
        destinationProvince
        originRegion
        destinationRegion
        ownerId
        ownerEmail
        ratebatchID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    companyID
    carrierID
    status
    tsMode
    scac
    startDate
    endDate
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRateBatchMutationVariables,
  APITypes.DeleteRateBatchMutation
>;
export const createUserInvitation = /* GraphQL */ `mutation CreateUserInvitation(
  $input: CreateUserInvitationInput!
  $condition: ModelUserInvitationConditionInput
) {
  createUserInvitation(input: $input, condition: $condition) {
    id
    inviteeEmail
    inviteeId
    companyID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserInvitationMutationVariables,
  APITypes.CreateUserInvitationMutation
>;
export const updateUserInvitation = /* GraphQL */ `mutation UpdateUserInvitation(
  $input: UpdateUserInvitationInput!
  $condition: ModelUserInvitationConditionInput
) {
  updateUserInvitation(input: $input, condition: $condition) {
    id
    inviteeEmail
    inviteeId
    companyID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserInvitationMutationVariables,
  APITypes.UpdateUserInvitationMutation
>;
export const deleteUserInvitation = /* GraphQL */ `mutation DeleteUserInvitation(
  $input: DeleteUserInvitationInput!
  $condition: ModelUserInvitationConditionInput
) {
  deleteUserInvitation(input: $input, condition: $condition) {
    id
    inviteeEmail
    inviteeId
    companyID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserInvitationMutationVariables,
  APITypes.DeleteUserInvitationMutation
>;
export const createBillingHistory = /* GraphQL */ `mutation CreateBillingHistory(
  $input: CreateBillingHistoryInput!
  $condition: ModelBillingHistoryConditionInput
) {
  createBillingHistory(input: $input, condition: $condition) {
    id
    amount
    status
    billingDate
    companyID
    startPeriod
    endPeriod
    PaymentResult {
      items {
        id
        billinghistoryID
        Invoice
        date
        transactionStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBillingHistoryMutationVariables,
  APITypes.CreateBillingHistoryMutation
>;
export const updateBillingHistory = /* GraphQL */ `mutation UpdateBillingHistory(
  $input: UpdateBillingHistoryInput!
  $condition: ModelBillingHistoryConditionInput
) {
  updateBillingHistory(input: $input, condition: $condition) {
    id
    amount
    status
    billingDate
    companyID
    startPeriod
    endPeriod
    PaymentResult {
      items {
        id
        billinghistoryID
        Invoice
        date
        transactionStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBillingHistoryMutationVariables,
  APITypes.UpdateBillingHistoryMutation
>;
export const deleteBillingHistory = /* GraphQL */ `mutation DeleteBillingHistory(
  $input: DeleteBillingHistoryInput!
  $condition: ModelBillingHistoryConditionInput
) {
  deleteBillingHistory(input: $input, condition: $condition) {
    id
    amount
    status
    billingDate
    companyID
    startPeriod
    endPeriod
    PaymentResult {
      items {
        id
        billinghistoryID
        Invoice
        date
        transactionStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBillingHistoryMutationVariables,
  APITypes.DeleteBillingHistoryMutation
>;
export const createCarrierCompanies = /* GraphQL */ `mutation CreateCarrierCompanies(
  $input: CreateCarrierCompaniesInput!
  $condition: ModelCarrierCompaniesConditionInput
) {
  createCarrierCompanies(input: $input, condition: $condition) {
    id
    Name
    companyID
    carrierID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCarrierCompaniesMutationVariables,
  APITypes.CreateCarrierCompaniesMutation
>;
export const updateCarrierCompanies = /* GraphQL */ `mutation UpdateCarrierCompanies(
  $input: UpdateCarrierCompaniesInput!
  $condition: ModelCarrierCompaniesConditionInput
) {
  updateCarrierCompanies(input: $input, condition: $condition) {
    id
    Name
    companyID
    carrierID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCarrierCompaniesMutationVariables,
  APITypes.UpdateCarrierCompaniesMutation
>;
export const deleteCarrierCompanies = /* GraphQL */ `mutation DeleteCarrierCompanies(
  $input: DeleteCarrierCompaniesInput!
  $condition: ModelCarrierCompaniesConditionInput
) {
  deleteCarrierCompanies(input: $input, condition: $condition) {
    id
    Name
    companyID
    carrierID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCarrierCompaniesMutationVariables,
  APITypes.DeleteCarrierCompaniesMutation
>;
export const createRates = /* GraphQL */ `mutation CreateRates(
  $input: CreateRatesInput!
  $condition: ModelRatesConditionInput
) {
  createRates(input: $input, condition: $condition) {
    id
    tsMode
    data
    scac
    serviceName
    startDate
    endDate
    originCity
    originCountry
    originProvince
    destinationCountry
    destinationCity
    destinationProvince
    originRegion
    destinationRegion
    ownerId
    ownerEmail
    ratebatchID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRatesMutationVariables,
  APITypes.CreateRatesMutation
>;
export const updateRates = /* GraphQL */ `mutation UpdateRates(
  $input: UpdateRatesInput!
  $condition: ModelRatesConditionInput
) {
  updateRates(input: $input, condition: $condition) {
    id
    tsMode
    data
    scac
    serviceName
    startDate
    endDate
    originCity
    originCountry
    originProvince
    destinationCountry
    destinationCity
    destinationProvince
    originRegion
    destinationRegion
    ownerId
    ownerEmail
    ratebatchID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRatesMutationVariables,
  APITypes.UpdateRatesMutation
>;
export const deleteRates = /* GraphQL */ `mutation DeleteRates(
  $input: DeleteRatesInput!
  $condition: ModelRatesConditionInput
) {
  deleteRates(input: $input, condition: $condition) {
    id
    tsMode
    data
    scac
    serviceName
    startDate
    endDate
    originCity
    originCountry
    originProvince
    destinationCountry
    destinationCity
    destinationProvince
    originRegion
    destinationRegion
    ownerId
    ownerEmail
    ratebatchID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRatesMutationVariables,
  APITypes.DeleteRatesMutation
>;
export const createDepartments = /* GraphQL */ `mutation CreateDepartments(
  $input: CreateDepartmentsInput!
  $condition: ModelDepartmentsConditionInput
) {
  createDepartments(input: $input, condition: $condition) {
    id
    DepartmentName
    DepartmentSCAC
    companyID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDepartmentsMutationVariables,
  APITypes.CreateDepartmentsMutation
>;
export const updateDepartments = /* GraphQL */ `mutation UpdateDepartments(
  $input: UpdateDepartmentsInput!
  $condition: ModelDepartmentsConditionInput
) {
  updateDepartments(input: $input, condition: $condition) {
    id
    DepartmentName
    DepartmentSCAC
    companyID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDepartmentsMutationVariables,
  APITypes.UpdateDepartmentsMutation
>;
export const deleteDepartments = /* GraphQL */ `mutation DeleteDepartments(
  $input: DeleteDepartmentsInput!
  $condition: ModelDepartmentsConditionInput
) {
  deleteDepartments(input: $input, condition: $condition) {
    id
    DepartmentName
    DepartmentSCAC
    companyID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDepartmentsMutationVariables,
  APITypes.DeleteDepartmentsMutation
>;
export const createCarrier = /* GraphQL */ `mutation CreateCarrier(
  $input: CreateCarrierInput!
  $condition: ModelCarrierConditionInput
) {
  createCarrier(input: $input, condition: $condition) {
    id
    Email
    Phone
    Address {
      Address
      City
      Country
      Zipcode
      State
      __typename
    }
    ServiceName
    SCAC
    CarrierInvitations {
      items {
        id
        InviterId
        inviteeEmail
        status
        inviteeId
        CompanyName
        companyID
        carrierID
        InviterEmail
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    CarrierCompanies {
      items {
        id
        Name
        companyID
        carrierID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    RateBatches {
      items {
        id
        Rates {
          items {
            id
            tsMode
            data
            scac
            serviceName
            startDate
            endDate
            originCity
            originCountry
            originProvince
            destinationCountry
            destinationCity
            destinationProvince
            originRegion
            destinationRegion
            ownerId
            ownerEmail
            ratebatchID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyID
        carrierID
        status
        tsMode
        scac
        startDate
        endDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCarrierMutationVariables,
  APITypes.CreateCarrierMutation
>;
export const updateCarrier = /* GraphQL */ `mutation UpdateCarrier(
  $input: UpdateCarrierInput!
  $condition: ModelCarrierConditionInput
) {
  updateCarrier(input: $input, condition: $condition) {
    id
    Email
    Phone
    Address {
      Address
      City
      Country
      Zipcode
      State
      __typename
    }
    ServiceName
    SCAC
    CarrierInvitations {
      items {
        id
        InviterId
        inviteeEmail
        status
        inviteeId
        CompanyName
        companyID
        carrierID
        InviterEmail
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    CarrierCompanies {
      items {
        id
        Name
        companyID
        carrierID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    RateBatches {
      items {
        id
        Rates {
          items {
            id
            tsMode
            data
            scac
            serviceName
            startDate
            endDate
            originCity
            originCountry
            originProvince
            destinationCountry
            destinationCity
            destinationProvince
            originRegion
            destinationRegion
            ownerId
            ownerEmail
            ratebatchID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyID
        carrierID
        status
        tsMode
        scac
        startDate
        endDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCarrierMutationVariables,
  APITypes.UpdateCarrierMutation
>;
export const deleteCarrier = /* GraphQL */ `mutation DeleteCarrier(
  $input: DeleteCarrierInput!
  $condition: ModelCarrierConditionInput
) {
  deleteCarrier(input: $input, condition: $condition) {
    id
    Email
    Phone
    Address {
      Address
      City
      Country
      Zipcode
      State
      __typename
    }
    ServiceName
    SCAC
    CarrierInvitations {
      items {
        id
        InviterId
        inviteeEmail
        status
        inviteeId
        CompanyName
        companyID
        carrierID
        InviterEmail
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    CarrierCompanies {
      items {
        id
        Name
        companyID
        carrierID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    RateBatches {
      items {
        id
        Rates {
          items {
            id
            tsMode
            data
            scac
            serviceName
            startDate
            endDate
            originCity
            originCountry
            originProvince
            destinationCountry
            destinationCity
            destinationProvince
            originRegion
            destinationRegion
            ownerId
            ownerEmail
            ratebatchID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyID
        carrierID
        status
        tsMode
        scac
        startDate
        endDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCarrierMutationVariables,
  APITypes.DeleteCarrierMutation
>;
export const createCarrierInvitation = /* GraphQL */ `mutation CreateCarrierInvitation(
  $input: CreateCarrierInvitationInput!
  $condition: ModelCarrierInvitationConditionInput
) {
  createCarrierInvitation(input: $input, condition: $condition) {
    id
    InviterId
    inviteeEmail
    status
    inviteeId
    CompanyName
    companyID
    carrierID
    InviterEmail
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCarrierInvitationMutationVariables,
  APITypes.CreateCarrierInvitationMutation
>;
export const updateCarrierInvitation = /* GraphQL */ `mutation UpdateCarrierInvitation(
  $input: UpdateCarrierInvitationInput!
  $condition: ModelCarrierInvitationConditionInput
) {
  updateCarrierInvitation(input: $input, condition: $condition) {
    id
    InviterId
    inviteeEmail
    status
    inviteeId
    CompanyName
    companyID
    carrierID
    InviterEmail
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCarrierInvitationMutationVariables,
  APITypes.UpdateCarrierInvitationMutation
>;
export const deleteCarrierInvitation = /* GraphQL */ `mutation DeleteCarrierInvitation(
  $input: DeleteCarrierInvitationInput!
  $condition: ModelCarrierInvitationConditionInput
) {
  deleteCarrierInvitation(input: $input, condition: $condition) {
    id
    InviterId
    inviteeEmail
    status
    inviteeId
    CompanyName
    companyID
    carrierID
    InviterEmail
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCarrierInvitationMutationVariables,
  APITypes.DeleteCarrierInvitationMutation
>;
export const createCompany = /* GraphQL */ `mutation CreateCompany(
  $input: CreateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  createCompany(input: $input, condition: $condition) {
    id
    Name
    PhoneNumber
    Address {
      Address
      City
      Country
      Zipcode
      State
      __typename
    }
    Domain
    CarrierInvitations {
      items {
        id
        InviterId
        inviteeEmail
        status
        inviteeId
        CompanyName
        companyID
        carrierID
        InviterEmail
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    Departments {
      items {
        id
        DepartmentName
        DepartmentSCAC
        companyID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    SubscriptionStatus
    SubscriptionEndDate
    BillingHistories {
      items {
        id
        amount
        status
        billingDate
        companyID
        startPeriod
        endPeriod
        PaymentResult {
          items {
            id
            billinghistoryID
            Invoice
            date
            transactionStatus
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    UserInvitations {
      items {
        id
        inviteeEmail
        inviteeId
        companyID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    MaxSourcingUsers
    MaxCarrierUsers
    CurrentSourcingUsers
    CurrentCarrierUsers
    SubscriptionId
    Email
    RateBatches {
      items {
        id
        Rates {
          items {
            id
            tsMode
            data
            scac
            serviceName
            startDate
            endDate
            originCity
            originCountry
            originProvince
            destinationCountry
            destinationCity
            destinationProvince
            originRegion
            destinationRegion
            ownerId
            ownerEmail
            ratebatchID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyID
        carrierID
        status
        tsMode
        scac
        startDate
        endDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    SubscriptionNextBillCost
    PaymentNextRetryDate
    CustomerId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyMutationVariables,
  APITypes.CreateCompanyMutation
>;
export const updateCompany = /* GraphQL */ `mutation UpdateCompany(
  $input: UpdateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  updateCompany(input: $input, condition: $condition) {
    id
    Name
    PhoneNumber
    Address {
      Address
      City
      Country
      Zipcode
      State
      __typename
    }
    Domain
    CarrierInvitations {
      items {
        id
        InviterId
        inviteeEmail
        status
        inviteeId
        CompanyName
        companyID
        carrierID
        InviterEmail
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    Departments {
      items {
        id
        DepartmentName
        DepartmentSCAC
        companyID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    SubscriptionStatus
    SubscriptionEndDate
    BillingHistories {
      items {
        id
        amount
        status
        billingDate
        companyID
        startPeriod
        endPeriod
        PaymentResult {
          items {
            id
            billinghistoryID
            Invoice
            date
            transactionStatus
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    UserInvitations {
      items {
        id
        inviteeEmail
        inviteeId
        companyID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    MaxSourcingUsers
    MaxCarrierUsers
    CurrentSourcingUsers
    CurrentCarrierUsers
    SubscriptionId
    Email
    RateBatches {
      items {
        id
        Rates {
          items {
            id
            tsMode
            data
            scac
            serviceName
            startDate
            endDate
            originCity
            originCountry
            originProvince
            destinationCountry
            destinationCity
            destinationProvince
            originRegion
            destinationRegion
            ownerId
            ownerEmail
            ratebatchID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyID
        carrierID
        status
        tsMode
        scac
        startDate
        endDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    SubscriptionNextBillCost
    PaymentNextRetryDate
    CustomerId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyMutationVariables,
  APITypes.UpdateCompanyMutation
>;
export const deleteCompany = /* GraphQL */ `mutation DeleteCompany(
  $input: DeleteCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  deleteCompany(input: $input, condition: $condition) {
    id
    Name
    PhoneNumber
    Address {
      Address
      City
      Country
      Zipcode
      State
      __typename
    }
    Domain
    CarrierInvitations {
      items {
        id
        InviterId
        inviteeEmail
        status
        inviteeId
        CompanyName
        companyID
        carrierID
        InviterEmail
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    Departments {
      items {
        id
        DepartmentName
        DepartmentSCAC
        companyID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    SubscriptionStatus
    SubscriptionEndDate
    BillingHistories {
      items {
        id
        amount
        status
        billingDate
        companyID
        startPeriod
        endPeriod
        PaymentResult {
          items {
            id
            billinghistoryID
            Invoice
            date
            transactionStatus
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    UserInvitations {
      items {
        id
        inviteeEmail
        inviteeId
        companyID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    MaxSourcingUsers
    MaxCarrierUsers
    CurrentSourcingUsers
    CurrentCarrierUsers
    SubscriptionId
    Email
    RateBatches {
      items {
        id
        Rates {
          items {
            id
            tsMode
            data
            scac
            serviceName
            startDate
            endDate
            originCity
            originCountry
            originProvince
            destinationCountry
            destinationCity
            destinationProvince
            originRegion
            destinationRegion
            ownerId
            ownerEmail
            ratebatchID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyID
        carrierID
        status
        tsMode
        scac
        startDate
        endDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    SubscriptionNextBillCost
    PaymentNextRetryDate
    CustomerId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyMutationVariables,
  APITypes.DeleteCompanyMutation
>;
