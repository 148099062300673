import React from 'react'
import InputField from '../../../../Components/InputField/InputField'
import Button from '../../../../Components/Button/Button'

export const Security = () => {
  return (
    <div className='general-box-setting'>
      <h3>Change Password</h3>
      <p>this are account setting for general account</p>

      <div className='single-col-box'>
        <div className='header-single-setting-box'>
          <h4>Old Password</h4>
          <p>This detail will be appearing on the website</p>
        </div>
        <InputField label='' name='name'/>
        
      </div>
      <div className='single-col-box'>
        <div className='header-single-setting-box'>
          <h4>New Password</h4>
          <p>This detail will be appearing on the website</p>
        </div>
        <InputField label='' name='email' />
        
      </div>
      <div className='single-col-box'>
        <div className='header-single-setting-box'>
          <h4>Confirm New Password</h4>
          <p>This detail will be appearing on the website</p>
        </div>
        <InputField label='' name='country'/>
        
      </div>

      <div>
        <Button label={"Save Changes"} loading={false} />
      </div>
    </div>
  )
}
