import React, { useContext, useEffect, useState } from 'react';
import { Route, Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { AccountContext } from '../../Context/AuthContext';
import { LoadingFull } from '../PreLoader/Loading';


const ProtectedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {isAuthenticated, getUser, isLoading} = useContext(AccountContext);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if(!isLoading) {
      onLoad();
    }
  }, [isLoading, isAuthenticated]);

  const onLoad = () => {
    if(!isAuthenticated) {
      const getPathInfo = location.pathname.split("/");
      if(getPathInfo[1] === "dashboard") {
        navigate("/auth/login", { replace: true, state: { from: location } });
      } else  {
        navigate("/auth/carrier/login", { replace: true, state: { from: location } });
      }
    } else {
      setLoading(false);
    }
  }

  return loading ? <LoadingFull text="Please Wait..."/> : <Outlet />;
};

export default ProtectedRoutes;
