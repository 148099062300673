import React, { useContext, useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { TbSmartHome, TbFileSpreadsheet, TbUsersPlus, TbUser, TbListTree, TbSettings } from "react-icons/tb";
import { VscLink } from "react-icons/vsc";
import { GiCarrier } from "react-icons/gi";

import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../../Context/AuthContext';
import logo from "../../../../assets/img/logo.png";
import Button from '../../../../Components/Button/Button';
import { CarrierInvitation, Status } from '../../../../API';

interface ISideBar {
  invitation: (CarrierInvitation | null)[] | undefined;
  setCreatingRate: (isCreatingRate: boolean) => void;
}

const SideBar = (prop: ISideBar) => {
  const navigate = useNavigate()
  const {logout, setIsLoggingOut} = useContext(AccountContext);
  const [subMenu, setSubMenu] = useState(false)
  const [invitationLength, setInvitationLength] = useState<number>(0);

  useEffect(() => { 
    setInvitationLength(prop.invitation?.filter(item => item?.status === Status.PENDING).length || 0);
  }, [prop.invitation]);



  return (
    <aside id="layout-menu" className="sidebar-dashboard layout-menu menu-vertical menu bg-menu-theme" data-bg-className="bg-menu-theme">
      <div className="app-brand demo">
        <Link to="/dashboard" className="app-brand-link">
          <img src={logo} width={130}/>
        </Link>
        {/* <h2>Welcome to Carrier Portal</h2> */}
      </div>
      <div className="menu-inner-shadow"></div>
      
      <ul className="menu-inner ps ps--active-y side-bar-dash">
        <li className="menu-item upload-button-main">
          <Button label="Upload Rates" loading={false} icon={<i className="bi bi-cloud-upload"></i>} onClick={() => prop.setCreatingRate(true)}></Button>
        </li>
      <li className="menu-item">
          <Link to="/carrier" className="menu-link"
        //   onClick={() => handleTabClick('tab1')}
        //   className={`menu-link menu-toggle ${activeTab === 'tab1' ? 'active' : ''}`}
          >
            <TbSmartHome size={22}/>
            <div>Dashboards</div>
          </Link>
        </li>
      <li className="menu-item">
        <Link to="my-rates" className="menu-link"
        // onClick={() => handleTabClick('tab2')}
        // className={`menu-link menu-toggle ${activeTab === 'tab2'? 'active' : ''}`}
        >
            <TbFileSpreadsheet  size={22}/>
            <div>My Rates</div>
        </Link>
      </li>
      <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="Forms &amp; Tables">Company</span>
      </li>
      <li className="menu-item">
        <Link to="invites" className="menu-link"
        // onClick={() => handleTabClick('tab2')}
        // className={`menu-link menu-toggle ${activeTab === 'tab2'? 'active' : ''}`}
        >
            <GiCarrier size={22}/>
            <div className='notification-menu'>Company Invites {invitationLength > 0 && <span>{invitationLength}</span>}</div>
        </Link>
      </li>
      <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="Forms &amp; Tables">Systems</span>
      </li>
      
      <li className="menu-item">
        <Link to="/pricing-sheet" className="menu-link"
        // onClick={() => handleTabClick('tab2')}
        // className={`menu-link menu-toggle ${activeTab === 'tab2'? 'active' : ''}`}
        > 
        <TbSettings size={22}/>
            <div>Settings</div>
        </Link>
      </li>
      <li className="menu-item log">
              <Link to="#" className="menu-link"
              onClick={() => setIsLoggingOut(true)}
            //   className={`menu-link ${activeTab === 'tab3'? 'active' : ''}`}
              >
                <div data-i18n="ChartJS">Logout</div>
              </Link>
            </li>
      </ul>
    </aside>
  )
}

export default SideBar