import React, { useContext, useState } from 'react'
import { Reveal } from '../../../Components/Revel/Revel'
import InputField from '../../../Components/InputField/InputField'
import Button from '../../../Components/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import { AccountContext } from '../../../Context/AuthContext';
import { toast } from 'react-toastify';
import { CountDown } from '../../../Components/CountDown/CountDown';

interface IResetPassword {
  userEmail: string
}

interface IErrorFields {
  code: string,
  password: string,
  rePassword: string
}

const INITIAL_ERROR = {
  code: "",
  password: "",
  rePassword: ""
}

export const ResetPassword = (props: IResetPassword) => {
  const navigate = useNavigate();
  const [timeRunning, setTimeRunning] = useState<boolean>(true);
  const [defaultTime, setDefaultTime] = useState<number>(30);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<IErrorFields>(INITIAL_ERROR);
  const [password, setPassword] = useState<string>();
  const {createNewPassword, forgotPassword} = useContext(AccountContext);
  const [score, setScore] = useState<number>(0);
  const changeScore = (score: number) => {
    setScore(score);
  }

  const passwordUpdate = (fields: any) => {
    setPassword(fields.password);
  }
  
  const onSubmit = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    setError(INITIAL_ERROR);
    setFormSubmitted(true);
    const data = new FormData(e.target);
    const code = Object.fromEntries(data.entries()).code.toString();
    const password = Object.fromEntries(data.entries()).password;
    const rePassword = Object.fromEntries(data.entries()).rePassword;

    if(password === rePassword && score >= 4 && code.length === 6) {
      try {
        const response = await createNewPassword({username: props.userEmail, code, password});
        if(response === "SUCCESS") {
          navigate('/auth/login');
          toast.success(`Password for your account has changed successfully. Please login with your new password`, {toastId: "PASSWORD_CHANGE_MESSAGE"});
        }
      } catch(e: any) {
        if(e.code === "CodeMismatchException") {
          setError((prev) => {
            return {...prev, code: e.message}
          });
        }

        if(e.code === "LimitExceededException") {
          toast.warning( e.message, {toastId: "PASSWORD_CHANGE_LIMIT_REACHED"});
        }

        if(e.code === "ExpiredCodeException") {
          toast.error(e.message, {toastId: "PASSWORD_CHANGE_LIMIT_REACHED"});
        }

        if(e.code === "InvalidPasswordException") {
          setError((prev) => {
            return {...prev, password: e.message}
          })
        }

      }
    } else {
      if(code.length < 6) {
        setError((prev) => {
          return {...prev, code: "Enter a valid code"}
        });
      } else if (password === "") {
        setError((prev) => {
          return {...prev, password: "Enter a valid password"}
        });
      } else if (rePassword === "") {
        setError((prev) => {
          return {...prev, rePassword: "Enter a valid password"}
        });
      } else if (password !== rePassword) {
        setError((prev) => {
          return {...prev, rePassword: "Password don't match"}
        });
      } else if (score <= 3) {
        setError((prev) => {
          return {...prev, rePassword: "Please use a strong password"}
        });
      }
    }

    setFormSubmitted(false);
  }

  const timeRun = async () => {
    setTimeRunning(true);
    setDefaultTime(prev => prev + 30);
    try {
      await forgotPassword(props.userEmail);
      toast.success(`Email sent successfully to ${props.userEmail}`, {toastId: "EMAIL_SENT"});
    } catch (e: any) {
      if(e.code === 'LimitExceededException') {
        setDefaultTime(3000);
        toast.warning( e.message, {toastId: "PASSWORD_CHANGE_LIMIT_REACHED"});
      }
    }
  }

  return (
    <Reveal>
      <div className='login-form-container'>
        <div className='login-header'>
          <h1 className='poppins-regular'>Confirm Reset Password</h1>
          <p className='poppins-light'>Please Check the inbox of <span className='selective-box'>{props.userEmail}</span> for a 6-digit verification code. More further instruction will be included in the email.</p>
        </div>
        <form onSubmit={onSubmit} className='login-form'>
          <InputField name='code' label='Verification Code *' type='number' error={error.code}/>
          <InputField name='password' pattern=".{8,}" label='New Password' type='password' error={error.password} onChange={passwordUpdate}/>
          <PasswordStrengthBar password={password} className="password-bar mb-0" onChangeScore={changeScore}/>
          <InputField name='rePassword' pattern=".{8,}" className="remove-label-space" label='Confirm New Password' type='password' error={error.rePassword}/>

          <Button label='Change Password' type='submit' loading={formSubmitted} className='mt-2'/>
        </form>
        <div className='bottom-login-form'> 
          <p className="poppins-light">Didn't receive the Code? {!timeRunning ? <button className="text-link text-button poppins-light" onClick={timeRun} type='button'>Resend Code</button> : <CountDown seconds={defaultTime} setTimeRunning={setTimeRunning}/>}</p>
        </div>
      </div>
    </Reveal>
  )
}
