import React, { useContext, useEffect, useState } from 'react'
import Button from '../../../Components/Button/Button'
import Select from 'react-select';
import "./style.css";
import InputField, { SelectField } from '../../../Components/InputField/InputField';
import SheetTable from '../components/SheetTable';
import { ModualPop } from '../../../Components/ModulePop/ModulePop';
import { RequestPricing } from '../components/RequestPricing/RequestPricing';
import { Link, useOutletContext } from 'react-router-dom';
import { CarrierInvitation, Company, RateBatch, Rates, Status } from '../../../API';
import noResult from "../../../assets/img/undraw_No_data_re_kwbl.png";
import { API } from 'aws-amplify';
import { listRates } from '../../../graphql/queries';
import Moment from 'react-moment';
import { NewRates } from './tabs/NewRates';
import { ApprovedRates } from './tabs/ApprovedRates';
import { ExpiredRates } from './tabs/ExpiredRates';
import { filter } from 'jszip';
import { AccountContext } from '../../../Context/AuthContext';
import moment from 'moment';
import { RejectedRates } from './tabs/RejectedRates';

export const getRateBatchByOwnerId = /* GraphQL */ `query GetRateBatchByOwnerId(
  $owner: String!
) {
  listCompanies(filter: { owner: { eq: $owner } }) {
    items {
      RateBatches {
        items {
          id
          companyID
          carrierID
          status
          tsMode
          scac
          startDate
          endDate
          Rates {
            items {
              id
              tsMode
              data
              scac
              serviceName
              startDate
              endDate
              originCity
              originCountry
              originProvince
              destinationCountry
              destinationCity
              destinationProvince
              originRegion
              destinationRegion
              ownerId
              ownerEmail
              ratebatchID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
}
`;

interface DashboardContext {
  fetchBB: () => Promise<void>;
  business: Company | null;
  addInvitation: (invitation: CarrierInvitation) => void;
}

export const PricingSheets = () => {
  const {getUser} = useContext(AccountContext);
  const { fetchBB, business, addInvitation } = useOutletContext<DashboardContext>();
  const [origin, setOrigin] = useState<string>();
  const [data, sheetData] = useState([]);
  const [isRequestingPricing, setIsRequestingPricing] = useState<boolean>(false);
  const [hideFilter, setHideFilter] = useState<boolean>(true);
  const [rates, setRates] = useState<Rates[]>([]);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [currentTabSelected, setCurrentTabSelected] = useState('NEWRATES');

  const [newRates, setNewRates] = useState<(RateBatch | null)[]>([]);
  const [approvedRates, setApprovedRates] = useState<(RateBatch | null)[]>([]);
  const [rejectedRates, setRejectedRates] = useState<(RateBatch | null)[]>([])
  const [expiredRates, setExpiredRates] = useState<(RateBatch | null)[]>([]);
  useEffect(() => {
    if(business?.id) {
      getRates();
    }
    
  }, [business]);

  const getRates = async () => {
    try {
      const user = getUser();
      // const currentDateTime = new Date().toISOString();
      const response:any = await API.graphql({
        query: getRateBatchByOwnerId,
        variables: { 
          owner: user.username 
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const companyInfo:Company = response.data.listCompanies.items[0];
      const rateBatch:(RateBatch | null)[] | undefined = companyInfo.RateBatches?.items;

      if(rateBatch && rateBatch?.length > 0 && rateBatch != null) {

        const now = moment();

        const newRates: RateBatch[] = [];
        const approvedRates: RateBatch[] = [];
        const rejectedRates: RateBatch[] = [];
        const expiredRates: RateBatch[] = [];


        rateBatch.forEach((rate: any) => {
          const endDate = moment(rate.endDate);

          if (endDate.isBefore(now)) {
            expiredRates.push(rate);
          } else {
            switch (rate.status) {
              case Status.PENDING:
                newRates.push(rate);
                break;
              case Status.ACCEPTED:
                approvedRates.push(rate);
                break;
              case Status.REJECTED:
                rejectedRates.push(rate);
                break;
              default:
                break;
            }
          }
        });

        setNewRates(newRates);
        setApprovedRates(approvedRates);
        setRejectedRates(rejectedRates);
        setExpiredRates(expiredRates);
      }
      

      // const approvedRates:any = await API.graphql({query: getCompany, authMode: "AMAZON_COGNITO_USER_POOLS", variables: {
      //   id: business?.id,
      //   status: Status.ACCEPTED,
      //   currentDateTime: currentDateTime,
      // }});
      // if(approvedRates != undefined) {
      //   const myRates:Rates[] = approvedRates.data.getCompany.Rates.items;
      //   setApprovedRates(myRates);
      // }

      // const expired:any = await API.graphql({query: expiredRate, authMode: "AMAZON_COGNITO_USER_POOLS", variables: {
      //   id: business?.id,
      //   currentDateTime: currentDateTime,
      // }});
      // if(expired != undefined) {
      //   const myRates:Rates[] = expired.data.getCompany.Rates.items;
      //   setExpiredRates(myRates);
      // }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  
  const onSubmit = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    console.log(Object.fromEntries(data.entries()));
  }

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="card pricing-sheet">
          <div className="d-flex justify-content-between align-items-center header-section-dash-single-row">
            <div className="card-header">
              <h5 className='mb-1'>Rate Inquiries</h5>
              <p>Rate Inquiries are only seen when submitted by a carrier</p>
            </div>
            
            <div>
              {/* Add a button to open the modal */}
              <Button label='Request Pricing' loading={false} type='button' onClick={() => setIsRequestingPricing(true)}></Button>
              {/* <Button variant="contained" className="mt-3 btn btn-primary btn-small" onClick={handleOpen}>Send Pricing Sheet</Button> */}
              <ModualPop show={isRequestingPricing} child={<RequestPricing addInvitation={addInvitation} company={business} setIsRequestingPricing={setIsRequestingPricing}/>} />
            </div>
          </div>
          <form className='main-form-box' onSubmit={onSubmit}>
            <div className='search-filed-pricing-sheet'>
              <InputField label='Search' name="search" placeholder='Search by lane id, carrier name'/>
            
              <SelectField list={[]} label="Carrier Name" error={""} value={origin} name="origin"/>
              <SelectField list={[]} label="Transportation Mode" error={""} value={origin} name="destination"/>

              <Button label='Search' loading={false} type='submit'></Button>
            </div>

            <div className='advance-button-filter' onClick={() => setHideFilter(!hideFilter)}>
              <button className='button-link filter-button' onClick={() => setHideFilter(!hideFilter)} type='button'>Advance Options</button>
              <div className='line-h-long'></div>
              <i className={hideFilter ? 'bi bi-chevron-down transition' : "bi bi-chevron-down transition flip-aero" }></i>
            </div>
            {/* <Select ></Select> */}
            <div className={hideFilter ? "extra-filter" : "extra-filter show-filter"}>
              <div>
                <div className='header-single-line-filter'>
                  <div className='line-h'></div>
                  <p>Origin Filter</p>
                  <div className='line-h-long'></div>
                </div>
                <div className='origin-box-filter-single-line'>
                  <SelectField list={[]} label="Zip Code / Postal Code" error={""} value={origin} name="origin"/>
                  <SelectField list={[]} label="City" error={""} value={origin} name="origin"/>
                  <SelectField list={[]} label="State / Province" error={""} value={origin} name="origin"/>
                  <SelectField list={[]} label="Country" error={""} value={origin} name="origin"/>
                </div>
              </div>
              <div>
                <div className='header-single-line-filter'>
                  <div className='line-h'></div>
                  <p>Destination Filter</p>
                  <div className='line-h-long'></div>
                </div>
                <div className='origin-box-filter-single-line pb-4'>
                  <SelectField list={[]} label="Zip Code / Postal Code" error={""} value={origin} name="origin"/>
                  <SelectField list={[]} label="City" error={""} value={origin} name="origin"/>
                  <SelectField list={[]} label="State / Province" error={""} value={origin} name="origin"/>
                  <SelectField list={[]} label="Country" error={""} value={origin} name="origin"/>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>


        
          
        {/* {rates && newRates.length === 0 ? 
          <div className="card pricing-sheet mt-5">
            <div className="d-flex justify-content-between align-items-center header-section-dash-single-row">
              <h5 className="card-header">Rate Inquiries</h5>
            </div>
            <div className='box-security-token no-rates-box'> 
              <div className='no-result-found'>
                <div>
                  <i className="bi bi-paperclip"></i>
                  <h2>No Rate Inquires found</h2>
                  <p>For any questions or information regarding rates, please contact your carrier directly. They will be able to provide you with the most accurate and up-to-date details.</p>
                  <Link to="/">Learn More <i className="bi bi-arrow-right"></i></Link>
                </div>
              </div> 
          </div>
          </div> 
          :  */}
        <div className="row mt-4">
          <div className='tabs-rate-page'>
            <ul>
              <li onClick={() => setCurrentTabSelected("NEWRATES")} className={currentTabSelected === "NEWRATES" ? 'selected-tab-rate' : ''}>New Rates</li>
              <li onClick={() => setCurrentTabSelected("APPROVEDRATES")} className={currentTabSelected === "APPROVEDRATES" ? 'selected-tab-rate' : ''}>Approved Rates</li>
              <li onClick={() => setCurrentTabSelected("REJECTEDRATES")} className={currentTabSelected === "REJECTEDRATES" ? 'selected-tab-rate' : ''}>Rejected Rates</li>
              <li onClick={() => setCurrentTabSelected("EXPIREDRATES")} className={currentTabSelected === "EXPIREDRATES" ? 'selected-tab-rate' : ''}>Expired Rates</li>
            </ul>
          </div>
          <div className="card pricing full-width">
            {currentTabSelected === "NEWRATES" && <NewRates newRates={newRates}/>}
            {currentTabSelected === "EXPIREDRATES" && <ExpiredRates expiredRates={expiredRates}/>}
            {currentTabSelected === "APPROVEDRATES" && <ApprovedRates approvedRates={approvedRates} business={business}/>}
            {currentTabSelected === "REJECTEDRATES" && <RejectedRates rejectedRates={rejectedRates}/>}
        </div>
      </div>
    </div>
  )
}
