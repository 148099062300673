import React, { useState } from 'react'
import "./style.css";
import Button, { DeleteButton } from '../../../Components/Button/Button';
import authImage from "../../../assets/img/auth.png";
import { ModualPop } from '../../../Components/ModulePop/ModulePop';
import noResult from "../../../assets/img/undraw_No_data_re_kwbl.png";
import AddDepartment from '../components/AddDepartment/AddDepartment';
import { Company, Departments } from '../../../API';
import { Link, useOutletContext } from 'react-router-dom';
import Moment from 'react-moment';
import { API } from 'aws-amplify';
import { deleteDepartments } from '../../../graphql/mutations';

interface DashboardContext {
  fetchBB: () => Promise<void>;
  business: Company | null;
  addDepartment: (department: Departments) => void;
  removeDepartment: (id: string) => void;
}

export const OTMManagement = () => {
  const {fetchBB, business, addDepartment, removeDepartment} = useOutletContext<DashboardContext>();
  const [isGeneratingToken, setIsGeneratingToken] = useState<boolean>(false);
  const [isAddingDepartment, setIsAddingDepartment] = useState<boolean>(false);

  const deleteDepartment = async (id: string) => {
    if(id !== "") {
      try {
        const deletedDepartments:any = await API.graphql({
          query: deleteDepartments,
          variables: {
              input: {
                  id: id
              }
          },
          authMode: "AMAZON_COGNITO_USER_POOLS"
        });

        if(deletedDepartments.data != undefined) {
          removeDepartment(id);
        }
      } catch(e) {
        console.log(e);
      }
    }
  };

  return (
    <>
    <div className="container-xxl flex-grow-1 container-p-y">
      {/* <h4 className="py-3 mb-4">Add User</h4> */}

      <div className="row">
        <div className="card pricing-sheet">
          <div className='d-flex justify-content-between align-items-center header-section-dash-single-row'>
            <div className="card-header">
              <h5 className='mb-1'>Departments</h5>
              <p>Create department to assign your employee to</p>
            </div>
            <div>
              <Button label='Add Department' loading={false} onClick={() => setIsAddingDepartment(true)}></Button>
            </div>
          </div>
        </div>
      </div>

      

      <div className="row mt-4">

      {business?.Departments?.items.length == 0 ?
      
         <div className='box-security-token mt-5'> 
          <div className='no-result-found mt-5'>
            <div>
              <i className="bi bi-building"></i>

              <h2>No Departments found</h2>
              <p>Please add a department to your company so when generating CSV it can link to your department</p>
              <Link to="/">Learn More <i className="bi bi-arrow-right"></i></Link>
            </div>
          </div> 
        </div> 
        
        :
        <div className="card pricing-sheet">
          <div className='d-flex justify-content-between align-items-center header-section-dash-single-row'>
          <table className='table-of-items activity-table'>
                <thead>
                  <th>Department <i className="bi bi-arrow-down"></i></th>
                  <th>Created Date</th>
                  <th>Sub Domain</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {business?.Departments?.items.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className='first-item-left-side'>
                          {/* <CheckBox loading={false}/> */}
                          <div className='carrier-info-table-box'>
                            <h4>{item?.DepartmentName}</h4>
                          </div>
                        </div>
                      </td>
                      
                      <td><Moment format='DD MMM YYYY @ HH:MM:DD'>{item?.createdAt}</Moment></td>
                      <td>{business.Domain}/{item?.DepartmentSCAC}</td>
                      <td className='last-item-table'><button className='btn-danger btn' onClick={() => deleteDepartment(item?.id || "")}>Delete</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </div>
        </div>}
      </div>
          

      {/* <div className="row">
        <div className='card mt-4 user-list-card'>
        {/* <h5 className="card-header">Generate Custom Authentication Link</h5>
        <div className='generation-key-section'>
          <h2>Login Key</h2>
          <p>Secure token link generation for authentication</p>
          <div className='box-security-token'>
            <div>
              <img src={authImage} width={250}/>
              <p>Please do not share your authentication token with anyone. Unauthorized access can compromise your account security and personal data. If you believe your token has been exposed or compromised, please contact our support team immediately for assistance.</p>
            </div>
            <Button label='Generate Token' onClick={() => setIsGeneratingToken(true)} loading={false}/>
          </div>
        </div>
        </div>
      </div> */}
    </div>
    <ModualPop show={isGeneratingToken} child={<h1>sds</h1>}/>
    <ModualPop show={isAddingDepartment} child={<AddDepartment addDepartment={addDepartment} setIsAddingDepartment={setIsAddingDepartment} company={business}/>}/>
    </>
  )
}
