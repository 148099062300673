import React, { useState } from 'react'
import InputField, { PhoneInputCustom } from '../../../../Components/InputField/InputField'
import Button from '../../../../Components/Button/Button'
import "./style.css";
import { Reveal } from '../../../../Components/Revel/Revel';
import { AddressAutofill } from '@mapbox/search-js-react';

const INITIAL_ERROR = {
  name: '',
  phone: '',
  address: '',
  postal: '',
  city: '',
  state: '',
  country: ''
}

export const BusinessInfo = (prop: any) => {
  const [error, setError] = useState(INITIAL_ERROR);
  const onChange = (name:any, field:any) => {
    //console.log(name, field);
    prop.setBusinessInfo((prev: any) => {
      return {...prev, [name]: field}
    });
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    setError(INITIAL_ERROR);
    const data = new FormData(e.target);
    const add = Object.fromEntries(data.entries())[' address-search'];
  
    if(prop.businessInfo.name !== "" && prop.businessInfo.phone !== "" && add !== "" && prop.businessInfo.postal !== "" && prop.businessInfo.city !== "" && prop.businessInfo.state !== "" && prop.businessInfo.country !== "") {
      prop.setBusinessInfo((prev: any) => {
        return {...prev, address: add}
      });

      prop.nextPage();
    } else {
      if(prop.businessInfo.name === "") {
        setError((prev: any) => {
          return {...prev, name: "Enter a valid name"}
        });
      } else if (prop.businessInfo.phone === "") {
        setError((prev: any) => {
          return {...prev, phone: "Enter a valid phone"}
        });
      } else if (add === "") {
        setError((prev: any) => {
          return {...prev, address: "Enter a valid address"}
        });
      } else if (prop.businessInfo.postal === "") {
        setError((prev: any) => {
          return {...prev, postal: "Enter a valid postal code"}
        });
      }
    }

    console.log(prop.businessInfo)
    //prop.nextPage();
  }

  return (
    <div>
      <Reveal>
        <div className='header-business-box-step'>
          <h2>Business Information</h2>
          <p>This information will help us help you achive your tasks</p>
        </div>
      </Reveal>

      <form className='d-flex form-business-info' onSubmit={onSubmit}>
        <Reveal delay={.1}>
          <InputField label='Company Name' error={error.name} name="cName" placeholder='Example Inc.' value={prop.businessInfo.name} onChange={(field) => onChange('name', field.cName)}/>
        </Reveal>

        <Reveal delay={.2}>
          <PhoneInputCustom label='Phone Number'  error={error.phone} name="phone" value={prop.businessInfo.phone} placeholder='+1 (XXX) - XXX - XXXX' onChange={(field) => onChange('phone', field.phone)}/>
        </Reveal>

        <Reveal delay={.3}>
          <div className='d-flex gap-3 full-width'>
            <AddressAutofill accessToken="pk.eyJ1IjoiYXJ5YW5iaGFsbGEiLCJhIjoiY2ttbWMxYjN0MG4zNzJ2b2RzenNtNHloeCJ9.D28HxdUCUpf7YpvsQZ26AQ">
              <InputField autoComplete="address-line1" error={error.address} label='Street Address' placeholder='123 Abc st.' name=""/>
            </AddressAutofill>
            <InputField autoComplete="postal-code" error={error.postal} label='Zip Code' value={prop.businessInfo.postal} name="postal" onChange={(field) => onChange('postal', field.postal)} placeholder='123 232'/>
          </div>
        </Reveal>

        <Reveal delay={.4}>
          <div className='d-flex gap-3'>
            <InputField autoComplete="address-level2" label='City' value={prop.businessInfo.city} name="city" onChange={(field) => onChange('city', field.city)} placeholder='Fort Mayc'/>
            <InputField autoComplete="address-level1" label='State/Province' value={prop.businessInfo.state} name="state" onChange={(field) => onChange('state', field.state)} placeholder='Florida'/>
            <InputField autoComplete="country" label='Country' name="country" value={prop.businessInfo.country} onChange={(field) => onChange('country', field.country)} placeholder='USA'/>
          </div>
        </Reveal>

        <Reveal delay={.5}>
          <div className='d-flex gap-4 mt-4'>
            <button className='button-link' onClick={() => prop.goBack()} type='button'>Back</button>
            <Button label='Next' loading={false} type='submit'></Button>
          </div>
        </Reveal>
      </form>
    </div>
  )
}
