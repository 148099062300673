import React from "react";
import { TbMoneybag , TbUserCog , TbGitPullRequest , TbProgressCheck  } from "react-icons/tb";
import { BsBuildingLock } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";
import { Reveal } from "../../../../Components/Revel/Revel";
import { CountUp } from "use-count-up";

const Card = (prop: any) => {
  return (
    <>
      <div className="col-sm-6 col-lg-4 mb-4 child-div-full-width">
        <Reveal>
          <div className="card card-border-shadow-primary full-width">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-primary">
                  <IoNewspaperOutline size={24}/>

                  </span>
                </div>
                <h4 className="ms-1 mb-0"><CountUp isCounting duration={1.5} end={prop.rateStats != null && prop.rateStats.total}/></h4>
              </div>
              <p className="mb-1">Total Rates Sheets</p>
              <p className="mb-0">
                <span className="fw-medium me-1">+18.2%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </Reveal>
      </div>
        
      <div className="col-sm-6 col-lg-4 mb-4 child-div-full-width">
        <Reveal delay={.1}>
          <div className="card card-border-shadow-warning">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-warning">
                    <TbUserCog size={24}/>
                  </span>
                </div>
                <h4 className="ms-1 mb-0"><CountUp isCounting duration={1} end={prop.totalCompanies} /></h4>
              </div>
              <p className="mb-1">Total Companies On</p>
              <p className="mb-0">
                <span className="fw-medium me-1">-8.7%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </Reveal>
      </div>
      <div className="col-sm-6 col-lg-4 mb-4 child-div-full-width">
        <Reveal delay={.2}>
          <div className="card card-border-shadow-danger">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-danger">
                    <TbGitPullRequest size={24} />
                  </span>
                </div>
                <h4 className="ms-1 mb-0">0</h4>
              </div>
              <p className="mb-1">Number of Invitation</p>
              <p className="mb-0">
                <span className="fw-medium me-1">+4.3%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </Reveal>
      </div>

      <div className="col-sm-2 col-lg-3 mb-4 child-div-full-width">
        <Reveal delay={.2}>
          <div className="card card-border-shadow-danger">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-danger">
                    <TbGitPullRequest size={24} />
                  </span>
                </div>
                <h4 className="ms-1 mb-0"><CountUp isCounting duration={2} end={prop.rateStats != null && prop.rateStats.LTL} /></h4>
              </div>
              <p className="mb-1">LTL Rates</p>
              <p className="mb-0">
                <span className="fw-medium me-1">+4.3%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </Reveal>
      </div>
      <div className="col-sm-2 col-lg-3 mb-4 child-div-full-width">
        <Reveal delay={.2}>
          <div className="card card-border-shadow-danger">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-danger">
                    <TbGitPullRequest size={24} />
                  </span>
                </div>
                <h4 className="ms-1 mb-0"><CountUp isCounting duration={1.5} end={prop.rateStats != null && prop.rateStats.FTL} /></h4>
              </div>
              <p className="mb-1">FTL / TL Rates</p>
              <p className="mb-0">
                <span className="fw-medium me-1">+4.3%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </Reveal>
      </div>
      <div className="col-sm-2 col-lg-3 mb-4 child-div-full-width">
        <Reveal delay={.2}>
          <div className="card card-border-shadow-danger">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-danger">
                    <TbGitPullRequest size={24} />
                  </span>
                </div>
                <h4 className="ms-1 mb-0">0</h4>
              </div>
              <p className="mb-1">Air Rates</p>
              <p className="mb-0">
                <span className="fw-medium me-1">+4.3%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </Reveal>
      </div>
      <div className="col-sm-2 col-lg-3 mb-4 child-div-full-width">
        <Reveal delay={.2}>
          <div className="card card-border-shadow-danger">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-danger">
                    <TbGitPullRequest size={24} />
                  </span>
                </div>
                <h4 className="ms-1 mb-0">0</h4>
              </div>
              <p className="mb-1">Ocean Rates</p>
              <p className="mb-0">
                <span className="fw-medium me-1">+4.3%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </Reveal>
      </div>
    </>
  );
};

export default Card;
