import React, { useEffect, useState } from 'react'
import Button, { CheckBox } from '../../../Components/Button/Button'
import { ModualPop } from '../../../Components/ModulePop/ModulePop'
import { RequestPricing } from '../components/RequestPricing/RequestPricing';
import InputField, { SelectField } from '../../../Components/InputField/InputField';
import "./style.css";
import { Link, useOutletContext } from 'react-router-dom';
import { Carrier, CarrierInvitation, Company, Status } from '../../../API';
import Moment from 'react-moment';
import { LoadingFull } from '../../../Components/PreLoader/Loading';
import noData from "../../../assets/img/undraw_No_data_re_kwbl.png";
import MUIDataTable from "mui-datatables";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import { CarrierTable } from './CarrierTable';
import { Slider } from 'rsuite';


interface DashboardContext {
  fetchBB: () => Promise<void>;
  business: Company | null;
  addInvitation: (invitation: CarrierInvitation) => void;
}

export const Carriers = () => {
  const { fetchBB, business, addInvitation } = useOutletContext<DashboardContext>();
  const [isRequestingPricing, setIsRequestingPricing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if(business != null) {
      setIsLoading(false);
    }
  }, [business]);
  
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="card pricing-sheet">
          <div className="d-flex justify-content-between align-items-center header-section-dash-single-row">
            <div className="card-header">
              <h5 className='mb-1'>Carriers</h5>
              <p>Carrier's can provides rates then which can be imported to OTM</p>
            </div>
            
            <div className='page-header-right-side'>
              <div className='invites-info'>
                <p className='full-width'>
                  <strong>{business?.CurrentCarrierUsers}</strong> / {business?.MaxCarrierUsers} Invites
                </p>
                <Slider min={0} max={business?.MaxCarrierUsers || 0} value={business?.CurrentCarrierUsers || 0} progress className='progress-class mb-1 mt-1'/>
                <small className='invite-status'>
                  {business?.CurrentCarrierUsers === business?.MaxCarrierUsers
                    ? "Invite Limit Reached"
                    : "You can invite more users"}
                </small>
              </div>
              {/* Add a button to open the modal */}
              <Button label='Invite Carrier' loading={false} type='button' onClick={() => setIsRequestingPricing(true)}></Button>
              {/* <Button variant="contained" className="mt-3 btn btn-primary btn-small" onClick={handleOpen}>Send Pricing Sheet</Button> */}
              <ModualPop show={isRequestingPricing} child={<RequestPricing addInvitation={addInvitation} company={business} setIsRequestingPricing={setIsRequestingPricing}/>} />
            </div>
          </div>
        </div>
      </div>

      
      <CarrierTable isLoading={isLoading} business={business}/>
     
    </div>
  )
}
